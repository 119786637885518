import React, { useState,useEffect } from 'react';
import './profile.css'
import  'react-multiple-select-dropdown-lite/dist/index.css'
import  secureLocalStorage  from  "react-secure-storage";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import Axios from "axios";


export default function ProfilePage() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const [details,setDetails]=useState([]);
  
  const activeUser = secureLocalStorage.getItem("activeUser");
  const username=activeUser;
  const token = secureLocalStorage.getItem("token");
  
  useEffect(()=>{
    if (isLoggedIn){
    const getVendorDetails=()=>{
  Axios.post(`${process.env.REACT_APP_API_URL}/vendor/profile_data_disp`,{
      username: username,
    } ,{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
     
      if(response.data != "-1"){
        setDetails(response.data);
       
      }else{
        window.alert("error");
      }
   
    });
  }
    getVendorDetails()
}
  },[]);
  
  
  const inputStyle = { margin: '0 5px' };
  return (
    
    <section  className="sec">
      {isLoggedIn ? ( <>
      <MDBContainer className="py-5" >
        <MDBRow>
          <MDBCol lg="7" className="mx-auto">
      <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                
                  src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                  alt="avatar" data-testid="user-img"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
                <p className="text-muted mb-1" data-testid="user-name">{ activeUser }</p>
         
              </MDBCardBody>
            </MDBCard></MDBCol>
        
        <div>
        {
      details.map((d) => ( 
            <div>

            <MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
            <MDBCardBody>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Production Unit</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
            <input type="text" id="prod_unit" style={{inputStyle}} value={d.prod_unit} placeholder={'Production Unit'}  required disabled />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">SAP Plant</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="sap_plant" style={{inputStyle}} value={d.sap_plant} placeholder={'SAP Plant'}  required disabled />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">SC Vendor</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="sc_vendor" style={{inputStyle}} value={d.sc_vendor} placeholder={'SC Vendor'}  required disabled/>
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">UserName</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="uname" style={{inputStyle}} value={d.uname} placeholder={'UserName'}  required  disabled/>
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>



             


            
            </MDBCardBody>
            </MDBCard> 
            </MDBCol>

            </div>
     ))}
    
        </div>

          
          </MDBRow>
      </MDBContainer>
      </>) :(window.location.href = "#/login")}
    </section>
  );
}
