
import React,{ useRef,useState,useEffect } from "react";
import useReactToPrint from 'react-to-print';
import { Box, Grid } from '@mui/material';
// import Select from "react-select";
// import { Select, MenuItem, TextField, Button } from '@mui/material';
import { Button, MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import moment, { isDate } from 'moment-timezone';
import MUIDataTable from 'mui-datatables'
import  secureLocalStorage  from  "react-secure-storage";
import Filter_component from '../components/filter/filter_component';
import Axios from "axios";
import './muitab.css';
import ReactToPrint from 'react-to-print';
function Despatch() {
 const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
 const uname=secureLocalStorage.getItem("activeUser"); 
 const [order_id,setorderids]=useState([]);
   const [selectedOption1, setSelectedOption1] = useState([])
   const [article,setSelectedArticle]=useState([]);
   const [selectedArticle, setArticle] = useState('');
   const [shade,setSelectedShade]=useState([]);
   const [selectedShade, setShade] = useState('');
   const [selectedRows, setSelectedRows] = useState([]);
   const [cwhartktmas,setCwhartktmas]=useState([]);
   var data1='';
   var at_bc=0
   const componentRef=useRef();
   const [isPrinted, setIsPrinted] = useState(false);
   const handlePrint =()=>useReactToPrint({
       content:()=>componentRef.current(),
       documentTitle:' samp',
       onafterprint:()=>alert("success")
   });
   let orderId1;
   let carton_nos=''
   let po_numbers=''
   const [isInputFocused, setIsInputFocused] = useState(false);
   const [isArticleInputFocused, setIsArticleInputFocused] = useState(false); // Initialize the state
   // const [selectedShade, setShade] = useState('');
   const [fromDate, setFromDate] = useState(null);
   const [toDate, setToDate] = useState(null);
   const [cartonFrom, setCartonFrom] = useState(null);
   const [cartonTo, setCartonTo] = useState(null);
   const mill = secureLocalStorage.getItem("mill");
   const token = secureLocalStorage.getItem("token");
   const [offset, setOffset] = useState(0);
 const [page, setPage] = useState(1);
 const [pageSize, setPageSize] = useState(10);
 const [selectedvalue, setselectedvalue] = useState('10');
 const [totalRecords, setTotalRecords] = useState(0);
   const [details,setDetails]=useState([]);
   const [lastdocnum, setLastdocnum] = useState(0);
   let [serialNumber, setSerialNumber] = useState(1);
   let [cartonNos, setCartonNos] = useState(new Set());
   const options = [
     "Generated Label",
     "Carton Printed",
     "Carton RePrinted",
   ];
   const columns = [
    {
      name: 'serialNumber',
      label: 'S.No.',
      options: {
        customBodyRender: (value) => {
          return value !== null ? value : '-';
        }
      }
    },
     {name:"carton_no",label:"Carton No"},
     {name:"po_number",label:"PO Number"},
    // {name:"mill",label:"Mill"},
     {name:"article",label:"Article"},
     {name:"shade",label:"Shade"},
     {name:"depot",label:"Depot"},
     {name:"fullqty",label:"Qty in boxes"},
     {name:"at_bc",label:"Qty in Pieces"},
     //{name:"packerid",label:"Packer ID"},
     {name:"grosswt",label:"Gross Weight"},
     // {name:"greykg",label:"Net Weight"},
     {name:"cartontype",label:"Carton Type"},
     {name:"statuscode",label:"Status"},
     {name:"carton_date", label: "Carton Date",
 options: {
   customBodyRender: (value) => format_date(value)
 }
 
 },

 //{name:"count_label",label:"Count Label"}
]

   useEffect(() => {
     if (isLoggedIn){
        console.log("secureLocalStorage.getItem",secureLocalStorage.getItem("activeUser"))
       const getAllDetails = () => {
         if (mill == "COATS") {
         
           Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,{
           },{
             headers: {
               'x-access-token': token,
               'Content-Type': 'application/json'}
             }).then((response) => {
            
           setorderids(response.data)
           });
           }
           else{
           Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp_vendor1`,{
           
            mill:mill,
           },{
             headers: {
               'x-access-token': token,
               'Content-Type': 'application/json'}
             }).then((response) => {
           setorderids(response.data)
           });
           }
         }
         getAllDetails()
   Axios.post(`${process.env.REACT_APP_API_URL}/label/article_disp`,{
   },{
     headers: {
       'x-access-token': token,
       'Content-Type': 'application/json'}
     }).then((response) => {
       console.log(response.data)
       setSelectedArticle(response.data)
   });
   Axios.post(`${process.env.REACT_APP_API_URL}/label/shade_disp`,{
   },{
     headers: {
       'x-access-token': token,
       'Content-Type': 'application/json'}
     }).then((response) => {
       console.log(response.data)
       setSelectedShade(response.data)
   });
   // combo_filter();
   if (
     selectedvalue !== '10' ||
     offset !== 0 ||
     page !== 1 ||
     pageSize !== 10
   ) {
     combo_filter();
   }
   }
   },[selectedvalue, offset, page, pageSize]);
   const format_date=(utcDate)=>{
       const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
       return istDate;
     }
   const options1 = article.map((item) => ({
       label: item.material_code,
       value: item.material_code,
     }));
     // const options2 = shade.map((item) => ({
     //   label: item.material_code,
     //   value: item.material_code,
     // }));
     const options_shade = shade.map((item) => ({
       label: item.shade,
       value: item.shade,
     }));
     const options2 = options_shade.filter((options_shade, index, self) =>
       index === self.findIndex((t) => (
         t.value === options_shade.value && t.label === options_shade.label
       ))
     );
   const setdate1 = (event) => {
       console.log(event.target.value)
       setFromDate(event.target.value);
     };
   //   const handleArticleChange = (event) => {
   //     const selectedValue = event.target.value;
   //     setArticle(selectedValue);
   //     console.log('Selected Article:', selectedValue);
   //   };
     const handleArticleChange = (event,newValue) => {
       if (newValue) {
           setArticle(newValue.value); // Update with the selected option's value
         } else {
           setArticle(''); // Reset the state if no option is selected
         }
     };
   //   const handleShadeChange = (event) => {
   //     const selectedValue = event.target.value;
   //     setShade(selectedValue);
   //     console.log('Selected shade:', selectedValue);
   //   };
   const handleShadeChange = (event, newValue) => {
       if (newValue) {
         setShade(newValue.value); // Update with the selected option's value
       } else {
         setShade(''); // Reset the state if no option is selected
       }
     };
     const handlesetFromDate  = (event) => {
       setFromDate(event.target.value);
       
           }
     const handlesetToDate  = (event) => {
             setToDate(event.target.value);
             
           }
    const handleCartonFrom  = (event) => {
         setCartonFrom(event.target.value);
            
     }
    const handleCartonTo  = (event) => {
           setCartonTo(event.target.value);
                  
      }
       const combo_filter = async() => {
       console.log(selectedArticle)
       try {
         console.log("from",fromDate)
         console.log("to",toDate)
         console.log(selectedArticle)
       if(toDate && (fromDate===null || fromDate===""))
       {
         window.alert("Select From Date ")
         return
       }
         const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/label/carton_status`, {
  
           // statuscode: selectedOrderstatuscode,
           date1:fromDate,
           date2:toDate,
           // article:selectedArticle?.value,
           // shade:selectedShade?.value,
           article:selectedArticle,
           shade:selectedShade,
           mill:mill,
           status:selectedOption1,
           value1: Number(selectedvalue),
           // offset: offset,
           page: page, // Pass current page
           pageSize: pageSize,
           cartonFrom:cartonFrom,
           cartonTo:cartonTo
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         });
   const cwhartktmas= await Axios.post(`${process.env.REACT_APP_API_URL}/cwhartktmas/fetch_details`, {
  
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        })
       
         const totalCount = data.totalCount;
 const response = data.result;
 const cw=cwhartktmas.data.result
         console.log("totalCount",totalCount)
         console.log("response",response)
         var len;
         let newcartonNos = new Set(cartonNos);
         if (page === 1) {
           // Reset the details state if it's the first page
           let newcartonNos = new Set();
           let serialNumber=1
           console.log("length",response.length)
           for(len=0;len<response.length;len++)
          {
           console.log("response[len].cartontype",response[len].cartontype)
           const firstPart = response[len].article.substring(0, 4); // "TLR0"
           const secondPart = response[len].article.substring(4);   
           const filteredData = cw.filter(item => item.at_art === firstPart && item.at_tkt===secondPart);
           at_bc = Array.from(new Set(filteredData.map(detail => detail.at_bc))).join(', ');
        
           if(response[len].cartontype==="Solid")
           {
           console.log("response[len].fullqty",response[len].fullqty)
           response[len].fullqty=response[len].at_cc;
           console.log("response[len].fullqty",response[len].fullqty)
           response[len].at_bc = at_bc* response[len].fullqty;
           }
           if(response[len].cartontype==="Loose")
           {
           const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
           response[len].grosswt=grossWeight
           response[len].at_bc = at_bc* response[len].fullqty;
           }
             // Check if carton_no is unique
        if (newcartonNos.has(response[len].carton_no)) {
          response[len].isUnique = false;
          response[len].serialNumber = serialNumber-1;
        } else {
          response[len].isUnique = true;
          response[len].serialNumber = serialNumber++;
          // newserialNumber=response[len].serialNumber
          newcartonNos.add(response[len].carton_no);
        }
         
           }
           setCartonNos(newcartonNos);
           setSerialNumber(serialNumber);
           setDetails(response);
         } else {
           // Append new data to the existing details state
           for(len=0;len<response.length;len++)
           {
            console.log("response[len].cartontype",response[len].cartontype)
            const firstPart = response[len].article.substring(0, 4); // "TLR0"
            const secondPart = response[len].article.substring(4);   
            const filteredData = cw.filter(item => item.at_art === firstPart && item.at_tkt===secondPart);
            if (filteredData.length === 0) {
              at_bc =0; 
            } else {
              at_bc = Array.from(new Set(filteredData.map(detail => detail.at_bc))).join(', ');
            }
            if(response[len].cartontype==="Solid")
            {
            console.log("response[len].fullqty",response[len].fullqty)
            response[len].fullqty=response[len].at_cc;
            console.log("response[len].fullqty",response[len].fullqty)
            response[len].at_bc = at_bc*  response[len].fullqty;
            }
            if(response[len].cartontype==="Loose")
            {
            const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
            response[len].grosswt=grossWeight
            response[len].at_bc = at_bc*  response[len].fullqty;
            }
                // Check if carton_no is unique
        if (newcartonNos.has(response[len].carton_no)) {
          response[len].isUnique = false;
          response[len].serialNumber = serialNumber-1
        } else {
          response[len].isUnique = true;
          console.log("Serila num",serialNumber)
          response[len].serialNumber = serialNumber++;
          // newserialNumber=response[len].serialNumber
          newcartonNos.add(response[len].carton_no);
        }
       
            }
            setCartonNos(newcartonNos);
            setSerialNumber(serialNumber);
           setDetails((prevDetails) => [...prevDetails, ...response]);
          
         }
         // const uniqueRows = response.filter((newRow) => {
         //   return !details.some((existingRow) => existingRow.carton_no === newRow.carton_no);
         // });
         
         // setDetails((prevDetails) => [...prevDetails, ...uniqueRows]);
         console.log(response)
         setTotalRecords(totalCount)
       } catch (error) {
         console.error("Error fetching data:", error);
       }
       
     }

     const clear_values = () => {
       setArticle('');
       setShade('');
       setFromDate('');
       setToDate('');
       setCartonFrom('');
       setCartonTo('');
       setSelectedOption1([]);
       setDetails([]);
       setTotalRecords(0)
   }
   const handlePageChange = (newPage) => {
     const newOffset = newPage * pageSize; // Calculate the new offset
     setPage(newPage + 1);
     setOffset(newOffset);
     
   };
 
   const handlePageSizeChange = (newPageSize) => {
     setPageSize(newPageSize);
     setPage(1); // Reset the page to 1 when page size changes
     setOffset(0); // Reset the offset to 0 when page size changes
     clearSelection()
   };
   
   const clearSelection = () => {
    setSelectedRows([]);
    setDetails([...details]);
  };
   const handleStatusChange = (event) => {
       setSelectedOption1(event.target.value);
     };
  
     function downloadTable() {
       // create a new workbook
       const workbook = XLSX.utils.book_new();
     
       // convert the table data to a worksheet
       const worksheet = XLSX.utils.table_to_sheet(document.querySelector('table'));
     
       // add the worksheet to the workbook
       XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
     
       // generate a blob object containing the XLSX file data
       const blob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
     
       // create a temporary link element to trigger the download
       const link = document.createElement('a');
       link.href = URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }));
       link.download = 'table.xlsx';
     
       // simulate a click event to trigger the download
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
       
     }
     const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
       setSelectedRows(allRowsSelected.map(row => row.dataIndex));
     };
    
     const handlePrintSelectedRows = () => {
       Axios.post(`${process.env.REACT_APP_API_URL}/label/fetch_last_doc_num`,{

   
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         }).then((response) => {
            var last_doc_num 
    if(response.data.length>0)
    {
        last_doc_num = response.data[0].doc_number;
    }
    else{
        last_doc_num =0 
    }
       
       
       if (last_doc_num) {
         // const idNumber = parseInt(lastOrderId.slice(1)) ;
         orderId1 = Number(last_doc_num)+1;
       } else {
         orderId1 =1;
       }
       //  setLastdocnum(response.data[0].document_no)
       const selectedColumns = columns.map(column => column.name); // Get the names of the selected columns
         const selectedRowsData = selectedRows.map(rowIndex =>
           selectedColumns.reduce((acc, columnName) => {
             acc[columnName] = details[rowIndex][columnName];
             return acc;
           }, {})
         ); // Extract data for the selected columns
         
const printWindow = window.open('', '_blank');
printWindow.document.write('<html><head><title>Print</title>');
printWindow.document.write('<style>table { border: 1px solid black; border-collapse: collapse; } th, td { border: 1px solid black; padding: 5px; } .page-break { page-break-before: always; }</style>');
printWindow.document.write('</head><body>');


const rowsPerPage = 15; 

// Function to write page content
function writePageContent(pageData, pageIndex) {
  printWindow.document.write('<table border="1">');
  printWindow.document.write('<thead>');
  printWindow.document.write('<tr><td colspan="4">Document NO:' + orderId1 + '<br> Date:' + new Date().toLocaleDateString() + '<br></td>');
  printWindow.document.write('<td colspan="9">Sirius Zip Fasteners Pvt Ltd.,C-22 , SIPCOT Industrial Complex, Manamadurai, Sivagangai District, TamilNadu - 630606<br>GSTIN:33AABCS6968D1ZB<br>GSTIN/UIN:33AABCS6968D1ZB<br>State Name:Tamil Nadu, Code :33<br>CIN: U28999KA1988PTC009372<br>E-Mail: logistics@siriuszip.com</td></tr>');
  printWindow.document.write('<tr>');
  // printWindow.document.write('<th>S.No.</th>');

  // Write headers
  selectedColumns.forEach(columnName => {
    const column = columns.find(col => col.name === columnName);
    printWindow.document.write('<th>' + column.label + '</th>');
  });

  printWindow.document.write('</tr></thead><tbody>');

  // Write data rows
  // let sno = pageIndex * rowsPerPage + 1;
  let pageTotalQtyBoxes = 0;
  let pageTotalQtyPieces = 0;
  let pageTotalgrosswt=0;
  pageData.forEach((row, rowIndex) => {
    printWindow.document.write('<tr>');
    // printWindow.document.write('<td>' + sno + '</td>');
    // sno++;
    selectedColumns.forEach((columnName, columnIndex) => {
      if (columnName === "carton_date") {
        printWindow.document.write('<td>' + format_date(row[columnName]) + '</td>');
      } else {
        printWindow.document.write('<td>' + row[columnName] + '</td>');
      }

      if (columnName === "carton_no") {
        carton_nos += row[columnName] + " ";
      }
      if (columnName === "po_number") {
        po_numbers += row[columnName] + " ";
      }
      if (columnName === "fullqty") {
        pageTotalQtyBoxes += Number(row[columnName]);
      }
      if (columnName === "at_bc") {
        pageTotalQtyPieces += Number(row[columnName]);
      }
      if (columnName === "grosswt") {
        pageTotalgrosswt += Number(row[columnName]);
      }
    });
    printWindow.document.write('</tr>');
  });

  printWindow.document.write('<tr><td colspan="5"></td>');
  printWindow.document.write('<td>Total</td>');
  printWindow.document.write('<td>' + pageTotalQtyBoxes + '</td>');
  printWindow.document.write('<td>' + pageTotalQtyPieces + '</td><td colspan="4"></td></tr>');
  printWindow.document.write('<tr><td colspan="7"></td><td>GROSS WT</td><td>',pageTotalgrosswt,'</td></tr>');
  printWindow.document.write('</tbody></table>');


}

// Split data into pages
let pageIndex = 0;
for (let i = 0; i < selectedRowsData.length; i += rowsPerPage) {
  if (pageIndex > 0) {
    printWindow.document.write('<div class="page-break"></div>');
  }
  const pageData = selectedRowsData.slice(i, i + rowsPerPage);
  writePageContent(pageData, pageIndex);
  pageIndex++;
}


printWindow.document.write('</body></html>');
printWindow.document.close();
printWindow.print();

         console.log("carton_nos",carton_nos)
         const printCheckInterval = setInterval(() => {
            if (printWindow.closed) {
                // If the print window is closed, set the flag to true
                setIsPrinted(true);
                clearInterval(printCheckInterval); // Stop the timer
            }
        }, 1000); // Check every second if the print window is closed
       
        // if (isPrinted) {
         Axios.post(`${process.env.REACT_APP_API_URL}/label/insert_document_details`,{
         doc_no:orderId1,
         carton_no:carton_nos,
         po_numbers:po_numbers,
         uname:uname
   
         },{
           headers: {
             'x-access-token': token,
             'Content-Type': 'application/json'}
           })
        // }
       });
     
       };
   return(
       <div>
         {isLoggedIn ? ( <>
       <br/>  <h3 style={{marginLeft:"55px"}} data-cy="order_label">Despatch Planning</h3>
       <button className="lineb" style={{marginLeft:'50px'}}/>
           <br/> <br/>
           <Box sx={{ width: '80%', marginLeft: '100px' }}>
         
         <Grid container spacing={2}>
           <Grid item xs={12} sm={6} md={4} lg={3}>
             <FormControl fullWidth>
               <InputLabel shrink={selectedArticle !== '' || isArticleInputFocused}>Select Article</InputLabel>
               <Autocomplete
                 options={options1}
                 value={options1.find(option => option.value === selectedArticle) || null}
                 onChange={handleArticleChange}
                 getOptionLabel={option => option.label}
                 onFocus={() => setIsArticleInputFocused(true)}
                 onBlur={() => setIsArticleInputFocused(false)}
                 renderInput={params => <TextField {...params} />}
               />
             </FormControl>
           </Grid>
   
           <Grid item xs={12} sm={6} md={4} lg={3}>
             <FormControl fullWidth>
               <InputLabel shrink={selectedShade !== '' || isInputFocused}>Select Shade</InputLabel>
               <Autocomplete
                 options={options2}
                 value={options2.find(option => option.value === selectedShade) || null}
                 onChange={handleShadeChange}
                 getOptionLabel={option => option.label}
                 onFocus={() => setIsInputFocused(true)}
                 onBlur={() => setIsInputFocused(false)}
                 renderInput={params => <TextField {...params} />}
               />
             </FormControl>
           </Grid>
   
           <Grid item xs={12} sm={6} md={4} lg={3}>
             <FormControl fullWidth>
               <InputLabel>Status</InputLabel>
               <Select
                 multiple
                 value={selectedOption1}
                 onChange={handleStatusChange}
                 renderValue={(selectedOption1) => (
                   <div>
                     {selectedOption1.map((value) => (
                       <div key={value}>{value}</div>
                     ))}
                   </div>
                 )}
               >
                 {options.map((option) => (
                   <MenuItem key={option} value={option}>
                     {option}
                   </MenuItem>
                 ))}
               </Select>
             </FormControl>
           </Grid>
   
           <Grid item xs={12} sm={6} md={4} lg={3}>
             <TextField
               fullWidth
               label="Select From Date"
               type="date"
               value={fromDate}
               onChange={handlesetFromDate}
               InputLabelProps={{
                 shrink: true,
               }}
               inputProps={{
                 max: toDate ? toDate : new Date().toISOString().split('T')[0],
               }}
             />
           </Grid>
   
           <Grid item xs={12} sm={6} md={4} lg={3}>
             <TextField
               fullWidth
               label="Select To Date"
               type="date"
               value={toDate}
               onChange={handlesetToDate}
               InputLabelProps={{
                 shrink: true,
               }}
               inputProps={{
                 min: fromDate,
                 max: new Date().toISOString().split('T')[0],
               }}
             />
           </Grid>
   
           <Grid item xs={12} sm={6} md={4} lg={3}>
             <TextField
               fullWidth
               label="Carton From"
               type="text"
               value={cartonFrom}
               onChange={handleCartonFrom}
               InputLabelProps={{
                 shrink: true,
               }}
             />
           </Grid>
   
           <Grid item xs={12} sm={6} md={4} lg={3}>
             <TextField
               fullWidth
               label="Carton To"
               type="text"
               value={cartonTo}
               onChange={handleCartonTo}
               InputLabelProps={{
                 shrink: true,
               }}
             />
           </Grid>
         </Grid>
   
         <Grid container spacing={2} sx={{ marginTop: 2 }} justifyContent="flex-end">
           <Grid item>
             <Button
               variant="contained"
               color="primary"
               size="small"
               onClick={() => {
                 setPage(1);
                 setPageSize(10);
                 setDetails([]);
                 combo_filter();
               }}
             >
               Filter
             </Button>
           </Grid>
   
           <Grid item>
             <Button
               variant="contained"
               color="primary"
               size="small"
               onClick={clear_values}
               sx={{ marginLeft: 1 }}
             >
               Clear
             </Button>
           </Grid>
         </Grid>
       </Box>
       <button className="lineb" style={{marginLeft:'50px'}}/>
     
<br/>
<div>
       {data1.length > 0 && (
         <div ref={componentRef}>
            <Button variant="contained" color="primary" 
              onClick={handlePrint}
             //  onClick={() => fileInputRef.current.click()}
              >
              Download
     </Button>
           <h1>Data to Print</h1>
           <ul>
             {data1.map((row, index) => (
               <li key={index}>
                 <strong>Carton No:</strong> {row.carton_no}, 
                 <strong> PO Number:</strong> {row.po_number}, 
                 <strong> Mill:</strong> {row.mill}, 
                 <strong> Article:</strong> {row.article}, 
                 <strong> Shade:</strong> {row.shade}, 
                 <strong> Depot:</strong> {row.depot}, 
                 <strong> Full Quantity:</strong> {row.fullqty}, 
                 <strong> Packer ID:</strong> {row.packerid}, 
                 <strong> Gross Weight:</strong> {row.grosswt}, 
                 <strong> Carton Type:</strong> {row.cartontype}, 
                 <strong> Status Code:</strong> {row.statuscode}, 
                 <strong> Carton Date:</strong> {row.carton_date}, 
                 <strong> Count Label:</strong> {row.count_label}
               </li>
             ))}
           </ul>
          
         </div>
       )}
     </div>

         {/* <div style={{ width: '100%' }}> */}
{details.length > 0 && ( 
 <div>
<div style={{width:'95%',display:'flex', justifyContent:'right'}}>    
 
       
   <Button variant="contained" color="primary" 
              onClick={handlePrintSelectedRows}
             //  onClick={() => fileInputRef.current.click()}
              >
              Download
     </Button>
     </div>
       <br/>
       <div  style={{ marginLeft: "55px",display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth:'93%'}}>
     <MUIDataTable
       data={details}
       columns={columns}
       options={{
         filterType: 'checkbox',
        // selectableRows: 'none', // Disable default row selection
        // selectableRows: "multiple", 
        // isRowSelectable: (dataIndex) => {
        //   // Access the isUnique value for the row
        //   return details[dataIndex].isUnique;
        // },
         download: false,
         print: false,
         viewColumns: false,
         search: false,
         sort: false,
         filter: false,
         page: page - 1,
         rowsPerPage: pageSize,
         rowsPerPageOptions: [10, 15, 50, 100, 200, 300],
         count: totalRecords,
         onChangePage: handlePageChange,
         onChangeRowsPerPage: handlePageSizeChange,
         onRowSelectionChange: handleRowSelectionChange,
         downloadOptions: {
           filename: 'CartonStatus.csv',
           filterOptions: {
             useDisplayedColumnsOnly: true,
             useDisplayedRowsOnly: true
           }
         }
       }}
       className="custom-table"
     />
     {/* <button onClick={handlePrintSelectedRows}>Print Selected Rows</button> */}
   
</div></div>
       
)}

      </>) : (window.location.href = "#/login")}   
       </div>
       );
   }
   export default Despatch  