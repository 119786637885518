import React, { useState } from 'react';
import { useFormik } from "formik";
import './login.css'
import Axios from 'axios';
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
function Forget() {
  const onSubmit = async(values, actions) => {
  
    const username = document.getElementById('username').value;

    const password = document.getElementById('npassword').value;


Axios.post(`${process.env.REACT_APP_API_URL}/forget_pass`,{
      username: username,
      password: password, 
    }).then((response) => {
  
      if(response.data =="50")
      {
        window.alert("Error username not exist")
      }
      else
      {
        window.alert("Password Changed Successfully");
        window.location.reload();
      }
    }).catch((err)=>{
      console.log(err)
        window.alert(err)
    });
  };
  
  
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      username:"",
      password: "",
    },
   
    onSubmit,
  });

  console.log(errors);

      return (
        <form className='formm' onSubmit={handleSubmit}>
          <h3>Forget Password</h3>
          <div className="mb-3">
            <label htmlFor="username">UserName</label>
            <input
              type="text"
              className="form-control"
              placeholder="UserName"
              id="username"
              data-testid="forget-username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="npassword">New Password</label>
            <input
              type="password"
              id="npassword"
              data-testid="forget-password"
              className="form-control"
              placeholder="Enter password"
              value={values.npassword}
              onChange={handleChange}
              onBlur={handleBlur}
               />
          </div>
          
          <div className="d-grid">
            <button type="submit" className="btn btn-primary" >
              Submit
            </button>
          </div>
           <p className="forgot-password text-right">
           <Link to="/login">Back</Link>
        </p>
        </form>
      )
    }
  
  export default Forget;