import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import './duedate.css'
import * as XLSX from "xlsx";
import  secureLocalStorage  from  "react-secure-storage";

export default function Production() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const token = secureLocalStorage.getItem("token");
  const [details, setDetails] = useState([])
  const columns = [
    {
      name: "edit",
      options: {
        filter: false,
        label:"Edit",
        selectableRows: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = details[tableMeta.rowIndex].id;
          return (
            <Link to={`/Prod_unit_data?id=${id}`}>
              <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15"/ >
            </Link>
          );
        }
      }
    },
   {name: "pu_code",label:"Code" },
    {name: "pu_name",label:"Name"},
    {name: "pu_doccode",label:"Doc Code"},
    {name:  "pu_address",label:"Address"},
    {name: "pu_contact_person",label:"Contact Person"},
    {name: "sc_vendor",label:"Vendor"}
    
  ];


    useEffect(() => {
       if (isLoggedIn){
      axios.post(`${process.env.REACT_APP_API_URL}/prod-unit/show_proddata`, {
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        }).then((response) => {
        setDetails(response.data)
        console.log(details);
      })
    }
    }, [])
      
  
  return(
    <div>
    {isLoggedIn ? ( <>


        <div className='movv'>
        <Link to="/Prod_unit_data">
            <button type="submit" className="btn btn-primary">
            New
          </button>
        </Link>
        </div>
      <br/>
      <div className='container'>
      <MUIDataTable title={"PRODUCTION UNIT"}
        data={details}
        columns={columns}
        options={{ filterType: 'checkbox',selectableRows: 'none', sort: false}}
      />
    </div>
    </>):(window.location.href = "#/login")}
   </div>
   
  );
};