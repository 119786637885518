import React from 'react'
import Cards1 from '../components/cards/cards'
import Carousel1 from '../components/carousel/carousel'


const Home = () => {
  return (
    <>
    <Carousel1 />
    <Cards1 />
    </>
  )
}

export default Home
