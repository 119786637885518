import React, { useState,useEffect } from 'react';
import './master_data.css'
import { Link ,useParams} from 'react-router-dom';
import Axios from "axios";
import  secureLocalStorage  from  "react-secure-storage";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
function containsOnlychar(str) {
  return /^[a-zA-Z]{1,4}$/
  .test(str);
}
function containsOnlychar10(str) {
  return /^[a-zA-Z\d]{1,10}$/
  .test(str);
}
function containsvarchar25(str) {
  return /^[a-zA-Z0-9\d]{1,25}$/
  .test(str);
}

export default function Vendor_data() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");  
  const location=useLocation();
  const [details,setDetails]=useState([]);
  
  const id = new URLSearchParams(location.search).get('id');  
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const mill = secureLocalStorage.getItem("mill");
  const token = secureLocalStorage.getItem("token");

  useEffect(()=>{
    if (isLoggedIn){
    const getVendorDetails=()=>{
     Axios.post(`${process.env.REACT_APP_API_URL}/vendor/edit_vendordata`,
     {
      id:id,
     },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      if (response !== undefined) {
        setDetails(response.data)
    } else {
        console.log('Response is undefined');
    }
     })
     .catch((error) => {
      console.error(error);
    });
   }
   getVendorDetails()
  }
 },[]);

 const Vendor_data_update = (e) => {
  e.preventDefault();
 
  const sap_plant = document.getElementById("sap_plant").value;
  const sc_vendor = document.getElementById("sc_vendor").value;
  const uname = document.getElementById("uname").value;
  const password = document.getElementById("password").value;
  let ans=containsOnlychar(sap_plant)
  let ans2=containsOnlychar10(sc_vendor)
  let ans3=containsvarchar25(uname)
  
  if (!sap_plant || !sc_vendor || !uname || !password) {
    window.alert("Please fill in all fields.");
    return;
  }
   else if(ans==false)
  {
    window.alert("Invalid SAP Plant")
  }
  else if(ans2==false)
  {
    window.alert("Invalid SC Vendor")
  }
  else if(ans3==false)
  {
    window.alert("Invalid Username")
  }
  else{
  Axios.post(`${process.env.REACT_APP_API_URL}/vendor/Update_vendor`, {
    id:id,
    sap_plant:sap_plant,
    sc_vendor:sc_vendor,
    uname:uname,
    password:password,
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
    if(response.data == "-10")
    {
      window.alert("SC Vendor Name Alreay Exists");
      
    }
    else{
      window.alert("Values updated");
      window.location.href = '#/Vendors';
      window.location.reload(true);
    }
  });
}
 }

const Vendor_data_insert = (e) => {
  e.preventDefault();
  const sap_plant = input2;
  const sc_vendor = input3;
  const uname = input4;
  const password = input5;
  let ans=containsOnlychar(sap_plant);
  let ans2=containsOnlychar10(sc_vendor);
  let ans3=containsvarchar25(uname);
  
  if (!sap_plant || !sc_vendor || !uname || !password ) {
    window.alert("Please fill in all fields.");
    return;
  }
  else if(ans==false)
  {
    window.alert("Invalid SAP Plant")
  }
  else if(ans2==false)
  {
    window.alert("Invalid SC Vendor")
  }
  else if(ans3==false)
  {
    window.alert("Invalid Username")
  }
  else{
  Axios.post(`${process.env.REACT_APP_API_URL}/vendor/Insert_Vendor`, {
    sap_plant : sap_plant,
    sc_vendor : sc_vendor,
    uname : uname,
    password : password,
  },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        }).then((response) => {
    if(response.data == "50")
    {
      window.alert("Vendor already exist");
    }
    else
    {
      window.alert("New Vendor Inserted");
      window.location.href = '#/Vendors';
      window.location.reload(true);
    }
  });}
};


  const inputStyle = { margin: '0 5px' };
console.log(details)
  return (
   
    <div>
          {isLoggedIn ? ( <>
     {details.length >0 &&
      details.map((d) => ( 
            <div>

            <MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
            <MDBCardBody>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Production Unit</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">

            <input type="text" id="prod_unit" style={{inputStyle}} value={d.prod_unit} placeholder={'Production Unit'}  required disabled/>

            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">SAP Plant</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="sap_plant" style={{inputStyle}} defaultValue={d.sap_plant} placeholder={'SAP Plant'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">SC Vendor</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="sc_vendor" style={{inputStyle}} defaultValue={d.sc_vendor} placeholder={'SC Vendor'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">UserName</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="uname" style={{inputStyle}} defaultValue={d.uname} placeholder={'UserName'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Password</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="password" style={{inputStyle}} defaultValue={d.password} placeholder={'Password'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

             

            <button type="submit" className="btn btn-primary" onClick={Vendor_data_update} >
            Save
            </button>
            <Link to="/Vendors">
            <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
            </button>
            </Link>
            
            </MDBCardBody>
            </MDBCard> 
            </MDBCol>

            </div>
     ))}
     {details.length === 0 && 
     <div>

<MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
              <MDBCardBody>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Production Unit</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input1} placeholder={'Production Unit'} disabled/>
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">SAP Plant</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input2} placeholder={'SAP Plant'} onChange={(e) => setInput2(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">SC Vendor</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input3} placeholder={'SC Vendor'} onChange={(e) => setInput3(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">UserName</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input4} placeholder={'UserName'} onChange={(e) => setInput4(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Password</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input5} placeholder={'Password'} onChange={(e) => setInput5(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

          
          <button type="submit" className="btn btn-primary" onClick={Vendor_data_insert} >
            Save
          </button>
          <Link to="/vendors">
          <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
          </button>
          </Link>
                 
              </MDBCardBody>
            </MDBCard> 
          </MDBCol>
      
      </div>
}
      </>) : (window.location.href = "#/login")}
    
     
    </div>
  );
}
