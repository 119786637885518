import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link ,useParams} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './status_change.css';
import  secureLocalStorage  from  "react-secure-storage";
function Status_change({po, orderId, onClose,onStatusUpdate }) {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const activeUser = secureLocalStorage.getItem("activeUser");
  const current_user='SIRIUS'
  const location = useLocation();
  // const id = new URLSearchParams(location.search).get('id');
  const id=orderId;
  const po_num=po;
  console.log(id)
  console.log(po_num)
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [tempStatus, setTempStatus] = useState('');
 const mill=secureLocalStorage.getItem("mill");
 const token = secureLocalStorage.getItem("token");
 const dataObject1 = {};
//  const [orderData, setOrderData] = useState(null);
console.log("order id",orderId)
  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API_URL}/order/fetch_status`, {
      id: orderId,
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      setStatus(response.data);
      setSelectedStatus(response.data[0].statuscode); // Select the first status code from the API response
    });
    const today = new Date();
    const currentTime = today.toLocaleTimeString();
const current_sts=selectedStatus
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const hours = today.getHours().toString().padStart(2, '0');
    const minutes = today.getMinutes().toString().padStart(2, '0');
    const seconds = today.getSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    setCurrentDateTime(formattedDateTime);
  }, []);

  const handleStatusChange = (event) => {
    // setSelectedStatus(event.target.value);
    setTempStatus(event.target.value);
  };

  const addEmployee = () => {
    setSelectedStatus(tempStatus);
    const oid = document.getElementById('ordid').value;
    const sts = tempStatus;
    if(sts==="")
    {
      window.alert("Select a status to update")
      return
    }
    dataObject1[0] = {oid,sts,currentDateTime,mill};
    const jsonData = JSON.stringify(dataObject1);
    Axios.post(`${process.env.REACT_APP_API_URL}/order-log/order_log_insert`, {
      body: jsonData,
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      // alert("Log updated");
     
    });
    Axios.post(`${process.env.REACT_APP_API_URL}/order/order_status_update1`, {
      sts: sts,
      id: oid,
      component:"update_sts",
      last_edited: currentDateTime,
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      alert('Updated');
      onStatusUpdate(oid, sts);
      onClose();
      // window.location.href = `#/update_status`;
      // window.location.reload(true);
     
    });
  };

  return (
    <div>
      
      {/* {status ? (
        <div>
          {/* Render order details for editing */}
          {/* <button onClick={onClose}>Close</button>
        </div>
      ) : ( */}
        {/* <div>Loading...</div>
      )} */} 
    {mill ==="COATS" ? ( 
       <>
       <br />
       <br />
       <div className="form-group">
         <label className="label1">Order ID</label>
         <input type="hidden" id="ordid" value={id} readOnly className="input" />
         <input type="text" id="ordid" value={po_num} readOnly className="input" />
       </div>
       <div className="form-group">
         <label className="label1">Order Status</label>
         <select id="ordstatus" className="status_select" value={tempStatus} onChange={handleStatusChange}>
           <option value="">Select</option>
           <option value="Order Released">Order Released</option>
           <option value="Dyeing">Dyeing</option>
           <option value="Winding">Winding</option>
           <option value="Packing">Packing</option>
           <option value="Shortclosed">Close </option>
         </select><br/>
         <div align="left">
        <button type="submit" className="btn btn-primary" onClick={addEmployee}>
          Update Status
        </button>
        <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary" onClick={onClose}>Close</button>
        {/* <Link to="/update_status">
          <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
          </button>
          </Link> */}
      </div>
       </div>
      
       </>
    ) : (

<>
      <br />
      <br />
      <div className="form-group">
        <label className="label1">Order ID</label>
        <input type="hidden" id="ordid" value={id} readOnly className="input" />
        <input type="text" id="ordid" value={po_num} readOnly className="input" />
      </div>
      <div className="form-group">
        <label className="label1">Order Status</label>
     
        <select
        id="ordstatus"
        className="status_select"
        value={tempStatus}
        onChange={handleStatusChange}
      >
        <option value="">Select</option>
        {selectedStatus === 'Order Created' && (
          <option value="Order Released">Order Released</option>
        )}
        {selectedStatus === 'Order Released' && activeUser!==current_user && (
          <>
            <option value="Dyeing">Dyeing</option>
            <option value="Winding">Winding</option>
          </>
        )}
         {selectedStatus === 'Order Released' && activeUser===current_user && (
          <>
           <option value="Packing">Packing</option>
          </>
        )}
        {selectedStatus === 'Dyeing' && (
          <>
          <option value="Winding">Winding</option>
          <option value="Packing">Packing</option>
          </>
        )}
        {selectedStatus === 'Winding' && (
          <option value="Packing">Packing</option>
        )}
      </select>
       <br/>
        <div align="left">
        <button type="submit" className="btn btn-primary" onClick={addEmployee}>
          Update Status
        </button>
        <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary" onClick={onClose}>Close</button>
       
        {/* <Link to="/update_status">
          <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
          </button>
          </Link> */}
      </div>
      </div>
   
     
      </>
      )}
    </div>
  );
}

export default Status_change;
