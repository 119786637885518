import React from 'react'
import Axios from "axios";
import { useState,useEffect } from "react";
import Select from "react-select";
import  secureLocalStorage  from  "react-secure-storage";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables'
import {Link} from 'react-router-dom';
import "../../routes/track_status.css"
import Status_change from '../../routes/status_change';
import '../../routes/popup.css';
import { Button } from '@mui/material';
export default function Filter_component() {   
  const [details,setDetails]=useState([]);
  const [order_id,setorderids]=useState([]);
  const [oid, setOid] = useState('');
  const [selectedOrdermill, setSelectedOrdermill] = useState('');
  const [selectedArticle, setSelectedArticle] = useState('');
  const[articles,setArticles]=useState([]);
  const[shades,setShades]=useState([]);

  const [inputValue, setInputValue] = useState('');
  const [selectedShade, setSelectedShade] = useState('');
  const role=secureLocalStorage.getItem("role");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedPo, setSelectedPo] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedvalue,setselectedvalue]=useState('10');
  // const[offset,setoffset]=useState(0);
  const [offset, setOffset] = useState(0);
  // const [selectedOrderstatuscode, setSelectedOrderstatuscode] = useState('');
  const [selectedOrderstatuscodes1, setSelectedOrderstatuscodes1] = useState([]);
    const [selectedOrderstatuscodes, setSelectedOrderstatuscodes] = useState([]);
    const [selectedPos, setSelectedPos] = useState([]);
    const [showUpStatusPopup, setShowUpStatusPopup] = useState(false);
  var date1=null;
  var date2=null;
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const uname=secureLocalStorage.getItem("activeUser"); 
  const current_user='SIRIUS'
  var statuses=['Order Created','Order Released','Dyeing','Winding','Packing','Shortclosed','Closed Automatically'
,'Order Reopened'
]
if(role==='Dyeing')
{
   statuses=['Dyeing','Packing']
}
if(role==='Winding')
{
  statuses=['Winding','Packing']
}
if(uname===current_user)
{
  statuses=['Order Created','Order Released','Packing','Shortclosed','Closed Automatically'
  ,'Order Reopened'
  ]
}
useEffect(() => {
  console.log(fromDate)
  console.log(toDate)
  console.log(selectedOrderstatuscodes)
  console.log(selectedPos)
  console.log(selectedArticle)
  console.log(selectedShade)
  if(fromDate ===null && toDate===null && selectedOrderstatuscodes.length ===0 && selectedPos.length===0 && selectedArticle==='' && selectedShade==='' )
  {
    combo_filter(); 
  }
  else if (
    selectedvalue !== '10' ||
    offset !== 0 ||
    page !== 1 ||
    pageSize !== 10
  ) {
    combo_filter();
  }
  
}, [page, pageSize,selectedvalue, offset,]);

const format_date=(utcDate)=>{
  const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
  return istDate;
}
const handleEditClick = (orderId,po) => {
  console.log("ord id",orderId," po",po)
  setSelectedOrderId(orderId);
  setSelectedPo(po)
  setShowUpStatusPopup(true);
};

const handlePopupClose = () => {
  setSelectedOrderId(null);
  setSelectedPo(null)
  setShowUpStatusPopup(false);
};

const handleStatusUpdate = (orderId, newStatus) => {
  console.log("newStatus",newStatus)
  console.log("selectedOrderstatuscodes",selectedOrderstatuscodes)
  if(selectedOrderstatuscodes.includes(newStatus))
  {
    const updatedDetails = details.map(order => {
      if (order.sc_order_no === orderId) {
        return { ...order, statuscode: newStatus };
      }
      console.log("order",order)
      return order;
    });
    console.log("updatedDetails",updatedDetails)
    setDetails(updatedDetails);
    setTotalRecords(updatedDetails.length)
  }
  else if (selectedOrderstatuscodes.length>0 && !selectedOrderstatuscodes.includes(newStatus)){
    const updatedFilteredData = details.filter(order => order.sc_order_no !== orderId);
    console.log("updatedFilteredData",updatedFilteredData)
    setDetails(updatedFilteredData);
    setTotalRecords(updatedFilteredData.length)
  }
else {
  // When no filter is applied, update the details state
  const updatedDetails = details.map(order => {
    if (order.sc_order_no === orderId) {
      return { ...order, statuscode: newStatus };
    }
    console.log("order",order)
    return order;
  });

  setDetails(updatedDetails);
  setTotalRecords(updatedDetails.length)
}

};
const columns = [
  {
    name: "Edit",
    options: {
      filter: false,
      label:"Edit",
      customBodyRender: (value, tableMeta, updateValue) => {
       var id;
       var po_num;
        if(filteredData.length===0)
        {
          id = details[tableMeta.rowIndex].sc_order_no;
           po_num=details[tableMeta.rowIndex].po_number;
          
        }
        else{
          id = filteredData[tableMeta.rowIndex].sc_order_no;
          po_num=filteredData[tableMeta.rowIndex].po_number;
        }
        // const id = details[tableMeta.rowIndex].sc_order_no;
        // const po_num=details[tableMeta.rowIndex].po_number;
        // console.log("front end",po_num)
        // console.log(id)
        return (
          // <Link to={`/status_change?id=${id}`}>
          //    <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15"/ > 
           
          // </Link>
          <Link to="#" onClick={() => handleEditClick(id,po_num)}>
          <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15" alt="Edit" />
        </Link>
        );
      }
    }
  },
  {name:"po_number",label:"PO Number"},
{name:"material", label: "Art",
options: {
  customBodyRender: (value) =>  value.substr(0, 4) 
}
},
{name:"material", label: "Material"},
{name:"sap_order_date", label: "SAP Order Date",
options: {
customBodyRender: (value) => format_date(value)
}
},
{name:"ord_qty", label: "Order Quantity"},
{name:"pendingqty", label: "Pending Quantity"},
{
name:"due_date", 
label: "Due Date",
options: {
  customBodyRender: (value) => format_date(value)
}
},
{name:"statuscode", label: "Status"},
{name:"uname", label: "User Name"},
{name:"rec_plant", label: "Rec_plant"}
];
const handlePageChange = (newPage) => {
  const newOffset = newPage * pageSize; // Calculate the new offset
  setPage(newPage + 1);
  setOffset(newOffset);
};

const handlePageSizeChange = (newPageSize) => {
  setPageSize(newPageSize);
  setPage(1); // Reset the page to 1 when page size changes
  setOffset(0); // Reset the offset to 0 when page size changes
};
  const [showFilters, setShowFilters] = useState(false);
  const mill = secureLocalStorage.getItem("mill");
  const token = secureLocalStorage.getItem("token");
  const handleFilterClick = () => {
    setShowFilters(!showFilters);
  }
  const buttonStyle = showFilters ? 'line-button' : 'filter-button';
  function handleChange(event){
    setselectedvalue(event.target.value);
    setoffset(0);
  }
  useEffect(() => {
    const getAllDetails = () => {
      Axios.post(`${process.env.REACT_APP_API_URL}/label/article_disp`,{
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        }).then((response) => {
          // console.log(response.data)
          setArticles(response.data)
      });
      Axios.post(`${process.env.REACT_APP_API_URL}/label/shade_disp`,{
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        }).then((response) => {
          // console.log(response.data)
          setShades(response.data)
      });
     
}
getAllDetails()
}, []);   

const clear_values = () => {
  // setOid('');
  // setSelectedOrderstatuscodes([]);
  // setSelectedOrdermill(null);
  // setSelectedArticle(null)
  // setSelectedShade(null)
  // setFromDate(null);
  // setToDate(null);
  // setSelectedPos([])
  // setInputValue('')
  Axios.post(`${process.env.REACT_APP_API_URL}/label/article_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setArticles(response.data)
  });
  Axios.post(`${process.env.REACT_APP_API_URL}/label/shade_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setShades(response.data)
  });
  console.log("selectedOrderstatuscodes",selectedOrderstatuscodes)
  combo_filter()
  // if (mill == "COATS") {
  
  //   Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,{
  //   },{
  //     headers: {
  //       'x-access-token': token,
  //       'Content-Type': 'application/json'}
  //     }).then((response) => {
   
  //   setorderids(response.data)
  //   props.onFilter(response.data);
  //   });
  //   }
  //   else{
  //   Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp_vendor1`,{
    
  //    mill:mill,
  //    role:role,
  //   },{
  //     headers: {
  //       'x-access-token': token,
  //       'Content-Type': 'application/json'}
  //     }).then((response) => {
  //   setorderids(response.data)
  //   props.onFilter(response.data);
  //   });
  //   }
  }

const getdetails = (selectedOption) => {
  setOid(selectedOption.value);
}

const getdetails1 = (selectedOption) => {
  setSelectedOrdermill(selectedOption.value);
}

const getdetails2 = (selectedOptions) => {
  setSelectedOrderstatuscodes(selectedOptions.map(option => option.value));
}
const getdetails5 = (selectedOptions) => {
  
  setSelectedPos(selectedOptions);
};
const getdetails3 = (selectedOption) => {
  setSelectedArticle(selectedOption.value);
}
const getdetails4 = (selectedOption) => {
  setSelectedShade(selectedOption.value);
}
const combo_filter = async() => {
  if(toDate && (fromDate===null || fromDate===""))
  {
    window.alert("Select From Date ")
    return
  }
  if(mill == "COATS") {
 const {data}= await Axios.post(`${process.env.REACT_APP_API_URL}/order/combo_filter`, {
      id: oid,
      mill: selectedOrdermill,
      statuscode: selectedOrderstatuscodes,
      date1:fromDate,
      date2:toDate,
      article:selectedArticle,
      shade:selectedShade,
      po:selectedPos,
      po_textbox:inputValue,
      page:page,
      pageSize:pageSize,
      uname:uname
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    })
    const totalRecords = data.totalCount;
    const response = data.result;
        setTotalRecords(totalRecords)
          const totalPages = Math.ceil(totalRecords / pageSize);
          console.log("total pages",totalPages)
          setTotalPages(totalPages);
          if (page === 1) {
            setDetails(response);
          }
          else{
            setDetails((prevDetails) => [...prevDetails, ...response]);
          }

}
  else{
    const {data}= await Axios.post(`${process.env.REACT_APP_API_URL}/order/combo_filter_vendor`, {
      id: oid,
      mill_search: selectedOrdermill,
      statuscode: selectedOrderstatuscodes,
      date1:fromDate,
      date2:toDate,
      mill:mill,
      article:selectedArticle,
      shade:selectedShade,
      role:role,
      page:page,
      pageSize:pageSize,
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    })
    console.log("data",data)
      const totalRecords = data.totalCount;
      const response = data.result;
          setTotalRecords(totalRecords)
            const totalPages = Math.ceil(totalRecords / pageSize);
            console.log("total pages",totalPages)
            setTotalPages(totalPages);
            if (page === 1) {
              setDetails(response);
            }
            else{
              setDetails((prevDetails) => [...prevDetails, ...response]);
            }
  
  }
}

  // Define options for the Select component
 const options = order_id.map((item) => ({
  label: item.po_number,
  value: item.po_number,
}));

// Define options for the Select component
const options1 = order_id.map((item) => ({
  label: item.mill,
  value: item.mill,
}));
const filteredOptions1 = options1.filter((options1, index, self) =>
index === self.findIndex((t) => (
  t.value === options1.value && t.label === options1.label
))
);
// Define options for the Select component
const options2 = statuses.map((item) => ({
  label: item,
  value: item,
}));



// const filteredOptions3 = options3.filter((options3, index, self) =>
//   index === self.findIndex((t) => (
//     t.value === options3.value && t.label === options3.label
//   ))
// );

const filteredOptions3 = articles.map((item) => ({
  label: item.material_code,
  value: item.material_code,
}));
// Define options for the Select component
const options4 = shades.map((item) => ({
  label: item.shade,
  value: item.shade,
}));
const filteredOptions4 = options4.filter((options4, index, self) =>
  index === self.findIndex((t) => (
    t.value === options4.value && t.label === options4.label
  ))
);
// const setdate1=()=>{
//   date1=document.getElementById("from_date").value;
//  console.log("date1 frontend",date1)
//  setFromDate(date1);
//  }
 const setdate1 = (event) => {
  console.log(event.target.value)
  setFromDate(event.target.value);
};
 const setdate2=(event)=>{

  console.log("date2 frontend",event.target.value)
  setToDate(event.target.value);
 }
 const handleInputChange = (event) => {
  setInputValue(event.target.value);
};

 const placeholderOption1 = { label: "Search PO Number", value: null };
 const placeholderOption2 = { label: "Search Mill", value: null };
 const placeholderOption3 = { label: "Search Statuscode", value: null };
 const placeholderOption4 = { label: "Search Article", value: null };
 const placeholderOption5 = { label: "Search Shade", value: null };
 const downloadData = async () => {
  var data='';
  var totalRecordsResponse
  console.log("fromDate",fromDate)
  console.log("toDate",toDate)
  console.log("selectedOrderstatuscodes",selectedOrderstatuscodes)
  console.log("selectedPos",inputValue)
  console.log("selectedArticle",selectedArticle)
  console.log("selectedShade",selectedShade)

  if(fromDate !==null || toDate!==null || selectedOrderstatuscodes.length !==0 || inputValue.length!==0 || selectedArticle!=='' || selectedShade!==''
  //  || role==="Dyeing" || role==="Winding" 
   )
  {
     totalRecordsResponse= await Axios.post(`${process.env.REACT_APP_API_URL}/order/status_data_download`, {
      id: oid,
      mill: mill,
      statuscode: selectedOrderstatuscodes,
      date1:fromDate,
      date2:toDate,
      article:selectedArticle,
      shade:selectedShade,
      po:selectedPos,
      po_textbox:inputValue,
      page:page,
      pageSize:pageSize,
      role:role,
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    })
  }
  else{
    totalRecordsResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/order/get_total_rows`, {  
      mill:mill,
      role:role
    }, {
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'
  }
  });
  }
 
      
 console.log("totalRecordsResponse",totalRecordsResponse)
  data = totalRecordsResponse.data;
  

 console.log("data",data)
 console.log("data",data.length)
 if(data.length!=0)
 {
 const ws = XLSX.utils.json_to_sheet(data);

 
  const wb = XLSX.utils.book_new(); // Create a new workbook
  XLSX.utils.book_append_sheet(wb, ws, 'data'); // Add the worksheet to the workbook
   
  // Write the workbook to a buffer
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   
  // Create a Blob from the buffer
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
   
  // Create an anchor tag for downloading
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = 'Update Status Data.xlsx';
   
  // Trigger the download
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
   
  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(a.href);
  
 }

}
  return (
    <div>
     <h3 style={{marginLeft:"55px"}} data-cy="order_label">Update Status</h3><br/>
    <button  onClick={handleFilterClick} style={{marginLeft:'50px'}} className={buttonStyle}>Filter</button>
{showFilters && (<div>
    <div style={{width:'80%',display:'flex', justifyContent:'space-evenly',marginLeft:'50px'}}>

    {/* <Select className="select_ord" 
    value={oid ? { label: oid, value: oid } : null}
  options={[placeholderOption1, ...options]}
    onChange={getdetails} placeholder="Search PO Number"/>
    <Select className="select_ord" 
    value={ selectedOrdermill? { label: selectedOrdermill, value: selectedOrdermill } : null}
  options={[placeholderOption2, ...filteredOptions1]}
    onChange={getdetails1} placeholder="Search Mill"  /> */}
    <Select className="select_ord" 
    value={selectedArticle ? { label: selectedArticle, value: selectedArticle } : null}
  options={[placeholderOption4, ...filteredOptions3]}
    onChange={getdetails3} placeholder="Search Article"/>

     <Select className="select_ord" 
    value={selectedShade ? { label: selectedShade, value: selectedShade } : null}
  options={[placeholderOption5, ...filteredOptions4]}
    onChange={getdetails4} placeholder="Search Shade"/>
      {/* <Select className="select_ord" 
    value={ selectedOrderstatuscode? { label: selectedOrderstatuscode, value: selectedOrderstatuscode } : null}
    options={[placeholderOption3, ...options2]}
     onChange={getdetails2} placeholder="Search Statuscode"  /> */}
 <Select
      className="select_ord"
      isMulti 
      value={selectedOrderstatuscodes.map(option => ({ label: option, value: option }))}
      options={options2}
      onChange={getdetails2}
      placeholder="Search Statuscode"
    />
    </div>
    <div style={{width:'50%',display:'flex', justifyContent:'space-evenly',marginLeft:'50px'}}>

    {role === "Admin" && (
    <div><br/>
    {/* <TextField 
        label="Enter PO's (Eg:PO1,PO2...)"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
      /> */}
      <TextareaAutosize
        minRows={3} // You can adjust the number of rows as needed
        placeholder="Enter PO's (Eg:PO1,PO2...)"
        value={inputValue}
        onChange={handleInputChange}
        style={{ width: '400px', height: '100px' }}
      />
    </div>
    )}
    {/* </div>
<div style={{width:'40%',display:'flex', justifyContent:'space-evenly',marginLeft:'20px'}}> */}

<div >
<label id="label">From</label>
<input className="date_filter" type="date" id="from_date"  value={fromDate || ''}
//  max={new Date().toISOString().split('T')[0]}
max={toDate ? toDate : new Date().toISOString().split("T")[0]}
  onChange={setdate1}></input></div>
<div>
<label id="label">To</label>
<input className="date_filter" type="date" id="to_date" value={toDate || ''} min={fromDate || ''} max={new Date().toISOString().split('T')[0]} onChange={setdate2}></input></div>
{/* <div style={{width:'40%'}}><br/>
    {role === "Admin" && (
      <Select
      className="select_ord"
      value={selectedPos}
      options={options3}
      onChange={getdetails5}
      isMulti
      placeholder="Search POS"
    />
    )}
    </div> */}
 
</div>

<button style={{float: 'right',marginRight:'100px'}} className="btn btn-primary" id="filter_button"  onClick={() => {
 setPage(1);
 setPageSize(10);
 setDetails([]);
 combo_filter();
}}>Apply</button>

<button style={{float: 'right',marginRight:'20px'}} className="btn btn-primary" id="filter_button" onClick={()=>{
  setOid('');
  setSelectedOrderstatuscodes([]);
  setSelectedOrdermill(null);
  setSelectedArticle(null)
  setSelectedShade(null)
  setFromDate(null);
  setToDate(null);
  setSelectedPos([])
  setInputValue('')
  setPage(1);
  setPageSize(10);
  setDetails([]);
  clear_values();
  }}>Clear</button>

<br/><br/>
<button className="lineb" style={{marginLeft:'50px'}}/></div>

)}
   <br/> <br/><div style={{width:'95%',display:'flex', justifyContent:'right'}}>       
        <Button variant="contained" color="primary" 
               onClick={downloadData}
              //  onClick={() => fileInputRef.current.click()}
               >
               Download
      </Button>
      </div><br/>
      <div style={{ marginLeft: "55px",display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth:'93%'}}>
          <div style={{ width: '100%' }}>
       <MUIDataTable 
    data={details}
    columns={columns}
    options={{
      // filterType: 'checkbox',
      download:false,
      print:false,
      selectableRows: 'none',
      sort: false,
      filter: false,
      viewColumns:false,
      search:false,
      page: page - 1,
      rowsPerPage: pageSize,
      rowsPerPageOptions: [10, 20, 100,300,500],
      count: totalRecords,
      onChangePage: handlePageChange,
      onChangeRowsPerPage: handlePageSizeChange,
downloadOptions : {
  filename: 'OrderDetails.csv',
  filterOptions: {
    useDisplayedColumnsOnly: true,
    useDisplayedRowsOnly: true
  }
}
    }}
    className="custom-table" 
    />   </div>
    </div>
       {showUpStatusPopup && (
        <div className="popup">
          <Status_change po={selectedPo} orderId={selectedOrderId} onClose={handlePopupClose} onStatusUpdate={handleStatusUpdate} />
        
        </div>
      )}
    </div>
  )
}
