import React, { useState,useEffect } from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import { Link } from 'react-router-dom';
import Axios from "axios";
import * as XLSX from "xlsx";
import './excel.css'
import Excel_component from '../components/excel/excel_component';
import Excel_download from '../components/excel/excel_download';
// drag drop file component
export default function Master_data_upload() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = secureLocalStorage.getItem("token");
  var count=1;
  var error_text="";
  var str_count=0;
  var nerrors=0
 var fname="";
 const columns = [
  ['material_group','material_code','material_desc','material_uom','material_sc','material_wbc','status'],
  
];
const file_name="master_data.xlsx";
 const handleUpload = (details) => {
  readExcel(details);
};
  
  const readExcel = (file) => {
    
    setIsLoading(true);
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        const filteredData = data.filter(row => {
          return (
            row.material_group &&
            row.material_code &&
            row.material_desc &&
            row.material_uom &&
            row.material_sc &&
            row.material_wbc &&
            row.status
          );
        });
  
        if (filteredData.length !== data.length) {
          window.alert("Empty values are present in the file. Please check and try again.");
          setIsLoading(false);
          return;
        }
  
        resolve(filteredData);
        
       resolve(data);
       function containsOnlyNumbers(str) {
        return /^[a-zA-Z0-9]+$/.test(str);
      }
      function containsOnlystring(str) {
        return /^\b(Active|Inactive)\b/.test(str);

      }
      for(let k=0;k<data.length;k++)
      {
        let line=k+2;
        let ans=containsOnlyNumbers(data[k].material_group) && containsOnlyNumbers(data[k].material_code) && containsOnlyNumbers(data[k].material_desc) && containsOnlyNumbers(data[k].material_uom)&& containsOnlyNumbers(data[k].material_sc)&& containsOnlyNumbers(data[k].material_wbc)&& containsOnlystring(data[k].status)
        if(containsOnlyNumbers(data[k].material_group)== false)
        {
         error_text +="Line "+line+" material_group is not valid\n";
         nerrors=nerrors+1
         console.log(error_text)
        }
        if(containsOnlyNumbers(data[k].material_code)== false)
        {
         error_text +="Line "+line+" material_code is not valid\n";
         nerrors=nerrors+1
          console.log(error_text)
        }
        if(containsOnlyNumbers(data[k].material_desc)== false)
        {
         error_text +="Line "+line+" material_desc is not valid\n";
         nerrors=nerrors+1
          console.log(error_text)
        }
        if(containsOnlyNumbers(data[k].material_uom)== false)
        {
         error_text +="Line "+line+" material_uom is not valid\n";
         nerrors=nerrors+1
          console.log(error_text)
        }
        if(containsOnlyNumbers(data[k].material_sc)== false)
        {
         error_text +="Line "+line+" material_sc is not valid\n";
         nerrors=nerrors+1
          console.log(error_text)
        }
        if(containsOnlyNumbers(data[k].material_wbc)== false)
        {
         error_text +="Line "+line+" material_wbc is not valid\n";
         nerrors=nerrors+1
          console.log(error_text)
        }
        if(containsOnlystring(data[k].status)== false)
        {
         error_text +="Line "+line+" status is not valid\n";
         nerrors=nerrors+1
         console.log(error_text)
        }
        if(ans == false)
        {
          str_count = str_count+1;
        }
      }

      if(str_count>0)
      {
        document.getElementById('tb1').style.visibility = "hidden";
        window.alert("Invalid contents in the file.. Please upload the correct file \n"+error_text)
        window.location.reload(true);
        }
        
      
      }
      
      

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
      setIsLoading(false);
      document.getElementById("tb1").removeAttribute("hidden")
     document.getElementById('tb1').style.visibility = "visible";
    });
  };
  
  
  const Insert_Mdata = (e) => {
    e.preventDefault();
    for (let i = 1; i <= items.length; i++) {
    const material_group = document.getElementById('material_group'+i).value;
    const material_code = document.getElementById('material_code'+i).value
    const material_desc = document.getElementById('material_desc'+i).value
    const material_uom = document.getElementById('material_uom'+i).value
    const material_sc=document.getElementById("material_sc"+i).value
    const material_wbc=document.getElementById("material_wbc"+i).value
    const status=document.getElementById("status"+i).value

    if (!material_group || !material_code || !material_desc || !material_uom || !material_sc || !material_wbc || !status) {
      window.alert("Please fill in all fields.");
      return;
    }

    Axios.post(`${process.env.REACT_APP_API_URL}/material/bulk_insert_mdata`, {
      material_group:material_group,
      material_code:material_code,
      material_desc:material_desc,
      material_uom:material_uom,
      material_sc:material_sc,
      material_wbc:material_wbc,
      status:status,
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
    
    })}
    window.alert("Values updated");
    window.location.href = '#/Master';
    window.location.reload(true);

  };
  
  return (
    <div>
      {isLoggedIn ? ( <>
      <br/>
    
          <Link to="/Master">
          <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
          </button>
          </Link>
          <br/><br/>
          <Excel_component onUpload={handleUpload}/>
          <Excel_download data={columns} file_name={file_name}></Excel_download>
    <br/><br/>
    <div>
 
   
    
  </div>
  <br/>
  <br/>
  <div>
 
     
 {isLoading && (
   <div className="loading-overlay">
     <div className="loading-icon"></div>
   </div>
 )}
</div>
    <div>
    <table className="table container" id="tb1" hidden="hidden">
        <thead>
          <tr>
            <th scope="col">material_group</th>
            <th scope="col">material_code</th>
            <th scope="col">material_desc</th>
            <th scope="col">material_uom</th>
            <th scope="col">material_sc</th>
            <th scope="col">material_wbc</th>
            <th scope="col">status</th>
            
          </tr>
        </thead>
       
         
          <tbody>
          
             {items.map((d) => (
                <tr key={d.id}>
                 <td><input type="text" id={"material_group"+count} value={d.material_group} required></input></td>
                 <td><input  type="text" id={"material_code"+count} value={d.material_code}  required></input></td>
                 <td><input  type="text" id={"material_desc"+count} value={d.material_desc} required></input></td>
                 <td><input  type="text" id={"material_uom"+count} value={d.material_uom} required></input></td>
                 <td><input  type="text" id={"material_sc"+count} value={d.material_sc}  required></input></td>
                 <td><input  type="text" id={"material_wbc"+count} value={d.material_wbc}  required></input></td>
                 <td><input  type="text" id={"status"+count} value={d.status} onLoad={count=count+1} required></input></td>
               
               </tr>
             
            
             ))}
             <tr><td colspan="5" align="right"> <div>
          <button type="submit" className="btn btn-primary" onClick={Insert_Mdata} >
            Save
          </button></div></td></tr>
           </tbody>

      </table> </div>
      </>) : (window.location.href = "#/login")}  
    </div>
  );
};

