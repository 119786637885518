import React, { useState,useEffect } from 'react';
import Axios from "axios";
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import  secureLocalStorage  from  "react-secure-storage";
export default function Glabel1() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const activeUser = secureLocalStorage.getItem("activeUser");
  const currentUser='SIRIUS'
    const [order_id,setorderids]=useState([]);
    const [details,setDetails]=useState([]);
    const [date, setDate] = useState("");
    const [lastOrderId, setLastOrderId] = useState(0);
    const [labelid_details,setlabeldetails]=useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOrderId1, setSelectedOrderId1] = useState(null);
    const [selectedOrderId2, setSelectedOrderId2] = useState(null);
    const [article,setSelectedArticle]=useState([]);
    const [selectedArticle, setArticle] = useState('');
    const dataObject = {};
    const [shade,setSelectedShade]=useState([]);
    const [selectedShade, setShade] = useState('');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [rows1, setRows] = useState([]);
    // var total_box=0
    var label_type=""
    var res_data=0
    var qty=0
    const [depo,setSelectedDepo]=useState([]);
    const [selectedDepo, setDepo] = useState('');
    const mill = secureLocalStorage.getItem("mill");
    const token = secureLocalStorage.getItem("token");
    const [currentDateTime, setCurrentDateTime] = useState("");
    useEffect(() => {
      if (isLoggedIn){
        const getAllDetails = () => {
          if (mill == "COATS") {
          
            Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,{
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              }).then((response) => {
             
            setorderids(response.data)
            });
            }
            else{
            Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp_vendor1`,{
            
             mill:mill,
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              }).then((response) => {
            setorderids(response.data)
            });
            }
          }
          getAllDetails()
      const today = new Date();
      const currentTime=today.toLocaleTimeString();

      const formattedDate = today.toISOString().substr(0, 10);
      setDate(formattedDate);
      const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  
  const formattedDateTime = `${year}-${month}-${day}`;
  setCurrentDateTime(formattedDateTime);
  Axios.post(`${process.env.REACT_APP_API_URL}/label/article_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setSelectedArticle(response.data)
  });
  Axios.post(`${process.env.REACT_APP_API_URL}/label/shade_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setSelectedShade(response.data)
  });
  Axios.post(`${process.env.REACT_APP_API_URL}/label/depo_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setSelectedDepo(response.data)
  });
  Axios.post(`${process.env.REACT_APP_API_URL}/order-log/fetch_last_order_id1`,{
    
},{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  }).then((response) => {
 
 setLastOrderId(response.data[0].carton_no)
});
// if (mill == "COATS") {
//   Axios.post(`${process.env.REACT_APP_API_URL}/order/id_disp`,{
//   },{
//     headers: {
//       'x-access-token': token,
//       'Content-Type': 'application/json'}
//     }).then((response) => {
//    setorderids(response.data)
//   });
// }
//   else{
//     Axios.post(`${process.env.REACT_APP_API_URL}/order/id_disp_vendor`,{
      
//       uname:mill,
//   },{
//     headers: {
//       'x-access-token': token,
//       'Content-Type': 'application/json'}
//     }).then((response) => {
//   setorderids(response.data)
//   });
//   }
  // getdetails();
}
  }, []);

    var count=1

    const getdetails = async () => {
      setIsLoading(true); 
      if(selectedArticle ==='' && selectedShade ==='' && selectedDepo===''){
        
        window.alert("Select Article, Shade, Depo")
        window.location.reload(true);
        
      }
      console.log(selectedArticle?.value)
     const article_selected=selectedArticle?.value
     const shade_selected=selectedShade?.value
     const depo_selected=selectedDepo?.value
     try {
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/label/show_order_details`, {
        article: article_selected,
        shade: shade_selected,
        rec: depo_selected,
        mill: mill,
        type:"Solid",
      }, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
     
      const fetchedDetails = response.data;
      setDetails(fetchedDetails);
  
      const newRows = await Promise.all(fetchedDetails.map(async (d, index) => {
        console.log("ord qty"+d.ord_qty)
        console.log("pending qty",d.pendingqty)
        console.log("label_count",d.label_count)
        const quantity = await calc_quantity1((d.ord_qty-d.label_count), d.article,d.po_number);
        const quantity1 = await calc_quantity(d.ord_qty, d.article);
        console.log("quantity",quantity)
        const max_carton=quantity+((quantity*10)/100);
        // let roundedMaxCarton;
// if (max_carton - Math.floor(max_carton) > 0.5) {
//   roundedMaxCarton = Math.ceil(max_carton);
// } else {
//   roundedMaxCarton = Math.floor(max_carton);
// }
console.log("qty",qty)
var finsihed_qty;
if(d.label_count!=0)
 finsihed_qty=(d.label_count)/qty;
else
finsihed_qty=(d.ord_qty-d.pendingqty)/qty

if (finsihed_qty - Math.floor(finsihed_qty) > 0.5) {
  finsihed_qty = Math.ceil(finsihed_qty);
} else {
  finsihed_qty = Math.floor(finsihed_qty);
}
// console.log("roundedMaxCarton",roundedMaxCarton)
console.log("quantity",quantity)
console.log("finsihed_qty",finsihed_qty)
let dec_max_carton=quantity-finsihed_qty;
console.log("dec_max_carton",dec_max_carton)
if(dec_max_carton <0)
dec_max_carton=0
        return (
          <tr> 
          <input type="hidden" id={'mill'+count} value={d.mill}></input>
          <input type="hidden" id={'pu_code'+count} value={d.pu_code}></input>
          <input type="hidden" id={'sc_order_no'+count} value={d.sc_order_no}></input>
          <input type="hidden" id={'ord_qty'+count} value={d.ord_qty}></input>
          <input type="hidden" id={'pending_qty'+count} value={d.pendingqty}></input>
          <input type="hidden" id={'label_count'+count} value={d.label_count}></input>
          {/* <td><input type="text" id={'carton_no'+count} value={orderId1}></input></td>  */}
          <td><input type="text" id={'po_number'+count} value={d.po_number}></input></td>
         {/* <td><input type="hidden" id={'mill'+count} value={d.mill}></input></td>
         <td><input type="hidden" id={'pu_code'+count} value={d.pu_code}></input></td> */}
         <td><input type="text" id={'article'+count} value={d.article}></input></td>
         <td><input type="text" id={'shade'+count} value={d.shade}></input></td>
         <td><input type="text" id={'rec_plant'+count} value={d.rec_plant} ></input></td>
     
         <td><input type="number" id={'ord_qty1'+count} value={quantity1}></input></td>
         <td> <input type="text" id={'pending_qty'+count} value={d.pendingqty}></input></td>
         {/* <td><input type="text" id={'packer_id'+count}  onLoad={count=count+1}></input></td> */}
         <td><input type="text" id={'box_qty'+count}  ></input></td>
         <td><input type="text" id={'max_carton'+count}  value={quantity} onLoad={count=count+1}></input></td>
         
       </tr>
        );
      }));
  
      setRows(newRows);
    } catch (error) {
      console.error('Error fetching details:', error);
    }
    setIsLoading(false); 

    }

    const fetchDataAndCalculate = async (article,pending_qty) => {
   const response= await Axios.post(`${process.env.REACT_APP_API_URL}/label/box_calc`, {
        article:article,
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         })
        console.log(response.data[0].at_cc)
        res_data=response.data[0].at_cc
        if(res_data==0)
        {
         console.log(res_data)
       return null;
     }
      else{
      
       const box=Math.ceil(pending_qty/res_data);
        console.log(box)
      return box;
       }
    }
    const calc_quantity= async (order_qty,article)=>{
      const response= await Axios.post(`${process.env.REACT_APP_API_URL}/label/box_calc`, {
        article:article,
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         })
        console.log(response)
        qty=response.data[0].at_cc
        if(qty==0)
        {
         console.log(qty)
       return null;
     }
      else{
       const box=order_qty/qty;
       console.log(box)
       let box1;
       console.log(box - Math.floor(box))
       if (box - Math.floor(box) > 0.5) {
         box1 = Math.ceil(box);
       } else {
         box1 = Math.floor(box);
       }

        console.log(box1)
      return box1;
       }
    }
    const calc_quantity1= async (order_qty,article,po)=>{
      const response= await Axios.post(`${process.env.REACT_APP_API_URL}/label/box_calc`, {
        article:article,
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         })
        console.log(response)
        qty=response.data[0].at_cc
        if(qty==0)
        {
         console.log(qty)
       return null;
     }
      else{
       const box=(((order_qty*10)/100)+order_qty)/qty;
       console.log("calc_quantity1",box)
       let box1;
       console.log(box - Math.floor(box))
       if (box - Math.floor(box) > 0.5) {
         box1 = Math.ceil(box);
       } else {
         box1 = Math.floor(box);
       }
       const max_box=((order_qty*10)/100)+order_qty
        console.log("box",max_box)
        console.log("total",qty)
       if(Number(max_box)<qty)
       {
        window.alert("Solid Label cannot be geenrated for "+po)
        return 0
       }
        console.log(box1)
      return box1;
       }
    }
  const generatelabel = async(e) => {
     e.preventDefault();
     setButtonDisabled(true);
     setIsLoading(true);
     if(details.length===0)
     {
      window.alert("Select Labels")
      setButtonDisabled(false);
      return;
     }
     const packer_id = document.getElementById('packer_id').value;
     if ( !packer_id) {
      window.alert("Please fill Packer ID");
      setButtonDisabled(false);
      setIsLoading(false);
      return;
    }
      if (packer_id) {
        try {
          const response = await Axios.post(`${process.env.REACT_APP_API_URL}/label/packer_id_check`, { mill: mill }, {
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'
            }
          });
    
          console.log("mill", mill);
          console.log("packer id", packer_id);
          console.log("packer id res", response.data);
    
          if (mill === "COATS") {
            if (packer_id !== "madura_admin" && packer_id !== "admin_healer") {
              console.log("packer_id!=madura_admin && packer_id!=admin_healer");
              window.alert("Wrong Packer ID");
              setButtonDisabled(false);
              setIsLoading(false);
              return;
            }
          } else {
            if (packer_id !== response.data[0].uname) {
              window.alert("Wrong Packer ID");
              setButtonDisabled(false);
              setIsLoading(false);
              return;
            }
          }
    for(let i = 1;i <= details.length;i++){
  
      // const carton_no=document.getElementById('carton_no'+i).value;
    
      const box_qty = document.getElementById('box_qty'+i).value;
      const  sc_order_no=document.getElementById('sc_order_no'+i).value;
      const po_number=document.getElementById('po_number'+i).value;
      const  mill=document.getElementById('mill'+i).value;
      const  pu_code=document.getElementById('pu_code'+i).value;
      const article=document.getElementById('article'+i).value;
      const  shade=document.getElementById('shade'+i).value;
      const rec_plant=document.getElementById('rec_plant'+i).value;
      const fullqty=document.getElementById('ord_qty'+i).value;
      const pending_qty=document.getElementById('pending_qty'+i).value;
      const maximum_carton=document.getElementById('max_carton'+i).value;
      const total_box= await fetchDataAndCalculate(article,pending_qty);
     var label_count=document.getElementById('label_count'+i).value;
      // console.log("console func",fetchDataAndCalculate(article,fullqty));
      const numericInput = /^[0-9]*$/;

      if (!numericInput.test(String(box_qty))) {
        window.alert("Please enter number in Quantity");
        setButtonDisabled(false);
        setIsLoading(false);
        return;
      }
      console.log("tot box",total_box)

      console.log("box qty",box_qty)

      console.log("max carton",maximum_carton)
        if(total_box == null)
        {
          window.alert("Null in at_acc")
          setButtonDisabled(false);
          setIsLoading(false);
         return
        }
        if(activeUser==currentUser)
        {
          const response= await Axios.post(`${process.env.REACT_APP_API_URL}/label/box_calc`, {
            article:article,
           },{
             headers: {
               'x-access-token': token,
               'Content-Type': 'application/json'}
             })
            console.log(response)
            qty=response.data[0].at_cc
          if(Number(box_qty)*qty > Number(pending_qty))
          {
            window.alert("Wrong Box quantity")
        
            setButtonDisabled(false);
            setIsLoading(false);
           return;
          }
        }
      
        if(Number(box_qty) > Number(maximum_carton))
        {
         window.alert("Wrong Box quantity")
        
         setButtonDisabled(false);
         setIsLoading(false);
        return;
        }
  //   if ( !box_qty) {
  //    window.alert("Please fill Box Quantity");
  //    setButtonDisabled(false);
  //    setIsLoading(false);
  //    return;
  //  }
  
   else{
    if(box_qty!=0 && box_qty!=undefined && box_qty!=null){
    var cones=pending_qty
  var pendingqty=pending_qty-(res_data*box_qty) // res_data- 96, pending_qty=how many pending
  if(fullqty!==pending_qty && label_count==0)
  label_count=Number(fullqty)-Number(pending_qty)+Number(res_data*box_qty);
  else
   label_count=Number(label_count)+Number(res_data*box_qty);
  // if( pendingqty <0)
  // pendingqty=0
  if(cones <=(res_data*box_qty))
    label_type="Loose"
  else 
   label_type="Solid"
  console.log(label_type)
    // dataObject[i] = {carton_no,packer_id, sc_order_no,mill,pu_code,article,shade,rec_plant,fullqty,currentDateTime,pendingqty,label_type,cones,res_data,box_qty,po_number};
    dataObject[i] = {packer_id, sc_order_no,mill,pu_code,article,shade,rec_plant,fullqty,currentDateTime,pendingqty,label_type,cones,res_data,box_qty,po_number,label_count};
    
}
   }
  } 
  const jsonData = JSON.stringify(dataObject);
  Axios.post(`${process.env.REACT_APP_API_URL}/label/glabel`, {
    body: jsonData,
    mill:mill
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
console.log(response)
    window.alert("Label Generated " + JSON.stringify(response.data));
     window.location.reload(true);
    

}).catch((err)=>{
  console.log(err)
  window.alert(err)
});      
   } catch (error) {
          console.error(error);
          window.alert("Error checking packer ID");
          setButtonDisabled(false);
          setIsLoading(false);
          return;
        }
      }
  };

  
   // Define options for the Select component
   const options = order_id.map((item) => ({
    label: item.sc_order_no,
    value: item.sc_order_no,
}));
const options1_article = article.map((item) => ({
  label: item.material_code,
  value: item.material_code,
}));
const options1 = options1_article.filter((options1_article, index, self) =>
  index === self.findIndex((t) => (
    t.value === options1_article.value && t.label === options1_article.label
  ))
);
// const options2 = shade.map((item) => ({
//   label: item.material_code,
//   value: item.material_code,
// }));
const options_shade = order_id.map((item) => ({
  label: item.shade,
  value: item.shade,
}));
const options2 = options_shade.filter((options_shade, index, self) =>
  index === self.findIndex((t) => (
    t.value === options_shade.value && t.label === options_shade.label
  ))
);
const options3 = depo.map((item) => ({
  label: item.depo_name,
  value: item.depo_name,
}));
// const rows = details.map(async (d, index) => {
 
//   // let orderId1;
//   // if (lastOrderId) {
//   //   const idNumber = parseInt(lastOrderId.slice(2)) ;
//   //   orderId1 = `CL${(idNumber+index+1).toString().padStart(7, '0')}`;
//   // } else {
//   //   orderId1 = `CL${(index + 1).toString().padStart(7, '0')}`;
//   // }
//   const quantity=await calc_quantity(d.ord_qty,d.article)
  
//   return(

    
//                 <tr> 
//                   <input type="hidden" id={'mill'+count} value={d.mill}></input>
//                   <input type="hidden" id={'pu_code'+count} value={d.pu_code}></input>
//                   <input type="hidden" id={'sc_order_no'+count} value={d.sc_order_no}></input>
//                   {/* <td><input type="text" id={'carton_no'+count} value={orderId1}></input></td>  */}
//                   <td><input type="text" id={'po_number'+count} value={d.po_number}></input></td>
//                  {/* <td><input type="hidden" id={'mill'+count} value={d.mill}></input></td>
//                  <td><input type="hidden" id={'pu_code'+count} value={d.pu_code}></input></td> */}
//                  <td><input type="text" id={'article'+count} value={d.article}></input></td>
//                  <td><input type="text" id={'shade'+count} value={d.shade}></input></td>
//                  <td><input type="text" id={'rec_plant'+count} value={d.rec_plant} ></input></td>
             
//                  <td><input type="number" id={'ord_qty'+count} value={qua}></input></td>
//                  <td><input type="number" id={'pending_qty'+count} value={d.pendingqty}></input></td>
//                  {/* <td><input type="text" id={'packer_id'+count}  onLoad={count=count+1}></input></td> */}
//                  <td><input type="text" id={'box_qty'+count}  onLoad={count=count+1}></input></td>
                 
//                </tr>
//  )
             
//  });
const clear_values =() => {
  setArticle('');
  setShade('');
  setDepo('');
}
const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '150px', 
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '150px', 
  }),
};
 
  return(
    <div>
      {isLoggedIn ? ( <>
      <h3 style={{marginLeft:"100px"}} data-cy="order_label">Generate Solid Label</h3>
      {isLoggedIn ? ( <>
        <div style={{ display:"flex", marginLeft:"100px", marginTop: "20px"}}>
      {/* <Select
        options={options}
        placeholder="Select order ID 1"
        value={selectedOrderId1}
        id="orderid1"
        onChange={setSelectedOrderId1}
        isSearchable={true}
      />
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Select
        options={options}
        placeholder="Select order ID 2"
        value={selectedOrderId2}
        onChange={setSelectedOrderId2}
        isSearchable={true}
      /> */}
      {/* </div> */}
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Select
        options={options1}
        placeholder="Select Article"
        value={selectedArticle}
        onChange={setArticle}
        isSearchable={true}
        styles={customStyles}
      />
      </div>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Select
        options={options2}
        placeholder="Select Shade"
        value={selectedShade}
        onChange={setShade}
        isSearchable={true}
        styles={customStyles}
      />
      </div>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Select
        options={options3}
        placeholder="Select Depo"
        value={selectedDepo}
        onChange={setDepo}
        isSearchable={true}
        styles={customStyles}
      />
      </div>
      <div style={{ display:"flex", marginRight:"50px",justifyContent:"flex-end"}}>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Button variant="primary" onClick={getdetails}>Show Details</Button>
      </div>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Button variant="primary" onClick={clear_values}>
        Clear
      </Button>
      </div>
      </div>
      </div>
      <br/>
      {details.length > 0 ? (
   <table className="table container" id="tb1">
   <thead>
    <tr><td><p>Packer ID <span style={{ color: 'red' }}>*</span></p></td><td>
  <input type="text" id={'packer_id'} ></input></td></tr>
     <tr>
        {/* <th scope="col">carton_no</th> */}
        <th scope="col">PO Number</th>
       {/* <th scope="col">mill</th>
       <th scope="col">Production Unit</th> */}
       <th scope="col">Article</th>
       <th scope="col">Shade</th>
       <th scope="col">rec_plant</th>
       <th scope="col">Order quantity</th>
       <th scope="col">Pending quantity</th>
       <th scope="col">Quantity</th>
       {/* <th scope="col">packer id</th> */}
       <th scope="col">Maximum Cartons </th>
        
     </tr>
   </thead>
   <tbody>
            {rows1}
            <tr><td colSpan="8" align="right"> <div>
            <Button variant="primary" onClick={generatelabel} disabled={isButtonDisabled}>Generate Label</Button>
         </div></td></tr>
          
              </tbody>
              
      </table> 
 ) : (
  <p></p>
)}
      <table><tr>

      {labelid_details.map((item,i)=>(
    <td>Label id Generated are :{item.carton_no} || </td>
      ))}

      </tr>
      </table>

      <br /><br />

     
      </>) : (<input type="hidden"></input>)}  
      {isLoading && (
      <div className="loading-overlay">
        <div className="loading-icon"></div>
      </div>
    )}
    </>) : (window.location.href = "#/login")} 
   </div>
   
  );
};





