import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./carousel.css"
import whitethread from '../../assets/whitethread.jpg';
import sustainability from '../../assets/sustainability.jpg'; 
import coats_ceo from '../../assets/coats_ceo.jpg';

function Carousel1() {
  return (
    <Carousel className='Carsoul'>
      <Carousel.Item className='image'>
        <img
          className="d-block w-100"
          src={sustainability}
          height="600px"
          width="400px"
          alt="First slide" 
        /> 
       
      </Carousel.Item>
    
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={whitethread}
          alt="Third slide"
          height="600px"
          width="400px"
        />

      
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={coats_ceo}
          alt="Third slide"
          height="600px"
          width="400px"
        />

      
      </Carousel.Item>

    </Carousel>
  );
}

export default Carousel1;