import React,{ useState,useEffect } from "react";
import { Button, MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';
import  secureLocalStorage  from  "react-secure-storage";
import Axios from "axios";
import moment, { isDate } from 'moment-timezone';
import './muitab.css';
import MUIDataTable from 'mui-datatables'
function Document_Display() {
    const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
    const [docno,setdocno]=useState('');
    const [docno1,setdocno1]=useState('');
    const token = secureLocalStorage.getItem("token");
    const [details,setDetails]=useState([]);
    const [selectedvalue, setselectedvalue] = useState('10');
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const format_date=(utcDate)=>{
        const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
        return istDate;
      }
      var at_bc=0
    const columns = [
        {name:"carton_no",label:"Carton No"},
        {name:"po_number",label:"PO Number"},
        {name:"article",label:"Article"},
        {name:"shade",label:"Shade"},
        {name:"depot",label:"Depot"},
        {name:"fullqty",label:"Quantity"},
        // {name:"packerid",label:"Packer ID"},
        {name:"at_bc",label:"Qty in Pieces"},
        {name:"grosswt",label:"Gross Weight"},
        // {name:"greykg",label:"Net Weight"},
        {name:"cartontype",label:"Carton Type"},
        {name:"carton_date", label: "Carton Date",
    options: {
      customBodyRender: (value) => format_date(value)
    }
    },
    {name:"doc_number",label:"Document Number"},
  ]
  useEffect(() => {
    if (
        selectedvalue !== '10' ||
        offset !== 0 ||
        page !== 1 ||
        pageSize !== 10
      ) {
        search();
      }
},[selectedvalue, offset, page, pageSize]);
    const handlePageChange = (newPage) => {
        const newOffset = newPage * pageSize; // Calculate the new offset
        setPage(newPage + 1);
        setOffset(newOffset);
        
      };
    
      const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(1); 
        setOffset(0); 
      };
      
    const handleInputChange = (event) => {
        setdocno(event.target.value);
      };
      const handleInputChange1 = (event) => {
        setdocno1(event.target.value);
      };
      const search = async () => {
        var document_no = document.getElementById('docid').value;
        var document_no1 = document.getElementById('docid1').value;
        document_no=document_no.trim()
        document_no1=document_no1.trim()
        const numberRegex = /^[0-9]+$/;
        if (!numberRegex.test(document_no) || !numberRegex.test(document_no1)) {
            window.alert("Wrong Document Number");
              return;
        }
        const { data }= await Axios.post(`${process.env.REACT_APP_API_URL}/label/document_select`, {
            document_no1:docno,
            document_no2:docno1,
            page: page, // Pass current page
            pageSize: pageSize
        },{
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'}
          })
          const cwhartktmas= await Axios.post(`${process.env.REACT_APP_API_URL}/cwhartktmas/fetch_details`, {
  
          },{
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'}
            })
        const totalCount = data.totalCount;
        const response = data.result;
        const cw=cwhartktmas.data.result;
        var len;
        console.log("data",data)
        console.log("totalCount",totalCount)
        console.log("response",response.length)
        if(response.length===0)
         window.alert("No Data Found")
        if (page === 1) {
            for(len=0;len<response.length;len++)
            {
             console.log("response[len].cartontype",response[len].cartontype)
             const firstPart = response[len].article.substring(0, 4); // "TLR0"
             const secondPart = response[len].article.substring(4);   
             const filteredData = cw.filter(item => item.at_art === firstPart && item.at_tkt===secondPart);
             at_bc = Array.from(new Set(filteredData.map(detail => detail.at_bc))).join(', ');
          
             if(response[len].cartontype==="Solid")
             {
             console.log("response[len].fullqty",response[len].fullqty)
             response[len].fullqty=response[len].at_cc;
             console.log("response[len].fullqty",response[len].fullqty)
             response[len].at_bc = at_bc* response[len].fullqty;
             }
             if(response[len].cartontype==="Loose")
             {
             const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
             response[len].grosswt=grossWeight
             response[len].at_bc = at_bc* response[len].fullqty;
             }
             }
            setDetails(response);
        }
        else{
            for(len=0;len<response.length;len++)
            {
             console.log("response[len].cartontype",response[len].cartontype)
             const firstPart = response[len].article.substring(0, 4); // "TLR0"
             const secondPart = response[len].article.substring(4);   
             const filteredData = cw.filter(item => item.at_art === firstPart && item.at_tkt===secondPart);
             if (filteredData.length === 0) {
               at_bc =0; 
             } else {
               at_bc = Array.from(new Set(filteredData.map(detail => detail.at_bc))).join(', ');
             }
             if(response[len].cartontype==="Solid")
             {
             console.log("response[len].fullqty",response[len].fullqty)
             response[len].fullqty=response[len].at_cc;
             console.log("response[len].fullqty",response[len].fullqty)
             response[len].at_bc = at_bc*  response[len].fullqty;
             }
             if(response[len].cartontype==="Loose")
             {
             const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
             response[len].grosswt=grossWeight
             response[len].at_bc = at_bc*  response[len].fullqty;
             }
             }
            setDetails((prevDetails) => [...prevDetails, ...response]);
        }
        setTotalRecords(totalCount)
    }
    const clear = () => {
        setdocno('');
        setdocno1('');
        setDetails('')
    }
    const downloadData = async () => {
      if(totalRecords!=0)
      {
      var data='';
      var len=0
        Axios.post(`${process.env.REACT_APP_API_URL}/label/document_select`, {
        document_no1:docno,
        document_no2:docno1,
        download:1
    },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        }).then(async (response) => {
          var len=0
          const cwhartktmas= await Axios.post(`${process.env.REACT_APP_API_URL}/cwhartktmas/fetch_details`, {

          },{
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'}
            })
          const cw=cwhartktmas.data.result;
          for(len=0;len<response.data.result.length;len++)
          {
           console.log("response[len].cartontype",response.data.result[len].cartontype)
           const firstPart = response.data.result[len].article.substring(0, 4); // "TLR0"
           const secondPart = response.data.result[len].article.substring(4);   
           const filteredData = cw.filter(item => item.at_art === firstPart && item.at_tkt===secondPart);
           if (filteredData.length === 0) {
             at_bc =0; 
           } else {
             at_bc = Array.from(new Set(filteredData.map(detail => detail.at_bc))).join(', ');
           }
           if(response.data.result[len].cartontype==="Solid")
           {
           console.log("response[len].fullqty",response.data.result[len].fullqty)
           response.data.result[len].fullqty=response.data.result[len].at_cc;
           console.log("response[len].fullqty",response.data.result[len].fullqty)
           response.data.result[len].at_bc = at_bc*  response.data.result[len].fullqty;
           }
           if(response.data.result[len].cartontype==="Loose")
           {
           const grossWeight =  ((response.data.result[len].grosswt/response.data.result[len].at_cc) * response.data.result[len].fullqty).toFixed(1)
           response.data.result[len].grosswt=grossWeight
           response.data.result[len].at_bc = at_bc*  response.data.result[len].fullqty;
           }
           }
           data = response.data.result.map(row => {
            return {
              
              carton_no: row.carton_no,
              po_number: row.po_number,
              article: row.article,
              shade: row.shade,
              depot: row.depot,
              fullqty: Number(row.fullqty),
              qty_in_pieces:row.at_bc,
              grosswt: row.grosswt,
              cartontype: row.cartontype,
              carton_date: format_date(row.carton_date),
              doc_number: row.doc_number,
            };
          });
          console.log("data",data)
          console.log("data",data.length)
          if(data.length!=0)
          {
          const ws = XLSX.utils.json_to_sheet(data);
         
          
           const wb = XLSX.utils.book_new(); // Create a new workbook
           XLSX.utils.book_append_sheet(wb, ws, 'data'); // Add the worksheet to the workbook
            
           // Write the workbook to a buffer
           const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            
           // Create a Blob from the buffer
           const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            
           // Create an anchor tag for downloading
           const a = document.createElement('a');
           a.href = URL.createObjectURL(blob);
           a.download = 'Document Data.xlsx';
            
           // Trigger the download
           a.style.display = 'none';
           document.body.appendChild(a);
           a.click();
            
           // Clean up
           document.body.removeChild(a);
           URL.revokeObjectURL(a.href);
           
          }
        })
   
      }
    }
    const handlePrintSelectedRows = () => {
        console.log(details[0].doc_number)
        Axios.post(`${process.env.REACT_APP_API_URL}/label/document_select`, {
            document_no1:docno,
            document_no2:docno1,
            download:1
        },{
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'}
            }).then(async (response) => {
                console.log(response.data.result.length)
                console.log(details)
                var len=0
                const cwhartktmas= await Axios.post(`${process.env.REACT_APP_API_URL}/cwhartktmas/fetch_details`, {
  
                },{
                  headers: {
                    'x-access-token': token,
                    'Content-Type': 'application/json'}
                  })
                const cw=cwhartktmas.data.result;
                for(len=0;len<response.data.result.length;len++)
                {
                 console.log("response[len].cartontype",response.data.result[len].cartontype)
                 const firstPart = response.data.result[len].article.substring(0, 4); // "TLR0"
                 const secondPart = response.data.result[len].article.substring(4);   
                 const filteredData = cw.filter(item => item.at_art === firstPart && item.at_tkt===secondPart);
                 if (filteredData.length === 0) {
                   at_bc =0; 
                 } else {
                   at_bc = Array.from(new Set(filteredData.map(detail => detail.at_bc))).join(', ');
                 }
                 if(response.data.result[len].cartontype==="Solid")
                 {
                 console.log("response[len].fullqty",response.data.result[len].fullqty)
                 response.data.result[len].fullqty=response.data.result[len].at_cc;
                 console.log("response[len].fullqty",response.data.result[len].fullqty)
                 response.data.result[len].at_bc = at_bc*  response.data.result[len].fullqty;
                 }
                 if(response.data.result[len].cartontype==="Loose")
                 {
                 const grossWeight =  ((response.data.result[len].grosswt/response.data.result[len].at_cc) * response.data.result[len].fullqty).toFixed(1)
                 response.data.result[len].grosswt=grossWeight
                 response.data.result[len].at_bc = at_bc*  response.data.result[len].fullqty;
                 }
                 }
        const selectedColumns = columns.map(column => column.name); // Get the names of the selected columns
        
        // Map each row index to the selected row data
        const selectedRowsData = response.data.result.map(detail =>
            selectedColumns.reduce((acc, columnName) => {
                acc[columnName] = detail[columnName];
                return acc;
            }, {})
        ); 
    
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>table { border: 1px solid black; border-collapse: collapse; } th, td { border: 1px solid black; padding: 5px; } .page-break { page-break-before: always; }</style>');
        printWindow.document.write('</head><body>');
    
        const rowsPerPage = 15;
    
        // Function to write page content
        function writePageContent(pageData, pageIndex) {
            printWindow.document.write('<table border="1">');
            printWindow.document.write('<thead>');
            printWindow.document.write('<tr><td colspan="4">Document NO:' + details[0].doc_number + '<br> Date:' + new Date().toLocaleDateString() + '<br></td>');
            printWindow.document.write('<td colspan="9">Sirius Zip Fasteners Pvt Ltd.,C-22 , SIPCOT Industrial Complex, Manamadurai, Sivagangai District, TamilNadu - 630606<br>GSTIN:33AABCS6968D1ZB<br>GSTIN/UIN:33AABCS6968D1ZB<br>State Name:Tamil Nadu, Code :33<br>CIN: U28999KA1988PTC009372<br>E-Mail: logistics@siriuszip.com</td></tr>');
            printWindow.document.write('<tr>');
            printWindow.document.write('<th>S.No.</th>');
    
            // Write headers
            selectedColumns.forEach(columnName => {
                const column = columns.find(col => col.name === columnName);
                printWindow.document.write('<th>' + column.label + '</th>');
            });
    
            printWindow.document.write('</tr></thead><tbody>');
    
            // Write data rows
            let sno = pageIndex * rowsPerPage + 1;
            let pageTotalQtyBoxes = 0;
            let pageTotalQtyPieces = 0;
            let pageTotalgrosswt = 0;
            
            pageData.forEach((row) => {
                printWindow.document.write('<tr>');
                printWindow.document.write('<td>' + sno + '</td>');
                sno++;
                selectedColumns.forEach((columnName) => {
                    console.log(columnName)
                    console.log(row[columnName])
                    if (columnName === "carton_date") {
                        printWindow.document.write('<td>' + format_date(row[columnName]) + '</td>');
                    } else {
                        printWindow.document.write('<td>' + row[columnName] + '</td>');
                    }
                    if (columnName === "fullqty") {
                        pageTotalQtyBoxes += Number(row[columnName]);
                      }
                      if (columnName === "at_bc") {
                        pageTotalQtyPieces += Number(row[columnName]);
                      }
                      if (columnName === "grosswt") {
                        pageTotalgrosswt += Number(row[columnName]);
                      }
                });
                printWindow.document.write('</tr>');
            });
    
            printWindow.document.write('<tr><td colspan="5"></td>');
            printWindow.document.write('<td>Total</td>');
            printWindow.document.write('<td>' + pageTotalQtyBoxes + '</td>');
            printWindow.document.write('<td>' + pageTotalQtyPieces + '</td><td colspan="4"></td></tr>');
            printWindow.document.write('<tr><td colspan="7"></td><td>GROSS WT</td><td>' + pageTotalgrosswt + '</td></tr>');
            printWindow.document.write('</tbody></table>');
        }
    
        // Split data into pages
        let pageIndex = 0;
        for (let i = 0; i < selectedRowsData.length; i += rowsPerPage) {
            if (pageIndex > 0) {
                printWindow.document.write('<div class="page-break"></div>');
            }
            const pageData = selectedRowsData.slice(i, i + rowsPerPage);
            writePageContent(pageData, pageIndex);
            pageIndex++;
        }
    
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    });
    };
    
    return(
        <div>
          {isLoggedIn ? (
            <>
              <br/>
              <h3 style={{textAlign: 'center'}} data-cy="order_label">Document</h3>
              <div className="form-group">
                <br/>
                <label className="label1">From Document No:</label>
                <input type="text" id="docid"  value={docno} onChange={handleInputChange} className="input" />
                <label className="label1">To Document No:</label>
                <input type="text" id="docid1"  value={docno1} onChange={handleInputChange1} className="input" />
                <div align="left">
                  <button type="submit" className="btn btn-primary" onClick={search}>
                    Display
                  </button>
                  <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary" onClick={clear}>Clear</button>
                </div>
                <br/><br/>
              </div>
              {details.length > 0 && (
                <div>
                <div style={{width:'95%',display:'flex', justifyContent:'right'}}>    
 
                <Button variant="contained" color="primary"  style={{marginRight: '10px'}}
                           onClick={downloadData}
                          //  onClick={() => fileInputRef.current.click()}
                           >
                           Download
                  </Button> 
                <Button variant="contained" color="primary" 
                           onClick={handlePrintSelectedRows}
                          //  onClick={() => fileInputRef.current.click()}
                           >
                           Print
                  </Button>
                  </div>
              <div style={{ marginLeft: "55px",display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth:'93%'}}>     
        <MUIDataTable
              // data={details}
              data={details}
              columns={columns}
              options={{
                filterType: 'checkbox',
                selectableRows: 'none',
                download:false,
                print:false,
                viewColumns:false,
                search:false,
                selectableRows: 'none',
                sort: false,
                filter: false,
                page: page - 1,
                rowsPerPage: pageSize,
                count: totalRecords,
               onChangePage:  handlePageChange,
               onChangeRowsPerPage:handlePageSizeChange,
                downloadOptions : {
                  filename: 'CartonStatus.csv',
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                  }
                }
 
              }}
              className="custom-table" 
            />
        
            </div></div>
            )}
            </>
          ) : (
            window.location.href = "#/login"
          )}
        </div>
      );
}
export default Document_Display    