import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import "./Navbarstyle.css";
import  secureLocalStorage  from  "react-secure-storage";


function Navbar1() {
  const usertype = secureLocalStorage.getItem("usertype");
  const activeUser = secureLocalStorage.getItem("activeUser");
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");  
  const role= secureLocalStorage.getItem("role"); 
  const mill = secureLocalStorage.getItem("mill"); 
  console.log("role",role)
  let admin = false;

    if (usertype == "Admin") {
        admin = true;
        
    }
    else{
      console.log("not admin")
    }
  
  
  return (
    <Navbar collapseOnSelect expand="lg" bg="#FFFFFF" variant="#6f42c1" sticky="top" className='Nav'>
      <Container>
        <Navbar.Brand><Link to="/"><img src="https://coats.com/wp-content/uploads/2023/08/coats-logo.svg" width="60px" height="60px" /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link data-testid="l1"><Link to="/" className='nav_style'activeClassname='active'>Home</Link></Nav.Link>
           
            {isLoggedIn ? ( <>
            <NavDropdown title="Orders" id="collasible-nav-dropdown">
            {admin ? (
              <NavDropdown.Item style={{backgroundColor:"lightblue"}}>
                
                <Link to="/orders"  className='nav_style'activeClassname='active'>Place Order</Link>
                
                </NavDropdown.Item>):(<input type="hidden"></input>)}
                {role==="Admin" || role==="Vendor"?(
                <NavDropdown.Item style={{backgroundColor:"lightblue"}}>
                
                <Link to="/open_order"  className='nav_style'activeClassname='active'>Open Order</Link>
                
                </NavDropdown.Item>
                ):null}
               
                {admin ? (
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Upload_file_status" className='nav_style'activeClassname='active'>
                Upload file status</Link>
              </NavDropdown.Item>):(<input type="hidden"></input>)}
              {/* {admin ? (
              <NavDropdown.Item style={{backgroundColor:"lightblue"}}><Link to="/track_status" className='nav_style'activeClassname='active'>Track status</Link></NavDropdown.Item>):(<input type="hidden"></input>)} */}

              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}><Link to="/Filter_component" className='nav_style'activeClassname='active'>Update Status</Link></NavDropdown.Item>
              {admin ? (
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}><Link to="/multiple_status_up" className='nav_style'activeClassname='active'>Multiple Status Update</Link></NavDropdown.Item>):(<input type="hidden"></input>)}
            </NavDropdown> </> ) : (<input type="hidden"></input>)}
            {admin ?  ( <>
            <NavDropdown title="Vendors" id="collasible-nav-dropdown">
            {admin ? (
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/vendors"  className='nav_style'activeClassname='active'>Add Vendor</Link>
                
                </NavDropdown.Item>):(<input type="hidden"></input>)}
                {admin ? (
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Production" className='nav_style'activeClassname='active'>
                Add Production</Link>
              </NavDropdown.Item>):(<input type="hidden"></input>)}
             
            </NavDropdown> </> ) 
            : (<input type="hidden"></input>)}

            {/* {admin ? (  */}
            {isLoggedIn && role==="Admin" || role==="Vendor"? ( 
            <>
            <NavDropdown title="Master Data" id="collasible-nav-dropdown">
            {admin ? (
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Master"  className='nav_style'activeClassname='active'>Material Data</Link>
                
                </NavDropdown.Item>):(<input type="hidden"></input>)}
                {admin ? (
              <NavDropdown.Item style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Duedate" className='nav_style'activeClassname='active'>
                Due Date</Link>
              </NavDropdown.Item>):(<input type="hidden"></input>)}
             {role==="Admin" || role==="Vendor"?(
             <NavDropdown.Item style={{backgroundColor:"lightblue"}}>
                
             <Link to="/Cwhartktmas"  className='nav_style'activeClassname='active'>Cwhartktmas</Link>
             
             </NavDropdown.Item>
             ):null}
             
                {admin ? (
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Depo" className='nav_style'activeClassname='active'>
                Depo</Link>
              </NavDropdown.Item>):(<input type="hidden"></input>)}
            </NavDropdown> </> ): (<input type="hidden"></input>)}
             {/*  ) : (<input type="hidden"></input>)}*/}

            {/* Label */}
            {isLoggedIn && role==="Admin" || role==="Vendor"? ( <>
            <NavDropdown title="Label" id="collasible-nav-dropdown">
            <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Carton_cancel" className='nav_style'activeClassname='active'>
                Carton Cancel</Link>
              </NavDropdown.Item>
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Carton_status" className='nav_style'activeClassname='active'>
                Carton Status</Link>
              </NavDropdown.Item>
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Carton_reprint" className='nav_style'activeClassname='active'>
                Carton Reprint</Link>
              </NavDropdown.Item>
              <NavDropdown.Item style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Loose_Glabel"  className='nav_style'activeClassname='active'>Generate Loose Label</Link>
                
                </NavDropdown.Item>
                <NavDropdown.Item style={{backgroundColor:"lightblue"}}>
                
                <Link  to="/Solid_Glabel" className='nav_style'activeClassname='active'>Generate Solid Label</Link>
                
                </NavDropdown.Item>
                <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Solid_Dlabel"  className='nav_style'activeClassname='active'>Download Label</Link>
                
                </NavDropdown.Item>
              {/* <NavDropdown id="collasible-nav-dropdown" title="Loose Label">
                 */}
               
                {/* <NavDropdown.Item href="#action/3.1" style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Loose_Dlabel" className='nav_style'activeClassname='active'>Download Label</Link>
                
                </NavDropdown.Item> */}
              {/* </NavDropdown>

              <NavDropdown id="collasible-nav-dropdown" title="Solid Label">
                
              
                
              </NavDropdown> */}
            </NavDropdown> </> ) : (<input type="hidden"></input>)}
            {isLoggedIn && activeUser==="sirius_admin" || activeUser==="SIRIUS" ? ( <>
            <NavDropdown title="Document" id="collasible-nav-dropdown">
            <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Despatch" className='nav_style'activeClassname='active'>
                Despatch Planning</Link>
              </NavDropdown.Item>
              <NavDropdown.Item  style={{backgroundColor:"lightblue"}}>
                
                <Link to="/Document_Display" className='nav_style'activeClassname='active'>
                Document Display</Link>
              </NavDropdown.Item>
            </NavDropdown> </> ) : (<input type="hidden"></input>)}
            {admin ? (
              <Nav.Link data-testid="l1"><Link to="/log_report" className='nav_style'activeClassname='active'>Log Report</Link></Nav.Link>):(<input type="hidden"></input>)}
            {admin ? (
              <Nav.Link data-testid="l1"><Link to="/Report_download" className='nav_style'activeClassname='active'>Report</Link></Nav.Link>):(<input type="hidden"></input>)}
          </Nav>
          
          <Nav>
            {/* <Nav.Link><Link to="#" className='nav_style'  activeClassname='active'>{ activeUser }</Link></Nav.Link> */}
            {isLoggedIn ? (
            <><Nav.Link><Link to="/profile" className='nav_style'activeClassname='active'>{ activeUser }</Link></Nav.Link>
            <input className='button' style={{backgroundColor:"#2656D8"}} type="button" data-testid="button" value="Log out" onClick={() => { 
              secureLocalStorage.removeItem("activeUser"); 
              secureLocalStorage.removeItem("isLoggedIn"); 
              secureLocalStorage.removeItem("usertype");
              secureLocalStorage.removeItem("mill");
              secureLocalStorage.removeItem("token");
              secureLocalStorage.removeItem("role");
              window.location.href="#/login/"
              window.location.reload(true);
            } } /></>
            ): (<Nav.Link eventKey={2}><Link to="/login" className='nav_style'activeClassname='active'>Login</Link></Nav.Link>)}
           
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar1;