import { useState,useEffect } from "react"
import React from 'react'
import  secureLocalStorage  from  "react-secure-storage";
import Axios from "axios";
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables'
const Cwhartktmas= () => {
    const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
    const token = secureLocalStorage.getItem("token");
    const [status, setStatus] = useState([]);
    const mill = secureLocalStorage.getItem("mill");
    useEffect(async () => {
      if (isLoggedIn){
      const response= await Axios.post(`${process.env.REACT_APP_API_URL}/cwhartktmas/fetch_details`, {
  
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        })
        setStatus(response.data.result)
      }
      }, []);
      const columns=[
       
        {name:"at_art", label: "Article(at_art)"},
        {name:"at_tkt", label: "Ticket(at_tkt)"},
           {name:"at_grwt", label: "Gross Weight(at_grwt)"},
           {name:"at_gkg", label: "Net Weight(at_gkg)"},
        {name:"at_cc", label: "Cone Size(at_cc)"},
        {name:"at_bc", label: "Box Size(at_bc)"},
        {name:"at_asu",label:"at_asu"},
        {name:"at_wtsu",label:"at_wtsu"}
      ]
      return (
        <div>
   {isLoggedIn ? ( <>
          <h3 style={{marginLeft:"100px"}} data-cy="order_label">Cwhartktmas</h3>
           <br/><br/>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <MUIDataTable 
    data={status}
    columns={columns}

    options={mill==="COATS"?{ filterType: 'checkbox',selectableRows: 'none',sort: false,filter: false ,
  }:{filterType: 'checkbox',selectableRows: 'none',sort: false,filter: false ,
  download:false,print:false,}}
    
    className="custom-table1" 
    />
    </div>
    
          </>) : (window.location.href = "#/login")}
        </div>
      )
}
export default Cwhartktmas