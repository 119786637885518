import React from 'react'
import moment from 'moment-timezone';
import { useState,useEffect, useRef } from 'react';
import MUIDataTable from 'mui-datatables'
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import './muitab.css';
import  secureLocalStorage  from  "react-secure-storage";
import {Link} from 'react-router-dom';
import Axios from "axios";
import Filter_component from '../components/filter/filter_component';
import Status_change from './status_change';
import './popup.css';
function Update_status()  {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const [order_id,setorderids]=useState([]);
  const [details,setDetails]=useState([]);
  const [oid, setOid] = useState(null);
  const [selectedvalue,setselectedvalue]=useState('10');
  // const[offset,setoffset]=useState(0);
  const [offset, setOffset] = useState(0);
  const token = secureLocalStorage.getItem("token");
  const role=secureLocalStorage.getItem("role");
  console.log("role fe",role)
  const [showUpStatusPopup, setShowUpStatusPopup] = useState(false);
  const popupRef = useRef(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedPo, setSelectedPo] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const format_date=(utcDate)=>{
    const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
    return istDate;
  }
  const handleEditClick = (orderId,po) => {
    console.log("ord id",orderId," po",po)
    setSelectedOrderId(orderId);
    setSelectedPo(po)
    setShowUpStatusPopup(true);
  };

  const handlePopupClose = () => {
    setSelectedOrderId(null);
    setSelectedPo(null)
    setShowUpStatusPopup(false);
  };
  // const handleStatusUpdate = (orderId, newStatus) => {
  //   console.log(orderId)
  //   console.log(newStatus)
  //   // const updatedOrders = order_id.map(order_id => {
  //   //   if (order_id.sc_order_no === orderId) {
  //   //     return { ...order_id, statuscode: newStatus };
  //   //   }
  //   //   return order_id;
  //   // });
  //   if (isFilterApplied) {
  //   const updatedOrders = details.filter(order => order.sc_order_no !== orderId);
  //   setorderids(updatedOrders);
  //   }
  //   else{
  //     const updatedOrders = details.map(order => {
  //       if (order.sc_order_no === orderId) {
  //         return { ...order, statuscode: newStatus };
  //       }
  //       return order;
  //     });
  
  //     // Update the state with the updated orders
  //     setorderids(updatedOrders);
  //   }
  //   // console.log(details)
  //   // setorderids(updatedOrders);
  //   // handleFilteredDetails(details);
  //   // setorderids(details);
  // };
  const handleStatusUpdate = (orderId, newStatus) => {
    console.log("orderId",orderId)
  if (filteredData.length>0) {
    // When a filter is applied, we need to update the filteredData state
    const updatedFilteredData = filteredData.filter(order => order.sc_order_no !== orderId);
    console.log("updatedFilteredData",updatedFilteredData)
    setFilteredData(updatedFilteredData);
    setTotalRecords(updatedFilteredData.length)
  } else {
    // When no filter is applied, update the details state
    const updatedDetails = details.map(order => {
      if (order.sc_order_no === orderId) {
        return { ...order, statuscode: newStatus };
      }
      console.log("order",order)
      return order;
    });

    setDetails(updatedDetails);
    setTotalRecords(updatedDetails.length)
  }
};
  const columns = [
    {
      name: "Edit",
      options: {
        filter: false,
        label:"Edit",
        customBodyRender: (value, tableMeta, updateValue) => {
         var id;
         var po_num;
          if(filteredData.length===0)
          {
            id = details[tableMeta.rowIndex].sc_order_no;
             po_num=details[tableMeta.rowIndex].po_number;
            
          }
          else{
            id = filteredData[tableMeta.rowIndex].sc_order_no;
            po_num=filteredData[tableMeta.rowIndex].po_number;
          }
          // const id = details[tableMeta.rowIndex].sc_order_no;
          // const po_num=details[tableMeta.rowIndex].po_number;
          // console.log("front end",po_num)
          // console.log(id)
          return (
            // <Link to={`/status_change?id=${id}`}>
            //    <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15"/ > 
             
            // </Link>
            <Link to="#" onClick={() => handleEditClick(id,po_num)}>
            <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15" alt="Edit" />
          </Link>
          );
        }
      }
    },
    {name:"po_number",label:"PO Number"},
{name:"material", label: "Art",
options: {
    customBodyRender: (value) =>  value.substr(0, 4) 
  }
},
{name:"material", label: "Material"},
{name:"sap_order_date", label: "SAP Order Date",
options: {
  customBodyRender: (value) => format_date(value)
}
},
{name:"ord_qty", label: "Order Quantity"},
{name:"pendingqty", label: "Pending Quantity"},
{
  name:"due_date", 
  label: "Due Date",
  options: {
    customBodyRender: (value) => format_date(value)
  }
},
{name:"statuscode", label: "Status"},
{name:"uname", label: "User Name"},
{name:"rec_plant", label: "Rec_plant"}
  ];
  const handleFilteredDetails = (details) => {
    // setorderids(details);
    // setIsFilterApplied(true);
    setFilteredData(details);
    console.log(details.length)
    if(details.length===0)
    {
      setIsFilterApplied(false)
// window.alert("No data for the applied filter")
setDetails([])
setTotalRecords(details.length)
return
    }
    else
    {
    setTotalRecords(details.length)
    setIsFilterApplied(true)
    }
  };


   function handleChange(event){
    setselectedvalue(event.target.value);
    setOffset(0);

   }
   
const getdetails = (selectedOption) => {
  setOid(selectedOption.value);
  Axios.post(`${process.env.REACT_APP_API_URL}/order_status1`,{

      id: selectedOption.value,
  }).then((response) => {
     
      setDetails(response.data)
  });
}

  const mill = secureLocalStorage.getItem("mill");


useEffect(() => {
  if (isLoggedIn){
    const fetchData = async () => {
      try {
        console.log("role in api",role)
  if (mill == "COATS") {
    const { data } = await  Axios.post(`${process.env.REACT_APP_API_URL}/order/update_status`,{
      value1: Number(selectedvalue),
      // offset: offset,
      page: page, // Pass current page
      pageSize: pageSize, 
 },{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  });
  const totalRecords = data.totalCount;
  const response = data.result;
  // console.log("totalRecords",totalRecords)
  // console.log("response",response)

      setTotalRecords(totalRecords)
        const totalPages = Math.ceil(totalRecords / pageSize);
        console.log("total pages",totalPages)
        setTotalPages(totalPages);
  const uniqueRows = response.filter((newRow) => {
    return !details.some((existingRow) => existingRow.sc_order_no === newRow.sc_order_no);
  });

  setDetails((prevDetails) => [...prevDetails, ...uniqueRows]);
  // console.log(response)
}
else{
  const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/order/update_status_vendor`,{
    value1: Number(selectedvalue),
    // offset: offset,
    page: page, // Pass current page
    pageSize: pageSize, 
  mill:mill,
  role:role,
 },{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  });
  const totalRecords = data.totalCount;
  const response = data.result;
  console.log("totalRecords",totalRecords)
  console.log("response",response)

      setTotalRecords(totalRecords)
        const totalPages = Math.ceil(totalRecords / pageSize);
        console.log("total pages",totalPages)
        setTotalPages(totalPages);
  const uniqueRows = response.filter((newRow) => {
    return !details.some((existingRow) => existingRow.sc_order_no === newRow.sc_order_no);
  });

  setDetails((prevDetails) => [...prevDetails, ...uniqueRows]);
  console.log(response) 
}
} catch (error) {
  console.error("Error fetching data:", error);
}
};
// }, []);
fetchData();
}
}, [selectedvalue, offset, page, pageSize, token, isLoggedIn]);

const handlePageChange = (newPage) => {
  const newOffset = newPage * pageSize; // Calculate the new offset
  setPage(newPage + 1);
  setOffset(newOffset);
};

const handlePageSizeChange = (newPageSize) => {
  setPageSize(newPageSize);
  setPage(1); // Reset the page to 1 when page size changes
  setOffset(0); // Reset the offset to 0 when page size changes
};
 
   // Define options for the Select component
 const options = order_id.map((item) => ({
  label: item.sc_order_no,
  value: item.sc_order_no,
}));
const downloadData = async () => {
  var data='';
  if(isFilterApplied)
  {
    console.log("filteredData.data",filteredData.data)
    console.log("filteredData",filteredData)
    //  data = filteredData;
      data = filteredData.map(row => {
      return {
        
        po_number: row.po_number,
        art:row.material.substr(0,4),
        material: row.material,
        sap_order_date: format_date(row.sap_order_date),
        ord_qty: Number(row.ord_qty),
        pendingqty: Number(row.pendingqty),
        due_date: format_date(row.due_date),
        statuscode: row.statuscode,
        uname: row.uname,
        rec_plant: row.rec_plant,
        
      };
    });
  }
  else
  {
     const totalRecordsResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/order/get_total_rows`, {  
    mill:mill,
    role:role
  }, {
headers: {
  'x-access-token': token,
  'Content-Type': 'application/json'
}
});
 console.log("totalRecordsResponse",totalRecordsResponse)
  data = totalRecordsResponse.data;
  }

 console.log("data",data)
 console.log("data",data.length)
 if(data.length!=0)
 {
 const ws = XLSX.utils.json_to_sheet(data);

 
  const wb = XLSX.utils.book_new(); // Create a new workbook
  XLSX.utils.book_append_sheet(wb, ws, 'data'); // Add the worksheet to the workbook
   
  // Write the workbook to a buffer
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   
  // Create a Blob from the buffer
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
   
  // Create an anchor tag for downloading
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = 'Update Status Data.xlsx';
   
  // Trigger the download
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
   
  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(a.href);
  
 }

}

  return (
    
    <div>
       
       <h3 style={{marginLeft:"55px"}} data-cy="order_label">Update Status</h3>
    {isLoggedIn ? ( <>
    <Filter_component onFilter={handleFilteredDetails}/><br/>
    <div style={{width:'95%',display:'flex', justifyContent:'right'}}>       
        <Button variant="contained" color="primary" 
               onClick={downloadData}
              //  onClick={() => fileInputRef.current.click()}
               >
               Download
      </Button>
      </div><br/>
      <div style={{ marginLeft: "55px",display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth:'93%'}}>
          <div style={{ width: '100%' }}>
      {/* {console.log("filteredData",filteredData)} */}
    <MUIDataTable 
    // data={filteredData.length > 0 ? filteredData : details}
    data={isFilterApplied ? filteredData : details}
    columns={columns}
    options={{
      // filterType: 'checkbox',
      download:false,
      print:false,
      selectableRows: 'none',
      sort: false,
      filter: false,
      viewColumns:false,
      search:false,
      page: page - 1,
      rowsPerPage: pageSize,
      rowsPerPageOptions: [10, 20, 100,300,500],
      count: totalRecords,
      // onChangePage: handlePageChange,
      // onChangeRowsPerPage: handlePageSizeChange,
      onChangePage: filteredData.length === 0 ? handlePageChange : undefined,
onChangeRowsPerPage: filteredData.length === 0 ? handlePageSizeChange : undefined,
downloadOptions : {
  filename: 'OrderDetails.csv',
  filterOptions: {
    useDisplayedColumnsOnly: true,
    useDisplayedRowsOnly: true
  }
}
    }}
    className="custom-table" 
    /> 
    </div>
    </div>
   
        {showUpStatusPopup && (
        <div className="popup">
          <Status_change po={selectedPo} orderId={selectedOrderId} onClose={handlePopupClose} onStatusUpdate={handleStatusUpdate} />
        
        </div>
      )}
      
    </>) : (window.location.href = "#/login")}
    </div>
  )
}
export default Update_status