import React,{ useState,useEffect } from "react";
import moment, { isDate } from 'moment-timezone';
import MUIDataTable from 'mui-datatables'
import  secureLocalStorage  from  "react-secure-storage";
import Filter_component from '../components/filter/filter_component';
import Axios from "axios";
import './muitab.css';

function Carton_cancel() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
    const [cartonno,setCartonno]=useState('');
    const [details,setDetails]=useState([]);
    const token = secureLocalStorage.getItem("token");
    var article=''
    var sc_order_no=''
    var carton_no=''
    var carton_type=''
    var fullqty=''
    var po_num=''
    var label_count=''
    const handleInputChange = (event) => {
        setCartonno(event.target.value);
      };
    const search = () => {
        const carton_no = document.getElementById('ordid').value;
        // const regexPattern = /^CL\d{6}$/;
        const regexPattern = /^[0-9a-zA-Z]{8}$/;
        if(!regexPattern.test(String(carton_no)))
        {
          window.alert("Wrong Carton Number");
          return;
        }
        Axios.post(`${process.env.REACT_APP_API_URL}/label/carton_select`, {
          carton_no:carton_no,
        },{
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'}
          }).then((response) => {
            console.log(response)
            // console.log(response.data[0].is_cancelled)
            if (response.data.length > 0) {
              const isDispatched = response.data.some(item => item.is_despatched === '1');
              
              if (isDispatched) {
                window.alert("Carton already dispatched");
                setDetails([]);
                return;
              }
            }
            if(response.data.length == 0)
            {
              window.alert("No such Carton Number");
              return;
            }
            else if(response.data[0].is_cancelled=="false")
            setDetails(response.data)
            else
            setDetails("No data")
        });
    }
    const cancel = () => {
       
        details.map((detail) => (
          carton_no=detail.carton_no,
          article=detail.article,
          sc_order_no=detail.sc_order_no,
          carton_type=detail.cartontype,
          fullqty=detail.fullqty,
          po_num=detail.po_number
         
            ))
        Axios.post(`${process.env.REACT_APP_API_URL}/label/carton_cancel`, {
        details:details,
        },{
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'}
          }).then((response) => {
          window.alert("Carton Number "+carton_no+" has been cancelled")
          window.location.reload(true);
        });
    }
    const clear = () => {
        setCartonno('');
    }
    return(
     
      <div>
         {isLoggedIn ? ( <>
        <br/><h3 style={{textAlign: 'center'}} data-cy="order_label">Carton Cancel</h3>
        <div className="form-group">
          <br/>
        <label className="label1">Carton No:</label>
        <input type="text" id="ordid"  value={cartonno} onChange={handleInputChange} className="input" />
        <div align="left">
        <button type="submit" className="btn btn-primary" onClick={search}>
          Display
        </button>
        <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary" onClick={clear}>Clear</button>
       </div><br/><br/>
       {details === "No data" && <p>Label has already been Cancelled</p>}
       </div><br/><br/>
       {details.length > 0  ?  details !== "No data" && (
            <table className="table container" id="tb1">
            <thead>
              <tr>
                 <th scope="col">Carton_no</th>
                 <th scope="col">PO Number</th>
                {/* <th scope="col">mill</th>
                <th scope="col">Production Unit</th> */}
                <th scope="col">Article</th>
                <th scope="col">Shade</th>
                <th scope="col">Depo</th>
                {/* <th scope="col">rec_plant</th> */}
                <th scope="col">Full qty</th>
                {/* <th scope="col">Pending Quantity</th> */}
                <th scope="col">PackerID</th>
                <th scope="col">Status </th>
              </tr>
            </thead>
            <tbody>
                
            {details.map((detail, index) => (
            <><tr><td>{detail.carton_no}</td>
            <td>{detail.po_number}</td>
            <td>{detail.article}</td>
            <td>{detail.shade}</td>
            <td>{detail.depot}</td>
            <td>{detail.cartontype==='Loose'?detail.fullqty:detail.at_cc}</td>
            <td>{detail.packerid}</td>
            <td>{detail.statuscode}</td> </tr>
            
            </>
            
          ))}
        <tr><td colspan="8" align="right"><inuot  className="btn btn-primary" type="button" onClick={cancel}>Cancel</inuot></td></tr> 
                       </tbody>
                       
               </table> 
          ) : (
           <p></p>
         )}
          </>) : (window.location.href = "#/login")} 
    </div>
   
    );
}
export default Carton_cancel    