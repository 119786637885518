import { useState,useEffect } from "react"
import React from 'react'
import  secureLocalStorage  from  "react-secure-storage";
import Axios from "axios";
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables'
const Upload_file_status = () => {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const token = secureLocalStorage.getItem("token");
  const [status, setStatus] = useState([]);
  useEffect(() => {
    if (isLoggedIn){
    Axios.post(`${process.env.REACT_APP_API_URL}/upload/ord_status`,{
    
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}}).then((response) => {
     setStatus(response.data)
    });
  }
  }, []);

const format_date=(utcDate)=>{
  const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
  return istDate;
}
const columns=[
  {
    name: "View",
    options: {
      filter: false,
      label:"View",
      customBodyRender: (value, tableMeta, updateValue) => {
        const view = status[tableMeta.rowIndex].view;
      const file_name=status[tableMeta.rowIndex].file_name;
        return (
        view.length>0?<td><img src="https://th.bing.com/th?id=OIP._RTO9yp1xH5aQA0vS7fpHAHaHW&w=250&h=249&c=8&rs=1&qlt=90&o=6&dpr=1.3&pid=3.1&rm=2" width='15' height='15' onClick={()=>{alert("File Name : "+file_name+"\nError Description\n"+view);}}></img></td>:<td></td>
                      
        );
      }
    }
  },
     {name:"vendor_name", label: "Vendor Name"},
     {
      name:"uploaded_date", 
      label: "Uploaded Date",
      options: {
        customBodyRender: (value) => format_date(value)
      }
    },
     {name:"no_of_errors", label: "Error Count"},
     {name:"status", label: "Status"},
  {name:"file_name", label: "File Name"}
]
  return (
    <div>
      <br/> <h3 style={{marginLeft:"100px"}}data-cy="order_label">Upload File Status</h3>
       {isLoggedIn ? ( <><br/><br/>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
<MUIDataTable 
data={status}
columns={columns}
options={{ filterType: 'checkbox',selectableRows: 'none',sort: false,filter: false  }}
className="custom-table1" 
/>
</div>

      </>) : (window.location.href = "#/login")}
    </div>
  )
}

export default Upload_file_status