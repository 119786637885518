import React, { useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import  secureLocalStorage  from  "react-secure-storage";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Duedate() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const location = useLocation();
  const token = secureLocalStorage.getItem("token");
  const id = new URLSearchParams(location.search).get('id');
  const [details, setDetails] = useState([])
  const columns = [
    {
      name: "edit",
      options: {
        filter: false,
        label:"Edit",
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = details[tableMeta.rowIndex].id;
          return (
            <Link to={`/Duedate_data?id=${id}`}>
              <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15"/ >
            </Link>
          );
        }
      }
    },
    {name:"prod_unit",label:"Prod Unit"},
    {name:"material",label:"Material"},
    {name:"days" ,label:"Days"},
  ];

  useEffect(() => {
       if (isLoggedIn){
    axios.post(`${process.env.REACT_APP_API_URL}/due-date/show_duedate`,{

    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }
    ).then((response) => {
      setDetails(response.data)
   
    })
  }
  }, [])

  return (
    <div>
    {isLoggedIn ? ( <>
    <div style={{marginLeft: '50px' }}>
    <Link to="/Duedate_data">
    <div className='movv'>
            <button type="submit" className="btn btn-primary" >
            New
          </button>
          </div>
        </Link>
        </div>
       
        <br/>
    <div className='container'>
      <MUIDataTable title={"DUE DATE"}
        data={details}
        columns={columns}
        options={{ filterType: 'checkbox',selectableRows: 'none',sort: false }}
      />
    </div>
    </>) : (window.location.href = "#/login")}  
    </div>
  )
}