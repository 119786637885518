import React, { useState, useEffect } from "react";
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import secureLocalStorage from "react-secure-storage";
import Filter_component from '../components/filter/filter_component';
import Axios from "axios";
import './muitab.css';
function Open_order() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const token = secureLocalStorage.getItem("token");
  const [orderno, setOrderno] = useState('');
  const [details, setDetails] = useState({});
  const [numberOfProperties, setNumberOfProperties] = useState(0);
  const [detailsArray, setDetailsArray] = useState([]);
  const handleInputChange = (event) => {
    setOrderno(event.target.value);
  };
  const clear = () => {
    setOrderno('');
  }
  const search = () => {
    const order_no = document.getElementById('ordid').value;
Axios.post(`${process.env.REACT_APP_API_URL}/order/open_order_calc`, 
{ order_no: order_no }, {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      console.log(response.data);
      console.info("Open order calculation")
      console.error("Open order calculation")
      console.warn("Open order calculation")
      if(response.data.length == 0)
      {
        window.alert("No such PO with Closed Status");
        return;
      }
    else if(response.data.isDateLessThan60Days===false)
    {
        setDetails(response.data)
       // Set numberOfProperties and detailsArray based on the details object
        setNumberOfProperties(Object.keys(response.data).length); 
        setDetailsArray(Object.values(response.data));
        window.alert(response.data.sap_order_date+" order date is less than 2 months so you can continue to release")
    }
    else if(response.data.isDateLessThan60Days===true)
    {
        window.alert(response.data.sap_order_date+" order date is not less than 2 months so you cannot continue to release")
 
    }

    });

  }

  const openorder = () => {
    const order_no = document.getElementById('ordid').value;
    Axios.post(`${process.env.REACT_APP_API_URL}/order/open_order`, {
        order_no:order_no,
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        }).then((response) => {
          console.log(response)
          console.info("Response received",response)
          window.alert(order_no+" has been released")
          window.location.reload(true)
        

      });

  }

  return (

    <div>

      {isLoggedIn ? (

        <>

          <br />

          <h3 style={{ textAlign: 'center' }} data-cy="order_label">Open Closed Order</h3>

          <div className="form-group">

            <br />

            <label className="label1">Order No:</label>

            <input type="text" id="ordid" value={orderno} onChange={handleInputChange} className="input" />

            <div align="left">

              <button type="submit" className="btn btn-primary" onClick={search}> Display </button>

              <button style={{ marginLeft: '20px' }} type="submit" className="btn btn-primary" onClick={clear}>Clear</button>

            </div>
            </div>
            <br /><br />
            {numberOfProperties > 0 && (

              <table className="table container" id="tb1">

                <thead>

                  <tr>

                  <th scope="col">PO Number</th>
                 <th scope="col">Mill</th>
                {/* <th scope="col">mill</th>
                <th scope="col">Production Unit</th> */}
                <th scope="col">Article</th>
                <th scope="col">Shade</th>
                <th scope="col">Order Date</th>
                {/* <th scope="col">rec_plant</th> */}
                <th scope="col">Ordered qty</th>
                <th scope="col">Pending Quantity</th>
                {/* <th scope="col">PackerID</th> */}
                <th scope="col">Due Date </th>
                {/* <th scope="col">Open Order </th> */}
                  </tr>

                </thead>

                <tbody>
                <tr>
                {detailsArray.map((detail, index) => (

               <React.Fragment key={index}>

                <td>{detail}</td>

                </React.Fragment>

                ))}
                <td> <button type="submit" className="btn btn-primary" onClick={openorder}> Open Order </button>
</td>
                </tr>
                </tbody>

              </table>

            )}

          

        </>

      ) : (

        window.location.href = "#/login"

      )}

    </div>

  );

}

 

export default Open_order;