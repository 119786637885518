import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import './duedate.css'
import  secureLocalStorage  from  "react-secure-storage";

export default function Vendors() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");  
  const token = secureLocalStorage.getItem("token");
  const [details, setDetails] = useState([])
  const columns = [
    {
      name: "edit",
      options: {
        filter: false,
        label:"Edit",
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = details[tableMeta.rowIndex].id;
          return (
            <Link to={`/Vendor_data?id=${id}`}>
              <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15"/ >
            </Link>
          );
        }
      }
    },
   {name: "sc_vendor", label:"Vendor"},
    {name:"sap_plant",label:"SAP Plant"},
    {name:"uname",label:"User"},
    {name:"password",label:"Password"},
    {name:"prod_unit",label:"Prod Unit"},
   
  ];
    useEffect(()=>{
       if (isLoggedIn){
     const getVendorDetails=()=>{
      axios.post(`${process.env.REACT_APP_API_URL}/vendor/show_vendordata`,
      {
        
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        }).then((response) => {
        setDetails(response.data)

      });
    }
    getVendorDetails()
  }
  },[]);
      
  return(
    
    <div>
    {isLoggedIn ? ( <>
<div className='movv'>
        <Link to="/Vendor_data" >
            <button type="submit" className="btn btn-primary">
            New
          </button>
        </Link>
        </div>
   <br/>
      <div className='container'>
      <MUIDataTable title={"VENDOR DATA"}
        data={details}
        columns={columns}
        options={{ filterType: 'checkbox',
        selectableRows: 'none',sort: false }}
      />
    </div>
    </>) : (window.location.href = "#/login")}
   </div>
   
  );
  
};
