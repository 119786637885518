import React, { useState,useEffect } from 'react';
import './master_data.css'
import { Link ,useParams} from 'react-router-dom';
import Select from "react-select";
import Axios from "axios";
import { useLocation } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from 'mdb-react-ui-kit';
function containsOnlychar(str) {
  return /^[a-zA-Z]{1,4}$/
  .test(str);
}
function containsOnlynumber(str) {
  return /^[0-9]+$/
  .test(str);
}

export default function Duedate_data() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const location=useLocation();
  const [details,setDetails]=useState([]);
  const token = secureLocalStorage.getItem("token");
  const id = new URLSearchParams(location.search).get('id');  
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const[pu_code,setpu_code]=useState([]); 
  const[pu_code1,setpu_code1]=useState("");  
  const [selectedStatus, setSelectedStatus] = useState('');  
 const handleSelectChange = (selectedOption) => {

  setpu_code1(selectedOption.value);
};

  useEffect(()=>{
    if (isLoggedIn){
    const getpucode=()=>{
      Axios.post(`${process.env.REACT_APP_API_URL}/prod-unit/pu_code_disp`,
      {
       
      },{
       headers: {
         'x-access-token': token,
         'Content-Type': 'application/json'}
       }).then((response) => {
      setpu_code(response.data)
 
      })
  
    }
    getpucode()
    const getDuedate=()=>{
     Axios.post(`${process.env.REACT_APP_API_URL}/due-date/edit_Duedate`,
     {
      id:id,
     },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      if (response !== undefined) {
       
        setDetails(response.data)
        setSelectedStatus(response.data[0].prod_unit)
    } else {
        console.log('Response is undefined');
    }
     })
     .catch((error) => {
      console.error(error);
    });
   }
   getDuedate()
  }
 },[]);

 const Duedate_update = (e) => {
  e.preventDefault();
  let prod_unit=selectedStatus;
  if(pu_code1)
  {
     prod_unit = pu_code1;
  }
  else{
     prod_unit = selectedStatus;
  }
  const material = document.getElementById("material").value;
  const days = document.getElementById("days").value;
  
  let ans2=containsOnlynumber(days)

  
  if (!prod_unit || !material || !days) {
    window.alert("Please fill in all fields.");
    return;
  }
  else if(ans2==false)
  {
    window.alert("Days Should Only Contain Numeric value")
  }

  else{
  Axios.post(`${process.env.REACT_APP_API_URL}/due-date/Update_Duedate`, {
    id:id,
    prod_unit:prod_unit,
    material:material,
    days:days,
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
    if(response.data == "50")
    {
        window.alert("Values updated");
      window.location.href = '#/Duedate';
      window.location.reload(true);
    }
   
  });
}
 }

const Due_date_insert = (e) => {
  e.preventDefault();
  const prod_unit = pu_code1;
  const material = input2;
  const days = input3;
 
  //let ans=containsOnlychar(prod_unit)
  let ans2=containsOnlynumber(days)

  if (!prod_unit || !material || !days ) {
    window.alert("Please fill in all fields.");
    return;
  }
    else if(ans2==false)
    {
      window.alert("Days Should Only Contain Numeric value")
    }


  else{
  Axios.post(`${process.env.REACT_APP_API_URL}/due-date/Insert_Duedate`, {
    prod_unit : prod_unit,
    material : material,
    days: days,
    
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
    if(response.data == "50")
    {
      window.alert("Production Unit already exist");
    }
    else
    {
      window.alert("New Duedate Inserted");
      window.location.href = '#/Duedate';
      window.location.reload(true);
    }
  });}
};


  const inputStyle = { margin: '0 5px' };

const options = pu_code.map((item) => ({
  label: item.pu_code,
  value: item.pu_code,
}));
  return (
    
    <div>
       {isLoggedIn ? ( <>
     {details.length >0 &&
      details.map((d) => ( 
            <div>

            <MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
            <MDBCardBody>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="prod_unit">Production Unit</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
                <MDBCardText className="text-muted">
                <Select options={options}  onChange={handleSelectChange} defaultValue={options.find((option) => option.value === selectedStatus)} id="pu_code" placeholder="Search Production Unit" />
              
                </MDBCardText>
                </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="material">Material</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="material" style={{inputStyle}} defaultValue={d.material} placeholder={'Material'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="days">Days</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="days" style={{inputStyle}} defaultValue={d.days} placeholder={'Days'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <button type="submit" className="btn btn-primary" onClick={Duedate_update} >
            Save
            </button>
            <Link to="/Duedate">
            <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
            </button>
            </Link>
            
            </MDBCardBody>
            </MDBCard> 
            </MDBCol>

            </div>
     ))}
     {details.length === 0 && 
     <div>

<MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
              <MDBCardBody>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="prod_unit">Production Unit</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                
                <MDBCardText className="text-muted">
                <Select options={options}  onChange={handleSelectChange} id="pu_code" placeholder="Search Production Unit" />
              
                </MDBCardText>
                </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="material">Material</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input2} placeholder={'Material'} onChange={(e) => setInput2(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="days">Days</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input3} placeholder={'Days'} onChange={(e) => setInput3(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

               

          
          <button type="submit" className="btn btn-primary" onClick={Due_date_insert} >
            Save
          </button>
          <Link to="/Duedate">
          <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
          </button>
          </Link>
                 
              </MDBCardBody>
            </MDBCard> 
          </MDBCol>
      
      </div>}
      </>) : (window.location.href = "#/login")}  
    </div>
  );
}