
import React, { useState, useEffect } from "react";
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import secureLocalStorage from "react-secure-storage";
import Filter_component from '../components/filter/filter_component';
import Axios from "axios";
import './muitab.css';

function Track_status() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const [selectedvalue, setselectedvalue] = useState('10');
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const role=secureLocalStorage.getItem("role");
  const [pageSize, setPageSize] = useState(10);
  const [details, setDetails] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const token = secureLocalStorage.getItem("token");
  const [filteredData, setFilteredData] = useState([]);
  const format_date = (utcDate) => {
    const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
    return istDate;
  };

  const columns = [
    { name: "po_number", label: "PO Number" },
    {name:"material", label: "Art",
options: {
    customBodyRender: (value) =>  value.substr(0, 4) 
  }
},
    { name: "material", label: "Material" },
    // {
    //   name: "ord_date",
    //   label: "Order Date",
    //   options: {
    //     customBodyRender: (value) => format_date(value),
    //   },
    // },
    {
      name: "sap_order_date",
      label: "SAP Order Date",
      options: {
        customBodyRender: (value) => format_date(value),
      },
    },
    { name: "ord_qty", label: "Order Quantity" },
    { name: "pendingqty", label: "Pending Quantity" },
    { name: "statuscode", label: "Status" },
    {
      name: "due_date",
      label: "Due Date",
      options: {
        customBodyRender: (value) => format_date(value),
      },
    },
    { name: "uname", label: "User Name" },
    { name: "rec_plant", label: "Rec plant" },
  ];
  const mill = secureLocalStorage.getItem("mill");
  useEffect(() => {
    if (isLoggedIn) {
//       // Get the total count of records
 const fetchData = async () => {
        try {
       
      
        const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/order/corder_disp`, {
          value1: Number(selectedvalue),
          // offset: offset,
          page: page, // Pass current page
          pageSize: pageSize, 
        }, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
          }
        });
        const totalRecords = data.totalCount;
        const response = data.result;
        console.log("totalRecords",totalRecords)
        console.log("response",response)
        // const totalRecords = totalRecordsResponse.data[0].count;
        setTotalRecords(totalRecords)
          const totalPages = Math.ceil(totalRecords / pageSize);
          console.log("total pages",totalPages)
          setTotalPages(totalPages);
        // setDetails(response.data)
        const uniqueRows = response.filter((newRow) => {
          return !details.some((existingRow) => existingRow.sc_order_no === newRow.sc_order_no);
        });

        setDetails((prevDetails) => [...prevDetails, ...uniqueRows]);
        console.log(response)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }
  }, [selectedvalue, offset, page, pageSize, token, isLoggedIn]);

  const handlePageChange = (newPage) => {
    const newOffset = newPage * pageSize; // Calculate the new offset
    setPage(newPage + 1);
    setOffset(newOffset);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset the page to 1 when page size changes
    setOffset(0); // Reset the offset to 0 when page size changes
  };
  

  const handleFilteredDetails = (details) => {
    setFilteredData(details);
    console.log(details.length)
    if(details.length===0)
    {
// window.alert("No data for the applied filter")
setDetails([])
setTotalRecords(details.length)
return
    }
    else
    setTotalRecords(details.length)
  };

  return (
    <div>
     
      <h3 style={{ marginLeft: "55px" }} data-cy="order_label">Track Status</h3>
      {isLoggedIn ? (
        <>
          <Filter_component onFilter={handleFilteredDetails} /><br /><br />
          {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '1600px', border: '2px solid #000' }}> */}
          <div style={{ marginLeft: "55px",display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth:'93%'}}>
          <div style={{ width: '100%' }}>
          {console.log("details",details)}
          {console.log("page",page-1)}
          {console.log("rowsPerPage",pageSize)}
            <MUIDataTable
              // data={details}
              data={filteredData.length > 0 ? filteredData : details}
              columns={columns}
              options={{
                responsive: 'vertical',
                filterType: 'checkbox',
                selectableRows: 'none',
                sort: false,
                filter: false,
                page: page - 1,
                rowsPerPage: pageSize,
                // rowsPerPageOptions: [10, 20, 30],
                
                // onChangePage: handlePageChange,
                // onChangeRowsPerPage: handlePageSizeChange,
                onChangePage: filteredData.length === 0 ? handlePageChange : undefined,
    onChangeRowsPerPage: filteredData.length === 0 ? handlePageSizeChange : undefined,
    count: totalRecords,
    downloadOptions : {
      filename: 'OrderDetails.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
              }}
              className="custom-table"
            />
            </div>
            </div>
        </>
      ) : (
        window.location.href = "#/login"
      )}
    </div>
  );
}

export default Track_status;
