import React from 'react'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import "./cards.css";
import flame from '../../assets/flame.jpg';
import shoe from '../../assets/shoe.jpg'; 
import nool from '../../assets/nool.jpg'; 
function Cards1() {
  return (
    <CardGroup>
      <Card className='Cards1'>
        <Card.Img variant="top" src={flame} width="40" height="300" />
        <Card.Body>
          <Card.Title>APPAREL</Card.Title>
          <Card.Text>
          Flame retardant clothing with reliable protection ,security and safety when it matters most
          </Card.Text>
        </Card.Body>
       
      </Card>
      <Card className='Cards2'>
        <Card.Img variant="top" src={shoe} width="40" height="300" />
        <Card.Body>
          <Card.Title>FOOTWEAR</Card.Title>
          <Card.Text>
          High quality composites, threads and yarns for footwear.Range of engineered yarns for footwear.
          </Card.Text>
        </Card.Body>
    
      </Card>
      <Card className='Cards3'>
        <Card.Img variant="top" src={nool}  width="40" height="300"/>
        <Card.Body>
          <Card.Title>PERFORMANCE MATERIAL</Card.Title>
          <Card.Text>
          Maximum productivity and superior quality of seams.A high performance and natural-looking sewing thread.
          </Card.Text>
        </Card.Body>
      
      </Card>
    </CardGroup>
  );
}

export default Cards1;