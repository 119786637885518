import React, { useState, useEffect ,useRef} from 'react'
import MUIDataTable from 'mui-datatables'
import { Button, MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import Axios from 'axios';
// import Select from "react-select";
// import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import  secureLocalStorage  from  "react-secure-storage";
import FileSaver from 'file-saver'
export default function Muidata() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const token = secureLocalStorage.getItem("token");
  const uname= secureLocalStorage.getItem("activeUser");
  const [order_id,setorderids]=useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [details,setDetails]=useState([]);
  const [selectedOrderId1, setSelectedOrderId1] = useState('');
  const [selectedOrderId2, setSelectedOrderId2] = useState('');
  const mill = secureLocalStorage.getItem("mill");
  const [article,setSelectedArticle]=useState([]);
  const [selectedArticle, setArticle] = useState('');
  const [shade,setSelectedShade]=useState([]);
  const [selectedShade, setShade] = useState('');
  const [depo,setSelectedDepo]=useState([]);
  const [selectedDepo, setDepo] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const fileInputRef = useRef(null);
  const [isDepoInputFocused, setIsDepoInputFocused] = useState(false); 
  const [isArticleInputFocused, setIsArticleInputFocused] = useState(false); 
  const [selectedOption1, setSelectedOption1] = useState('');
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedvalue, setselectedvalue] = useState('10');
  const [totalRecords, setTotalRecords] = useState(1);
  const columns = [
    {name:"carton_no",label:"Carton No"},
    {name:"sc_order_no",label:"SC Order No"},
    {name:"mill",label:"Mill"},
    {name:"article",label:"Article"},
    {name:"shade",label:"Shade"},
    {name:"depot",label:"Depot"},
    {name:"fullqty",label:"Quantity"},
    {name:"packerid",label:"Packer ID"},
    {name:"grosswt",label:"Gross Weight"},
    // {name:"greykg",label:"Net Weight"},
    {name:"cartontype",label:"Carton Type"},
    {name:"statuscode",label:"Status"},
    {name:"carton_date", label: "Carton Date",
options: {
  customBodyRender: (value) => format_date(value)
}
},
{name:"count_label",label:"Count Label"}]
  useEffect(() => {
    if (isLoggedIn){
      const getAllDetails = () => {
        if (mill == "COATS") {
        
          Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,{
          },{
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'}
            }).then((response) => {
           
          setorderids(response.data)
          });
          }
          else{
          Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp_vendor1`,{
          
           mill:mill,
          },{
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'}
            }).then((response) => {
          setorderids(response.data)
          });
          }
        }
        getAllDetails()
    Axios.post(`${process.env.REACT_APP_API_URL}/label/article_disp`,{
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
        console.log(response.data)
        setSelectedArticle(response.data)
    });
    Axios.post(`${process.env.REACT_APP_API_URL}/label/depo_disp`,{
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
        console.log(response.data)
        setSelectedDepo(response.data)
    });
  //   if (mill == "COATS") {

  //     Axios.post(`${process.env.REACT_APP_API_URL}/label/id_disp1`,{
  //   },{
  //     headers: {
  //       'x-access-token': token,
  //       'Content-Type': 'application/json'}
  //     }).then((response) => {
  //    setorderids(response.data)
  //   });
  // }
  // else{
  //   Axios.post(`${process.env.REACT_APP_API_URL}/label/id_disp1_vendor`,{
      
  //      mill:mill,
  // },{
  //   headers: {
  //     'x-access-token': token,
  //     'Content-Type': 'application/json'}
  //   }).then((response) => {
  //  setorderids(response.data)
  // });
  //  }
  if (
    selectedvalue !== '10' ||
    offset !== 0 ||
    page !== 1 ||
    pageSize !== 10
  ) {
    combo_filter();
  }
  }
  }, [selectedvalue, offset, page, pageSize]);
  const options1 = article.map((item) => ({
    label: item.material_code,
    value: item.material_code,
  }));
  // const options2 = shade.map((item) => ({
  //   label: item.material_code,
  //   value: item.material_code,
  // }));
  const options_shade = order_id.map((item) => ({
    label: item.shade,
    value: item.shade,
  }));
  const options2 = options_shade.filter((options_shade, index, self) =>
    index === self.findIndex((t) => (
      t.value === options_shade.value && t.label === options_shade.label
    ))
  );
  const options3 = depo.map((item) => ({
    label: item.depo_name,
    value: item.depo_name,
  }));
  const handleArticleChange = (event,newValue) => {
    if (newValue) {
        setArticle(newValue.value); // Update with the selected option's value
      } else {
        setArticle(''); // Reset the state if no option is selected
      }
  };
  const handleShadeChange = (event, newValue) => {
    if (newValue) {
      setShade(newValue.value); // Update with the selected option's value
    } else {
      setShade(''); // Reset the state if no option is selected
    }
  };
  const handleDepoChange = (event, newValue) => {
    if (newValue) {
      setDepo(newValue.value); // Update with the selected option's value
    } else {
      setDepo(''); // Reset the state if no option is selected
    }
  };
  const handlesetFromDate  = (event) => {
    setFromDate(event.target.value);
    
        }
        const handleStatusChange = (event) => {
          setSelectedOption1(event.target.value);
        };
 const format_date=(utcDate)=>{
  const istDate=moment.utc(utcDate).tz('Asia/Kolkata').format('DD/MM/YYYY');
  return istDate;
  
}
const getBrowserName = () => {
  let browserInfo = navigator.userAgent
  let browser
  if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
    browser = 'Opera'
  } else if (browserInfo.includes('Edg')) {
    browser = 'Edge'
  } else if (browserInfo.includes('Chrome')) {
    browser = 'Chrome'
  } else if (browserInfo.includes('Safari')) {
    browser = 'Safari'
  } else if (browserInfo.includes('Firefox')) {
    browser = 'Firefox'
  } else {
    browser = 'unknown'
  }
  return browser
}
const downloadData = async () => {
  var len=0
  const response = await Axios.post(`${process.env.REACT_APP_API_URL}/label/solid_label_data_download`, {

    date1:fromDate,
    article:selectedArticle,
    shade:selectedShade,
    mill:mill,
    depo:selectedDepo,
    cartontype:selectedOption1,
    page: page, // Pass current page
    pageSize: pageSize,
},{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  })
  for(len=0;len<response.data.length;len++)
  {
   console.log("response[len].cartontype",response.data[len].cartontype)
   if(response.data[len].cartontype==="Solid")
   {
   console.log("response[len].fullqty",response.data[len].fullqty)
   response.data[len].fullqty=response.data[len].at_cc;
   console.log("response[len].fullqty",response.data[len].fullqty)
   }
   if(response.data[len].cartontype==="Loose")
   {
   const grossWeight =  ((response.data[len].grosswt/response.data[len].at_cc) * response.data[len].fullqty).toFixed(1)
   response.data[len].grosswt=grossWeight
   }
   }
   const browser = getBrowserName()
  const lastCartonNo = details.length > 0 ? details[0].carton_no : null;
const firstCartonNo = details.length > 0 ? details[details.length - 1].carton_no : null;

var label_type
console.log("firstCartonNo",firstCartonNo)
console.log("lastCartonNo",lastCartonNo)
console.log("response",response.data)
  Axios.post(`${process.env.REACT_APP_API_URL}/label/label_update_after_donwload`,{

    carton_no1: firstCartonNo,
    carton_no2: lastCartonNo,
    details:response.data,
},{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  }).then((response) => {

});
  let data = '';
 let ctype=''
 var doc = document.getElementById('printf').contentWindow.document;
    doc.open();
  // Loop through details array and add each row to the text file
  if(firstCartonNo===lastCartonNo)
  {
  response.data.forEach((item) => {
    label_type =
          browser === 'Safari' || browser === 'Firefox' ? 'Loose' : 'Loose.dat'
    const netValue = (item.fullqty * item.greykg).toFixed(1)
    const crossWeight =  ((item.grosswt/item.at_cc) * item.fullqty).toFixed(1)
    const article = `${item.article.substring(0,4)}/${item.article.substring(4,7)}`
    if(uname=='madura_admin')
    {
      doc.write(" <B><p style='line-height: 0.9;'>Carton No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.carton_no);
  doc.write(" <B><p style='line-height: 0.9;'>Depot&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.depot);
  doc.write(" <B><p style='line-height: 0.9;'>Article&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", article);
  // doc.write(" <B><p>Ticket No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.article.substring(4,7));
  doc.write(" <B><p style='line-height: 0.9;'>Shade&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.shade);
  doc.write(" <B><p style='line-height: 0.9;'>Full Quantity&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",item.fullqty);
  doc.write(" <B><p style='line-height: 0.9;'>AT_ASU&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.at_asu);
  doc.write(" <B><p style='line-height: 0.9;'>Netvalue &nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",netValue );
  doc.write(" <B><p style='line-height: 0.9;'>Gross wt&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", crossWeight);
  doc.write(" <B><p style='line-height: 0.9;'>Carton  Date&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",  format_date(item.carton_date));
  doc.write(" <B><p style='line-height: 0.9;'>Packer ID&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.packerid);
     }
     else{
    data += `${item.carton_no},${item.depot},${article}, ${item.shade}    ${item.fullqty} ${item.at_asu}   ,,,,,,  ${netValue},${crossWeight},${format_date(item.carton_date)},${item.packerid}\r\n`;
     }
  });
  }
  else{
    console.log("response.data",response.data)
    console.log("selectedOption1",selectedOption1)
    
    response.data.forEach((item) => {
      if(selectedOption1==="Solid")
      {
        label_type =
      browser === 'Safari' || browser === 'Firefox' ? 'Solid' : 'Solid.dat'
      }
      else{
        label_type =
        browser === 'Safari' || browser === 'Firefox' ? 'Loose' : 'Loose.dat'
      }
      const netValue = (item.greykg * item.at_cc).toFixed(1)
      var fullqty
      if(item.cartontype==="Solid")
        fullqty=item.at_cc
      else
        fullqty=item.fullqty
        if(uname=='madura_admin')
        {
          doc.write(" <B><p style='line-height: 0.6;'>Carton No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.carton_no);
      doc.write(" <B><p style='line-height: 0.6;'>Depot&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.depot);
      doc.write(" <B><p style='line-height: 0.6;'>Article&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.article.substring(0,4));
      doc.write(" <B><p style='line-height: 0.6;'>Ticket No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.article.substring(4,7));
      doc.write(" <B><p style='line-height: 0.6;'>Shade&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.shade);
      doc.write(" <B><p style='line-height: 0.6;'>Full Quantity&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", fullqty);
      doc.write(" <B><p style='line-height: 0.6;'>AT_ASU&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.at_asu);
      doc.write(" <B><p style='line-height: 0.6;'>Carton No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.carton_no);
      doc.write(" <B><p style='line-height: 0.6;'>Gross wt&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.grosswt);
      doc.write(" <B><p style='line-height: 0.6;'>Carton  Date&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",  format_date(item.carton_date));
      doc.write(" <B><p style='line-height: 0.6;'>Packer ID&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.packerid);
      doc.write(" <B><p style='line-height: 0.6;'>&nbsp;&nbsp;&nbsp&nbsp; &nbsp;&nbsp;&nbsp; ");
         }
         else{
      data += `${item.carton_no},${item.depot} ,${item.article.substring(0,4)},${item.article.substring(4,7)},${item.shade}, ${fullqty} ${item.at_asu},${item.carton_no},${netValue},${item.grosswt},${format_date(item.carton_date)},${item.packerid}\r\n`;
         }
    });
  }
  if(uname=='madura_admin')
{
  doc.close()
  window.frames["printf"].focus()
   window.frames["printf"].print()

}
 else{
  try {
    if (browser === 'Safari' || browser === 'Firefox') {
      var blob = new Blob([data], { type: 'application/octet-stream' })
      FileSaver.saveAs(blob, `${label_type}.dat`)
    } else {
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: label_type,
        types: [
          {
            description: 'Dat Files',
            accept: {
              'application/octet-stream': ['.dat'],
            },
          },
        ],
      })

      const writable = await fileHandle.createWritable()
      await writable.write(data)
      await writable.close()
    }
  } catch (error) {
    console.error('Error saving file:', error)
  }
}
};

  // Define options for the Select component
  const options = order_id.map((item) => ({
    label: item.carton_no,
    value: item.carton_no,
}));
const combo_filter = async() => {
  console.log(selectedArticle) 
  if(!selectedOption1)
  {
    window.alert("Select Carton Type") 
    return
  }
  if(!fromDate && !selectedArticle && !selectedShade && !selectedDepo && !selectedOption1)
  {
      window.alert("Apply Filter") 
    window.location.reload(true);
  }
  const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/label/solid_label_download`, {

    date1:fromDate,
    article:selectedArticle,
    shade:selectedShade,
    mill:mill,
    depo:selectedDepo,
    cartontype:selectedOption1,
    page: page, // Pass current page
    pageSize: pageSize,
},{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  })
  const totalCount = data.totalCount;
  const response = data.result;
  console.log("data",data)
          console.log("totalCount",totalCount)
          console.log("response",response)
          var len;
          if(page===1)
          {
            for(len=0;len<response.length;len++)
            {
             console.log("response.data[len].cartontype",response[len].cartontype)
             if(response[len].cartontype==="Solid")
             {
             console.log("response[len].fullqty",response[len].fullqty)
             response[len].fullqty=response[len].at_cc;
             console.log("response[len].fullqty",response[len].fullqty)
             }
             if(response[len].cartontype==="Loose")
             {
             const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
             response[len].grosswt=grossWeight
             }
             }
             setDetails(response)
          }
          else{
           
            for(len=0;len<response.length;len++)
            {
             console.log("response.data[len].cartontype",response[len].cartontype)
             if(response[len].cartontype==="Solid")
             {
             console.log("response[len].fullqty",response[len].fullqty)
             response[len].fullqty=response[len].at_cc;
             console.log("response[len].fullqty",response[len].fullqty)
             }
             if(response[len].cartontype==="Loose")
             {
             const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
             response[len].grosswt=grossWeight
             }
             }
             setDetails((prevDetails) => [...prevDetails, ...response]);
            
          }
          setTotalRecords(totalCount)
  

}
const handlePageChange = (newPage) => {
  const newOffset = newPage * pageSize; // Calculate the new offset
  setPage(newPage + 1);
  setOffset(newOffset);
  
};

const handlePageSizeChange = (newPageSize) => {
  setPageSize(newPageSize);
  setPage(1); // Reset the page to 1 when page size changes
  setOffset(0); // Reset the offset to 0 when page size changes
};
const clear_values = () => {
  setArticle('');
  setShade('');
  setFromDate('');
  setDepo('');
  setSelectedOption1('')
}
  return (
    // <div className='container'>
    <div>  
       {isLoggedIn ? ( <>
         <br/>  <h3 style={{marginLeft:"55px"}} data-cy="order_label">Download label</h3> <br/>
    <div style={{width:'80%',display:'flex', justifyContent:'space-evenly',marginLeft:'50px'}}>
      <br/><br/>
       <FormControl style={{ display: "flex", marginLeft: "-110px", width: "150px" }}>
      <InputLabel shrink={selectedArticle !== '' || isArticleInputFocused}>Select Article</InputLabel>
      <Autocomplete
        options={options1}
        value={options1.find(option => option.value === selectedArticle) || null}
        onChange={handleArticleChange}
        getOptionLabel={option => option.label}
        onFocus={() => setIsArticleInputFocused(true)}
        onBlur={() => setIsArticleInputFocused(false)}
        renderInput={params => <TextField {...params} />}
        style={{ fontSize: "12px" }}
      />
    </FormControl>
   

<FormControl style={{ display: "flex", marginLeft: "10px", width: "150px" }}>
  <InputLabel shrink={selectedShade !== '' || isInputFocused}>Select Shade</InputLabel>
  <Autocomplete
    options={options2}
    value={options2.find(option => option.value === selectedShade) || null}
    onChange={handleShadeChange}
    getOptionLabel={option => option.label}
    onFocus={() => setIsInputFocused(true)}
    onBlur={() => setIsInputFocused(false)}
    renderInput={params => <TextField {...params} />}
    style={{ fontSize: "12px" }}
  /></FormControl>
  <FormControl style={{ display: "flex", marginLeft: "10px", width: "150px" }}>
  <InputLabel shrink={selectedDepo !== '' || isDepoInputFocused}>Select Depo</InputLabel>
  <Autocomplete
    options={options3}
    value={options3.find(option => option.value === selectedDepo) || null}
    onChange={handleDepoChange}
    getOptionLabel={option => option.label}
    onFocus={() => setIsDepoInputFocused(true)}
    onBlur={() => setIsDepoInputFocused(false)}
    renderInput={params => <TextField {...params} />}
    style={{ fontSize: "12px" }}
  /></FormControl>
    <FormControl style={{ display: "flex", marginLeft: "10px", width: "150px" }}>
        <InputLabel>Carton Type</InputLabel>
        <Select value={selectedOption1} onChange={handleStatusChange}>
          {/* <MenuItem value="Carton Created">Carton Created</MenuItem> */}
          <MenuItem value="Solid">Solid</MenuItem>
          <MenuItem value="Loose">Loose</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Select Carton Date"
        type="date"
        value={fromDate}
        onChange={handlesetFromDate}
        style={{ display: "flex", marginLeft: "10px", width: "150px" }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          max: new Date().toISOString().split('T')[0],
        }}
      />
      <Button variant="contained" color="primary" onClick={() => {
 setPage(1);
 setPageSize(10);
 setDetails([]);
 combo_filter();
}}>
        Filter
      </Button>
      <Button variant="contained" color="primary" onClick={clear_values}>
        Clear
      </Button>
      </div>
<br/>
      {isLoggedIn ? ( <>
      {details.length > 0 ? (
      <>
       <div style={{width:'95%',display:'flex', justifyContent:'right'}}> 
               <Button variant="contained" color="primary" 
               onClick={downloadData}
              //  onClick={() => fileInputRef.current.click()}
               >
               Download Label
      </Button>
               </div><br/>
               <div >
<iframe id="printf" name="printf"  style={{ display: 'none' }} ></iframe>
</div>
       <div style={{ marginLeft: "55px",display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth:'93%'}}>
          <div style={{ width: '100%' }}>
      <MUIDataTable title={"DOWNLOAD SOLID LABEL"}
            data={details}
            columns={columns}
            options={{ filterType: 'checkbox', selectableRows: 'none', sort: false ,
            filter: false,
                page: page - 1,
                rowsPerPage: pageSize,
                count: totalRecords,
                // onChangePage: handlePageChange,
                // onChangeRowsPerPage: handlePageSizeChange,
                onChangePage:  handlePageChange,
                onChangeRowsPerPage:handlePageSizeChange, 
            downloadOptions : {
                  filename: 'CartonDetails.csv',
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                  }
                }
            }}
            className="custom-table"  /></div></div>
              </>
      ) : (
        <p></p>
      )}
       </>) : (<input type="hidden"></input>)}  
       </>) : (window.location.href = "#/login")}  
    </div>
  )
}