import React from 'react'
import { BrowserRouter } from "react-router-dom"
import { Route, Routes } from "react-router-dom"
import Home from './routes/Home';
import Navbar1 from './components/Navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Vendors from './routes/vendors';
import Aboutus from './routes/Aboutus';
import Orders from './routes/orders';
import Contactus1 from './routes/contactus';
import Login2 from './components/login/login';
import Forget from './components/login/forget1';
import Glabel from './routes/loose_label/glabel';
import Dlabel1 from './routes/solid_label/dlabel1';
import Glabel1 from './routes/solid_label/glabel1';
import Master_data from './routes/master_data';
import Master from './routes/master';
import Master_data_upload from './routes/master_data_upload';
import Production from './routes/prod_unit';
import Vendor_data from './routes/vendor_data';
import Production_data from './routes/prod_unit_data';
import Duedate from './routes/duedate';
import Duedate_data from './routes/duedate_data';
import ProfilePage from './routes/profile';
import Track_status from './routes/track_status';
import Update_status from './routes/update_status';
import Status_change from './routes/status_change';
import Multiple_status_up from './routes/multiple_status_up';
import Log_report from './routes/log_report';
import Upload_file_status from './routes/upload_file_status';
import Excel_component from './components/excel/excel_component';
import { HashRouter } from "react-router-dom";
import Report_download from './routes/report';
import Cwhartktmas from './routes/cwhartktmas';
import Depo from './routes/depo';
import Carton_cancel from './routes/carton_cancel';
import Carton_status from './routes/carton_status';
import Carton_reprint from './routes/carton_reprint';
import Open_order from './routes/open_order';
import Filter_component from './components/filter/filter_component';
import Despatch from './routes/depatch';
import Document_Display from './routes/document_display';
function App() {
  return (
    <div>
    <HashRouter>
        <Navbar1 />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/Upload_file_status" element={<Upload_file_status />} />
        <Route path="/vendors" element={<Vendors />} />
        <Route path="/Vendor_data" element={<Vendor_data />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/Loose_Glabel" element={<Glabel />} />
        <Route path="/Solid_Glabel" element={<Glabel1 />} />
        <Route path="/Solid_Dlabel" element={<Dlabel1 />} />
        <Route path="/Master_data" element={<Master_data />} />
        <Route path="/Prod_unit_data" element={<Production_data />} />
        <Route path="/Master_data_upload" element={<Master_data_upload />} />
        <Route path="/Master" element={<Master />} />
        <Route path="/Duedate" element={<Duedate />} />
        <Route path="/Duedate_data" element={<Duedate_data />} />
        <Route path="/Production" element={<Production />} />
        <Route path="/login" element={<Login2 />} />
        <Route path="/forget" element={<Forget/>}/>
        <Route path="/track_status" element={<Track_status/>}/> 
        <Route path="/update_status" element={<Update_status/>}/>
        <Route path="/status_change" element={<Status_change/>}/>
        <Route path="/multiple_status_up" element={<Multiple_status_up/>}/>
        <Route path="/log_report" element={<Log_report/>}/> 
        <Route path="/excel_component" element={<Excel_component/>}/> 
        <Route path="/Report_download" element={<Report_download/>}/> 
        <Route path="/Cwhartktmas" element={<Cwhartktmas/>}/> 
        <Route path="/Depo" element={<Depo/>}/> 
        <Route path="/Carton_cancel" element={<Carton_cancel/>}/> 
        <Route path="/Carton_status" element={<Carton_status/>}/> 
        <Route path="/Carton_reprint" element={<Carton_reprint/>}/> 
        <Route path="/Open_order" element={<Open_order/>}/> 
        <Route path="/Filter_component" element={<Filter_component/>}/> 
        <Route path="/Despatch" element={<Despatch/>}/> 
        <Route path="/Document_Display" element={<Document_Display/>}/> 
       </Routes>
    </HashRouter>
    
    </div>
  );
}

export default App;
