import React,{ useState,useEffect } from "react";
// import Select from "react-select";
// import { Select, MenuItem, TextField, Button } from '@mui/material';
import { Button, MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import moment, { isDate } from 'moment-timezone';
import MUIDataTable from 'mui-datatables'
import  secureLocalStorage  from  "react-secure-storage";
import Filter_component from '../components/filter/filter_component';
import Axios from "axios";
import './muitab.css';

function Carton_status() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const [order_id,setorderids]=useState([]);
    const [selectedOption1, setSelectedOption1] = useState([])
    const [article,setSelectedArticle]=useState([]);
    const [selectedArticle, setArticle] = useState('');
    const [shade,setSelectedShade]=useState([]);
    const [selectedShade, setShade] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isArticleInputFocused, setIsArticleInputFocused] = useState(false); // Initialize the state
    // const [selectedShade, setShade] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const mill = secureLocalStorage.getItem("mill");
    const token = secureLocalStorage.getItem("token");
    const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedvalue, setselectedvalue] = useState('10');
  const [totalRecords, setTotalRecords] = useState(0);
    const [details,setDetails]=useState([]);
    const options = [
      "Generated Label",
      "Carton Printed",
      "Carton RePrinted",
    ];
    const columns = [
      {name:"carton_no",label:"Carton No"},
      {name:"po_number",label:"PO Number"},
      {name:"mill",label:"Mill"},
      {name:"article",label:"Article"},
      {name:"shade",label:"Shade"},
      {name:"depot",label:"Depot"},
      {name:"fullqty",label:"Quantity"},
      {name:"packerid",label:"Packer ID"},
      {name:"grosswt",label:"Gross Weight"},
      // {name:"greykg",label:"Net Weight"},
      {name:"cartontype",label:"Carton Type"},
      {name:"statuscode",label:"Status"},
      {name:"carton_date", label: "Carton Date",
  options: {
    customBodyRender: (value) => format_date(value)
  }
  },
  {name:"count_label",label:"Count Label"}]
    useEffect(() => {
      if (isLoggedIn){
        const getAllDetails = () => {
          if (mill == "COATS") {
          
            Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,{
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              }).then((response) => {
             
            setorderids(response.data)
            });
            }
            else{
            Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp_vendor1`,{
            
             mill:mill,
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              }).then((response) => {
            setorderids(response.data)
            });
            }
          }
          getAllDetails()
    Axios.post(`${process.env.REACT_APP_API_URL}/label/article_disp`,{
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
        console.log(response.data)
        setSelectedArticle(response.data)
    });
    Axios.post(`${process.env.REACT_APP_API_URL}/label/shade_disp`,{
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
        console.log(response.data)
        setSelectedShade(response.data)
    });
    // combo_filter();
    if (
      selectedvalue !== '10' ||
      offset !== 0 ||
      page !== 1 ||
      pageSize !== 10
    ) {
      combo_filter();
    }
    }
    },[selectedvalue, offset, page, pageSize]);
    const format_date=(utcDate)=>{
        const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
        return istDate;
      }
    const options1 = article.map((item) => ({
        label: item.material_code,
        value: item.material_code,
      }));
      // const options2 = shade.map((item) => ({
      //   label: item.material_code,
      //   value: item.material_code,
      // }));
      const options_shade = shade.map((item) => ({
        label: item.shade,
        value: item.shade,
      }));
      const options2 = options_shade.filter((options_shade, index, self) =>
        index === self.findIndex((t) => (
          t.value === options_shade.value && t.label === options_shade.label
        ))
      );
    const setdate1 = (event) => {
        console.log(event.target.value)
        setFromDate(event.target.value);
      };
    //   const handleArticleChange = (event) => {
    //     const selectedValue = event.target.value;
    //     setArticle(selectedValue);
    //     console.log('Selected Article:', selectedValue);
    //   };
      const handleArticleChange = (event,newValue) => {
        if (newValue) {
            setArticle(newValue.value); // Update with the selected option's value
          } else {
            setArticle(''); // Reset the state if no option is selected
          }
      };
    //   const handleShadeChange = (event) => {
    //     const selectedValue = event.target.value;
    //     setShade(selectedValue);
    //     console.log('Selected shade:', selectedValue);
    //   };
    const handleShadeChange = (event, newValue) => {
        if (newValue) {
          setShade(newValue.value); // Update with the selected option's value
        } else {
          setShade(''); // Reset the state if no option is selected
        }
      };
      const handlesetFromDate  = (event) => {
        setFromDate(event.target.value);
        
            }
      const handlesetToDate  = (event) => {
              setToDate(event.target.value);
              
            }
        const combo_filter = async() => {
        console.log(selectedArticle)
        try {
          console.log("from",fromDate)
          console.log("to",toDate)
          console.log(selectedArticle)
        if(toDate && (fromDate===null || fromDate===""))
        {
          window.alert("Select From Date ")
          return
        }
          const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/label/carton_status`, {
   
            // statuscode: selectedOrderstatuscode,
            date1:fromDate,
            date2:toDate,
            // article:selectedArticle?.value,
            // shade:selectedShade?.value,
            article:selectedArticle,
            shade:selectedShade,
            mill:mill,
            status:selectedOption1,
            value1: Number(selectedvalue),
            // offset: offset,
            page: page, // Pass current page
            pageSize: pageSize,
        },{
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'}
          });
          const totalCount = data.totalCount;
  const response = data.result;
          console.log("totalCount",totalCount)
          console.log("response",response)
          var len;
          if (page === 1) {
            // Reset the details state if it's the first page
            console.log("length",response.length)
            for(len=0;len<response.length;len++)
           {
            console.log("response[len].cartontype",response[len].cartontype)
            if(response[len].cartontype==="Solid")
            {
            console.log("response[len].fullqty",response[len].fullqty)
            response[len].fullqty=response[len].at_cc;
            console.log("response[len].fullqty",response[len].fullqty)
            }
            if(response[len].cartontype==="Loose")
            {
            const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
            response[len].grosswt=grossWeight
            }
            }
            setDetails(response);
          } else {
            // Append new data to the existing details state
            for(len=0;len<response.length;len++)
            {
             console.log("response[len].cartontype",response[len].cartontype)
             if(response[len].cartontype==="Solid")
             {
             console.log("response[len].fullqty",response[len].fullqty)
             response[len].fullqty=response[len].at_cc;
             console.log("response[len].fullqty",response[len].fullqty)
             }
             if(response[len].cartontype==="Loose")
             {
             const grossWeight =  ((response[len].grosswt/response[len].at_cc) * response[len].fullqty).toFixed(1)
             response[len].grosswt=grossWeight
             }
             }
            setDetails((prevDetails) => [...prevDetails, ...response]);
          }
          // const uniqueRows = response.filter((newRow) => {
          //   return !details.some((existingRow) => existingRow.carton_no === newRow.carton_no);
          // });
          
          // setDetails((prevDetails) => [...prevDetails, ...uniqueRows]);
          console.log(response)
          setTotalRecords(totalCount)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        
      }

      const clear_values = () => {
        setArticle('');
        setShade('');
        setFromDate('');
        setToDate('');
        setSelectedOption1([]);
        setDetails([]);
        setTotalRecords(0)
    }
    const handlePageChange = (newPage) => {
      const newOffset = newPage * pageSize; // Calculate the new offset
      setPage(newPage + 1);
      setOffset(newOffset);
      
    };
  
    const handlePageSizeChange = (newPageSize) => {
      setPageSize(newPageSize);
      setPage(1); // Reset the page to 1 when page size changes
      setOffset(0); // Reset the offset to 0 when page size changes
    };
    
    const handleStatusChange = (event) => {
        setSelectedOption1(event.target.value);
      };
      function downloadTable() {
        // create a new workbook
        const workbook = XLSX.utils.book_new();
      
        // convert the table data to a worksheet
        const worksheet = XLSX.utils.table_to_sheet(document.querySelector('table'));
      
        // add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
        // generate a blob object containing the XLSX file data
        const blob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
        // create a temporary link element to trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }));
        link.download = 'table.xlsx';
      
        // simulate a click event to trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
      }
      const downloadData = async () => {
        if(totalRecords!=0)
        {
        var data='';
        var len=0
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/label/carton_status`, {
   
          date1:fromDate,
          date2:toDate,
          article:selectedArticle,
          shade:selectedShade,
          mill:mill,
          status:selectedOption1,
          value1: Number(selectedvalue),
          page: page, // Pass current page
          pageSize: pageSize,
          download:1,
      },{
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'}
        });
          console.log("response.data",response.data)
          console.log("response.length",response.data.length)
          for(len=0;len<response.data.length;len++)
          {
           console.log("response[len].cartontype",response.data[len].cartontype)
           if(response.data[len].cartontype==="Solid")
           {
           console.log("response[len].fullqty",response.data[len].fullqty)
           response.data[len].fullqty=response.data[len].at_cc;
           console.log("response[len].fullqty",response.data[len].fullqty)
           }
           if(response.data[len].cartontype==="Loose")
           {
           const grossWeight =  ((response.data[len].grosswt/response.data[len].at_cc) * response.data[len].fullqty).toFixed(1)
           response.data[len].grosswt=grossWeight
           }
           }
          //  data = filteredData;
            data = response.data.map(row => {
            return {
              
              carton_no: row.carton_no,
              po_number: row.po_number,
              mill: row.mill,
              article: row.article,
              shade: row.shade,
              depot: row.depot,
              fullqty: Number(row.fullqty),
              packerid: row.packerid,
              grosswt: row.grosswt,
              cartontype: row.cartontype,
              statuscode: row.statuscode,
              carton_date: format_date(row.carton_date),
              count_label: row.count_label,
            };
          });
      
       
      
       console.log("data",data)
       console.log("data",data.length)
       if(data.length!=0)
       {
       const ws = XLSX.utils.json_to_sheet(data);
      
       
        const wb = XLSX.utils.book_new(); // Create a new workbook
        XLSX.utils.book_append_sheet(wb, ws, 'data'); // Add the worksheet to the workbook
         
        // Write the workbook to a buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
         
        // Create a Blob from the buffer
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
         
        // Create an anchor tag for downloading
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'Carton Status Data.xlsx';
         
        // Trigger the download
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
         
        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
        
       }
        }
      }
    return(
        <div>
          {isLoggedIn ? ( <>
        <br/>  <h3 style={{marginLeft:"55px"}} data-cy="order_label">Carton Status</h3>
            <br/>
   <div style={{width:'80%',display:'flex', justifyContent:'space-evenly',marginLeft:'50px'}}>
   <FormControl style={{ display: "flex", marginLeft: "-53px", width: "150px" }}>
      <InputLabel shrink={selectedArticle !== '' || isArticleInputFocused}>Select Article</InputLabel>
      <Autocomplete
        options={options1}
        value={options1.find(option => option.value === selectedArticle) || null}
        onChange={handleArticleChange}
        getOptionLabel={option => option.label}
        onFocus={() => setIsArticleInputFocused(true)}
        onBlur={() => setIsArticleInputFocused(false)}
        renderInput={params => <TextField {...params} />}
        style={{ fontSize: "12px" }}
      />
    </FormControl>
   

<FormControl style={{ display: "flex", marginLeft: "10px", width: "150px" }}>
  <InputLabel shrink={selectedShade !== '' || isInputFocused}>Select Shade</InputLabel>
  <Autocomplete
    options={options2}
    value={options2.find(option => option.value === selectedShade) || null}
    onChange={handleShadeChange}
    getOptionLabel={option => option.label}
    onFocus={() => setIsInputFocused(true)}
    onBlur={() => setIsInputFocused(false)}
    renderInput={params => <TextField {...params} />}
    style={{ fontSize: "12px" }}
  />
</FormControl>

<FormControl style={{ display: "flex", marginLeft: "10px", width: "170px" }}>
        <InputLabel>Status</InputLabel>
        <Select multiple value={selectedOption1} onChange={handleStatusChange}
          renderValue={(selectedOption1) => (
            <div>
              {selectedOption1.map((value) => (
                <div key={value}>{value}</div>
              ))}
            </div>
          )}
        
        > 
          {/* <MenuItem value="Generated Label">Generated Label</MenuItem>
          <MenuItem value="Carton Printed">Carton Printed</MenuItem>
          <MenuItem value="Carton RePrinted">Carton RePrinted</MenuItem> */}
           {options.map((option) => (
            
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
        </Select>
      </FormControl>

      <TextField
        label="Select From Date"
        type="date"
        value={fromDate}
        onChange={handlesetFromDate}
        style={{ display:"flex", marginLeft:"10px", width: "150px" }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          // max: new Date().toISOString().split("T")[0], // Set the max attribute to today's date
          max: toDate ? toDate : new Date().toISOString().split("T")[0],
        }}
      />
       <TextField
        label="Select To Date"
        type="date"
        value={toDate}
        onChange={handlesetToDate}
        style={{ display:"flex", marginLeft:"10px", width: "150px" }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: fromDate, 
          max: new Date().toISOString().split("T")[0], // Set the max attribute to today's date
        }}
      />
      <Button variant="contained" color="primary" onClick={() => {
 setPage(1);
 setPageSize(10);
 setDetails([]);
 combo_filter();
}}>
        Filter
      </Button>
      <Button variant="contained" color="primary" onClick={clear_values}>
        Clear
      </Button>
      </div>
<br/>

          {/* <div style={{ width: '100%' }}> */}
{details.length > 0 && ( 
  <div>
<div style={{width:'95%',display:'flex', justifyContent:'right'}}>    
  
        <Button variant="contained" color="primary" 
               onClick={downloadData}
              //  onClick={() => fileInputRef.current.click()}
               >
               Download
      </Button>
    
      </div>
        <br/>
        <div style={{ marginLeft: "55px",display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth:'93%'}}>
<MUIDataTable
              // data={details}
              data={details}
              columns={columns}
              options={{
                filterType: 'checkbox',
                selectableRows: 'none',
                download:false,
                print:false,
                viewColumns:false,
                search:false,
                selectableRows: 'none',
                sort: false,
                filter: false,
                page: page - 1,
                rowsPerPage: pageSize,
                count: totalRecords,
                // onChangePage: handlePageChange,
                // onChangeRowsPerPage: handlePageSizeChange,
                onChangePage:  handlePageChange,
                onChangeRowsPerPage:handlePageSizeChange,
                downloadOptions : {
                  filename: 'CartonStatus.csv',
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                  }
                }
 
              }}
              className="custom-table" 
            /></div></div>
        
)}

       </>) : (window.location.href = "#/login")}   
        </div>
        );
    }
    export default Carton_status  