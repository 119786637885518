import React, { useState,useEffect } from 'react';
import Axios from "axios";
import moment from 'moment-timezone';
import Select from "react-select";
import * as XLSX from 'xlsx';
import  secureLocalStorage  from  "react-secure-storage";
function Report_download() {
const [details,setDetails]=useState([]);
const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
const [order_id,setorderids]=useState([]);
const [selectedOrdermill, setSelectedOrdermill] = useState('');
const [selectedOrderstatuscode, setSelectedOrderstatuscode] = useState('');
const token = secureLocalStorage.getItem("token");
var date1=null;
var date2=null;
const [showFilters, setShowFilters] = useState(false);
const handleFilterClick = () => {
  setShowFilters(!showFilters);
}
const buttonStyle = showFilters ? 'line-button' : 'filter-button';
useEffect(() => {
  if (isLoggedIn){
Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,
{},{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  }).then((response) => {
    setorderids(response.data)
});
}
}, []);
const getdetails1 = (selectedOption) => {
    setSelectedOrdermill(selectedOption.value);
  }
  
const getdetails2 = (selectedOption) => {
    setSelectedOrderstatuscode(selectedOption.value);
  }
  // Define options for the Select component
const options1 = order_id.map((item) => ({
    label: item.mill,
    value: item.mill,
  }));
  const filteredOptions1 = options1.filter((options1, index, self) =>
  index === self.findIndex((t) => (
    t.value === options1.value && t.label === options1.label
  ))
  );
  const options2 = order_id.map((item) => ({
    label: item.statuscode,
    value: item.statuscode,
  }));
  const filteredOptions2 = options2.filter((options2, index, self) =>
  index === self.findIndex((t) => (
    t.value === options2.value && t.label === options2.label
  ))
);
const placeholderOption2 = { label: "Search Mill", value: null };
const placeholderOption3 = { label: "Search Statuscode", value: null };
const combo_filter = () => {

  Axios.post(`${process.env.REACT_APP_API_URL}/order/combo_filter_report`, {
    
    mill: selectedOrdermill,
    statuscode: selectedOrderstatuscode,
    
},{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  }).then((response) => {
    setDetails(response.data)
});
}
const format_date=(utcDate)=>{
  const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
  return istDate;
}
const clear_values = () => {
  setSelectedOrdermill(null);
  setSelectedOrderstatuscode(null);
  Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
  setorderids(response.data)
  });
}

function downloadTable() {
  // create a new workbook
  const workbook = XLSX.utils.book_new();

  // convert the table data to a worksheet
  const worksheet = XLSX.utils.table_to_sheet(document.querySelector('table'));

  // add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // generate a blob object containing the XLSX file data
  const blob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // create a temporary link element to trigger the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }));
  link.download = 'table.xlsx';

  // simulate a click event to trigger the download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  
}

  return (
    <div>
      {isLoggedIn ? ( <>
    <br/>
    <button  onClick={handleFilterClick} style={{marginLeft:'50px'}} className={buttonStyle}>Filter</button>
    {showFilters && (<div>
        <div style={{width:'80%',display:'flex', justifyContent:'space-evenly',marginLeft:'50px'}}>
    
    <Select className="select_ord" 
    value={ selectedOrdermill? { label: selectedOrdermill, value: selectedOrdermill } : null}
  options={[placeholderOption2, ...filteredOptions1]}
    onChange={getdetails1} placeholder="Search Mill"  />
    <Select className="select_ord" 
    value={ selectedOrderstatuscode? { label: selectedOrderstatuscode, value: selectedOrderstatuscode } : null}
    options={[placeholderOption3, ...filteredOptions2]}
     onChange={getdetails2} placeholder="Search Statuscode"  />
     </div>
     <br/>
     <button style={{float: 'right',marginRight:'60px'}} className="btn btn-primary" id="filter_button" onClick={combo_filter}>Apply</button>

     <button style={{float: 'right',marginRight:'40px'}} className="btn btn-primary" id="filter_button" onClick={clear_values}>Clear</button>
    
     <br/><br/>
     
<button className="lineb" style={{marginLeft:'50px'}}/></div>)}<table className="table container">
  <thead>
    <tr>
      <th>Order ID</th>
      <th>Mill</th>
      <th>Status Code</th>
      <th>Article</th>
      <th>Shade</th>
      <th>Order Quantity</th>
      <th>Due Date</th>
      
    </tr>
  </thead>
  <tbody>
    {details.map((item, index) => (
      <tr key={index}>
        <td>{item.sc_order_no}</td>
        <td>{item.mill}</td>
        <td>{item.statuscode}</td>
        <td>{item.article}</td>
        <td>{item.shade}</td>
        <td>{item.ord_qty}</td>
        <td>{format_date(item.due_date)}</td>
        
      </tr>
    ))}
  </tbody>
</table>
<button style={{float: 'right',marginRight:'50px'}} className="btn btn-primary"  onClick={downloadTable}>Download</button>
</>) : (window.location.href = "#/login")}  
    </div>
  )
}

export default Report_download