
import React from 'react'
import  secureLocalStorage  from  "react-secure-storage";
import * as XLSX from "xlsx";
import '../../routes/excel.css'
export default function Excel_download({data,file_name}) {
  const downloadFile = () => {
    // Create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert workbook to XLSX file
    const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // Create download link and click it
    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([xlsxFile], { type: 'application/octet-stream' }));
    link.setAttribute('download',file_name);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div data-cy="download-component">
    <p style={{textAlign: 'center',color:'black'}}>Download our example <u><a style={{color:'blue'}} onClick={downloadFile}>templates</a></u></p><br></br>
     
    </div>
  )
}

