import React, { useState,useEffect } from 'react';
import './master_data.css'
import { Link ,useParams} from 'react-router-dom';
import Axios from "axios";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from 'mdb-react-ui-kit';
export default function Master_data() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const location=useLocation();
  const [details,setDetails]=useState([]);
  const token = secureLocalStorage.getItem("token");
  const id = new URLSearchParams(location.search).get('id');  
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");
  const [selectedOption, setSelectedOption] = useState('');
  

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  }
  

  useEffect(()=>{
    if (isLoggedIn){
    const getMasterDetails=()=>{
     Axios.post(`${process.env.REACT_APP_API_URL}/material/edit_masterdata`,
     {
      id:id,
     },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      if (response !== undefined) {
        setDetails(response.data)
    } else {
        console.log('Response is undefined');
    }
     })
     .catch((error) => {
      console.error(error);
    });
   }
   getMasterDetails()
  }
 },[]);

 const Master_data_update = (e) => {
  e.preventDefault();
  const status = selectedOption !== '' ? selectedOption : details[0].status;
  const material_group = document.getElementById("mgroup").value;
  const material_code = document.getElementById("mcode").value;
  const material_desc = document.getElementById("mdesc").value;
  const material_uom = document.getElementById("muom").value;
  const material_sc = document.getElementById("msc").value;
  const material_wbc = document.getElementById("mwbc").value;
  
  if (!material_group || !material_code || !material_desc || !material_uom || !material_sc || !material_wbc) {
    window.alert("Please fill in all fields.");
    return;
  }
  
  Axios.post(`${process.env.REACT_APP_API_URL}/material/Update_Master`, {
    id:id,
    material_group: material_group,
    material_code : material_code,
    material_desc : material_desc,
    material_uom : material_uom,
    material_sc : material_sc,
    material_wbc : material_wbc,
    status: status
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
    if(response.data == "-10")
    {
      window.alert("Material code that you tried to update is alredy exist");
    }
    if(response.data == "20")
    {
      window.alert("Values updated");
      window.location.href = '#/Master';
      window.location.reload(true);
    }
  });
}

const Master_data_insert = (e) => {
  e.preventDefault();
  const material_group = input1;
  const material_code = input2;
  const material_desc = input3;
  const material_uom = input4;
  const material_sc = input5;
  const material_wbc = input6;
  
  if (!material_group || !material_code || !material_desc || !material_uom || !material_sc || !material_wbc || !selectedOption) {
    window.alert("Please fill in all fields.");
    return;
  }
  
  Axios.post(`${process.env.REACT_APP_API_URL}/material/Insert_Master`, {
    material_group: material_group,
    material_code : material_code,
    material_desc : material_desc,
    material_uom : material_uom,
    material_sc : material_sc,
    material_wbc : material_wbc,
    status : selectedOption
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
    if(response.data == "50")
    {
      window.alert("Article and shade already exist");
    }
    else
    {
      window.alert("New Data Inserted");
      window.location.href = '#/Master';
      window.location.reload(true);
    }
  });
};


  const inputStyle = { margin: '0 5px' };

  return (
    
    <div>
      {isLoggedIn ? ( <>
     {details.length >0 &&
      details.map((d) => ( 
            <div>

            <MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
            <MDBCardBody>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Material Group</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
            <input type="text" id="mgroup" style={{inputStyle}} defaultValue={d.material_group} placeholder={'Material_Group'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Material Code</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="mcode" style={{inputStyle}} defaultValue={d.material_code} placeholder={'Material_Code'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Material Desc</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="mdesc" style={{inputStyle}} defaultValue={d.material_desc} placeholder={'Material_Desc'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Material uom</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="muom" style={{inputStyle}} defaultValue={d.material_uom} placeholder={'Material_uom'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Material sc</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="msc" style={{inputStyle}} defaultValue={d.material_sc} placeholder={'Material_sc'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="cname">Material wbc</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="mwbc" style={{inputStyle}} defaultValue={d.material_wbc} placeholder={'Material_wbc'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              
            <div style={{ display: 'flex',marginLeft: '10px' }}>


            <MDBCol sm="3">
                <label htmlFor="cname">Material status</label>
              
            </MDBCol>

            <label>
            <input
            type="radio"
            name="status"
            value="Active"
            defaultChecked={d.status === 'Active'}
            onChange={handleOptionChange}
            />
            Active
            </label>

            <label style={{ marginLeft: '10px' }}>
            <input
            type="radio"
            name="status"
            value="Inactive"
            defaultChecked={d.status === 'Inactive'}
            onChange={handleOptionChange}
            />
            Inactive
            </label>
            </div>
            </MDBRow>       

            <button type="submit" className="btn btn-primary" onClick={Master_data_update} >
            Save
            </button>
            <Link to="/Master">
            <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
            </button>
            </Link>
            
            </MDBCardBody>
            </MDBCard> 
            </MDBCol>

            </div>
     ))}
     {details.length === 0 && 
     <div>

                <MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
              <MDBCardBody>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Material Group</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input1} placeholder={'Material_Group'} onChange={(e) => setInput1(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Material Code</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input2} placeholder={'Material_Code'} onChange={(e) => setInput2(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Material Desc</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input3} placeholder={'Material_Desc'} onChange={(e) => setInput3(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Material uom</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input4} placeholder={'Material_uom'} onChange={(e) => setInput4(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Material sc</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input5} placeholder={'Material_sc'} onChange={(e) => setInput5(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="cname">Material wbc</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input6} placeholder={'Material_wbc'} onChange={(e) => setInput6(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  
                <div style={{ display: 'flex',marginLeft: '10px' }}>

                
                <MDBCol sm="3">
                    <label htmlFor="cname">Material status</label>
                   
                </MDBCol>
                
      <label>
        <input
          type="radio"
          value="Active"
          checked={selectedOption === 'Active'}
          onChange={handleOptionChange}
        />
        Active
      </label>

      <label style={{ marginLeft: '10px' }}>
        <input
          type="radio"
          value="Inactive"
          checked={selectedOption === 'Inactive'}
          onChange={handleOptionChange}
        />
        Inactive
      </label>
      </div>
      </MDBRow>       
          
          <button type="submit" className="btn btn-primary" onClick={Master_data_insert} >
            Save
          </button>
          <Link to="/Master">
          <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
          </button>
          </Link>
                 
              </MDBCardBody>
            </MDBCard> 
          </MDBCol>
      
      </div>}
      </>) : (window.location.href = "#/login")}
    </div>
  );
}
