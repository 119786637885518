import React, { useState,useEffect } from 'react';
import Axios from "axios";
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import  secureLocalStorage  from  "react-secure-storage";
import { max } from 'moment-timezone';
export default function Glabel1() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const activeUser = secureLocalStorage.getItem("activeUser");
  const currentUser='SIRIUS'
    const [order_id,setorderids]=useState([]);
    const [details,setDetails]=useState([]);
    const [date, setDate] = useState("");
    const [maxqty,setmaxqty]=useState(0);
    const [lastOrderId, setLastOrderId] = useState(0);
    const [labelid_details,setlabeldetails]=useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOrderId1, setSelectedOrderId1] = useState(null);
    const [selectedOrderId2, setSelectedOrderId2] = useState(null);
    const [article,setSelectedArticle]=useState([]);
    const [selectedArticle, setArticle] = useState('');
    const dataObject = {};
    const [shade,setSelectedShade]=useState([]);
    const [selectedShade, setShade] = useState('');
    const [selectedShades, setSelectedShades] = useState([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [rows1, setRows] = useState([]);
    let total_count_sirius=0
    // var total_box=0
    let packer_count=0
    var label_type=""
    var res_data=0
    var qty=0
    const [depo,setSelectedDepo]=useState([]);
    const [selectedDepo, setDepo] = useState('');
    const mill = secureLocalStorage.getItem("mill");
    const token = secureLocalStorage.getItem("token");
    const [currentDateTime, setCurrentDateTime] = useState("");
    useEffect(() => {
      if (isLoggedIn){
        const getAllDetails = () => {
          if (mill == "COATS") {
          
            Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp1`,{
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              }).then((response) => {
             
            setorderids(response.data)
            });
            }
            else{
            Axios.post(`${process.env.REACT_APP_API_URL}/order/order_disp_vendor1`,{
            
             mill:mill,
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              }).then((response) => {
            setorderids(response.data)
            });
            }
          }
          getAllDetails()
      const today = new Date();
      const currentTime=today.toLocaleTimeString();

      const formattedDate = today.toISOString().substr(0, 10);
      setDate(formattedDate);
      const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  
  const formattedDateTime = `${year}-${month}-${day}`;
  setCurrentDateTime(formattedDateTime);
  Axios.post(`${process.env.REACT_APP_API_URL}/label/article_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setSelectedArticle(response.data)
  });
  Axios.post(`${process.env.REACT_APP_API_URL}/label/shade_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setSelectedShade(response.data)
  });
  Axios.post(`${process.env.REACT_APP_API_URL}/label/depo_disp`,{
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
      console.log(response.data)
      setSelectedDepo(response.data)
  });
  Axios.post(`${process.env.REACT_APP_API_URL}/order-log/fetch_last_order_id1`,{
    
},{
  headers: {
    'x-access-token': token,
    'Content-Type': 'application/json'}
  }).then((response) => {
 
 setLastOrderId(response.data[0].carton_no)
});
// if (mill == "COATS") {
//   Axios.post(`${process.env.REACT_APP_API_URL}/order/id_disp`,{
//   },{
//     headers: {
//       'x-access-token': token,
//       'Content-Type': 'application/json'}
//     }).then((response) => {
//    setorderids(response.data)
//   });
// }
//   else{
//     Axios.post(`${process.env.REACT_APP_API_URL}/order/id_disp_vendor`,{
      
//       uname:mill,
//   },{
//     headers: {
//       'x-access-token': token,
//       'Content-Type': 'application/json'}
//     }).then((response) => {
//   setorderids(response.data)
//   });
//   }
}
  // getdetails();
  }, []);
  const handleShadeChange = (selectedOptions) => {
      setSelectedShades(selectedOptions ? selectedOptions.map(option => option.value) : []);
  }
    var count=1

    const getdetails = async () => {
      
      setIsLoading(true);
      setmaxqty(0)
      if(selectedArticle ==='' && selectedShade ==='' && selectedDepo===''){
        window.alert("Select Article, Shade, Depo")
        window.location.reload(true);
      }
      console.log(selectedArticle?.value)
     const article_selected=selectedArticle?.value
     const shade_selected=selectedShade?.value
     const depo_selected=selectedDepo?.value
     let shadeParam;

if (activeUser === currentUser) {
  shadeParam = selectedShades;
} else {
  shadeParam = shade_selected;
}

     try {
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/label/show_order_details`, {
        article: article_selected,
        //shade: shade_selected,
        
        shade: shadeParam,
        rec: depo_selected,
        mill: mill,
        type:"Loose",
      }, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
  
      const fetchedDetails = response.data;
      setDetails(fetchedDetails);
  
      const newRows = await Promise.all(fetchedDetails.map(async (d, index) => {
        const quantity = await calc_quantity(d.ord_qty, d.article);
        const max_carton=quantity+((quantity*10)/100);
        let roundedMaxCarton;
if (max_carton - Math.floor(max_carton) > 0.5) {
  roundedMaxCarton = Math.ceil(max_carton);
} else {
  roundedMaxCarton = Math.floor(max_carton);
}

console.log(roundedMaxCarton)
console.log("qty",qty*roundedMaxCarton)
let max_qty=(qty*roundedMaxCarton)-d.label_count
        return (
          <tr> 
          <input type="hidden" id={'mill'+count} value={d.mill}></input>
          <input type="hidden" id={'pu_code'+count} value={d.pu_code}></input>
          <input type="hidden" id={'sc_order_no'+count} value={d.sc_order_no}></input>
          
          <input type="hidden" id={'pending_qty'+count} value={d.pendingqty}></input>
          <input type="hidden" id={'label_count'+count} value={d.label_count}></input>
          {/* <td><input type="text" id={'carton_no'+count} value={orderId1}></input></td>  */}
          <td><input type="text" id={'po_number'+count} value={d.po_number}></input></td>
         {/* <td><input type="hidden" id={'mill'+count} value={d.mill}></input></td>
         <td><input type="hidden" id={'pu_code'+count} value={d.pu_code}></input></td> */}
         <td><input type="text" id={'article'+count} value={d.article}></input></td>
         <td><input type="text" id={'shade'+count} value={d.shade}></input></td>
         <td><input type="text" id={'rec_plant'+count} value={d.rec_plant} ></input></td>
         <td><input type="text" id={'ord_qty'+count} value={d.ord_qty}></input></td>
       <td> <input type="text" id={'pending_qty'+count} value={d.pendingqty}></input></td>
       
       <td><input type="text" id={'max_qty'+count} value={Math.floor((d.ord_qty*130)/100)}></input></td>
         {/* <td><input type="number" id={'ord_qty1'+count} value={quantity}></input></td> */}
        
         {/* <td><input type="text" id={'packer_id'+count}  onLoad={count=count+1}></input></td> */}
         <td><input type="text" id={'box_qty'+count}  onLoad={count=count+1}></input></td>
         
         {/* <td><input type="text" id={'max_carton'+count}  value={roundedMaxCarton} onLoad={count=count+1}></input></td> */}
         
       </tr>
        );
      }));
  
      setRows(newRows);
    } catch (error) {
      console.error('Error fetching details:', error);
    }
    setIsLoading(false); 

    }

    const fetchDataAndCalculate = async (article) => {
   const response= await Axios.post(`${process.env.REACT_APP_API_URL}/label/box_calc`, {
        article:article,
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         })
        console.log(response.data[0].at_cc)
        res_data=response.data[0].at_cc
        if(res_data==0)
        {
         console.log(res_data)
       return null;
     }
      else{
      
        console.log(res_data)
      return res_data;
       }
    }  

      //  const ord_qtyfunc = async (sc_order_no) => {
      //   const response= await Axios.post(`${process.env.REACT_APP_API_URL}/label/ord_count`, {
      //        sc_order_no:sc_order_no,
      //       },{
      //         headers: {
      //           'x-access-token': token,
      //           'Content-Type': 'application/json'}
      //         })
      //        console.log(response.data[0].ord_qty)
      //        const qty=response.data[0].ord_qty
           
      //        console.log("order quty",qty)
            
      //      return qty;
            
      //    }
    const calc_quantity= async (order_qty,article)=>{
      const response= await Axios.post(`${process.env.REACT_APP_API_URL}/label/box_calc`, {
        article:article,
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         })
        console.log(response)
        qty=response.data[0].at_cc
        if(qty==0)
        {
         console.log(qty)
       return null;
     }
      else{
       setmaxqty(qty)
       const box=order_qty/qty;
       console.log("qty",qty)
       console.log(box)
       let box1;
       console.log(box - Math.floor(box))
       if (box - Math.floor(box) > 0.5) {
         box1 = Math.ceil(box);
       } else {
         box1 = Math.floor(box);
       }

        console.log(box1)
      return box1;
       }
    }
    const generatelabel = async (e) => {
      e.preventDefault();
      setButtonDisabled(true);
      setIsLoading(true);
    
      if (details.length === 0) {
        window.alert("Select Labels");
        setButtonDisabled(false);
        setIsLoading(false);
        return;
      }
     
      const packer_id = document.getElementById('packer_id').value;
      if ( !packer_id) {
        window.alert("Please fill Packer ID");
        setButtonDisabled(false);
        setIsLoading(false);
        return;
      }
      if (packer_id) {
        try {
          const response = await Axios.post(`${process.env.REACT_APP_API_URL}/label/packer_id_check`, { mill: mill }, {
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'
            }
          });
    
          console.log("mill", mill);
          console.log("packer id", packer_id);
          console.log("packer id res", response.data);
    
          if (mill === "COATS") {
            if (packer_id !== "madura_admin" && packer_id !== "admin_healer") {
              console.log("packer_id!=madura_admin && packer_id!=admin_healer");
              window.alert("Wrong Packer ID");
              setButtonDisabled(false);
              setIsLoading(false);
              return;
            }
          } else {
            if (packer_id !== response.data[0].uname) {
              window.alert("Wrong Packer ID");
              setButtonDisabled(false);
              setIsLoading(false);
              return;
            }
          }
  
    let ccount=0
          for(let i = 1;i <= details.length;i++){
  
            // const carton_no=document.getElementById('carton_no'+i).value;
            
            const box_qty = document.getElementById('box_qty'+i).value;
            const  sc_order_no=document.getElementById('sc_order_no'+i).value;
            const po_number=document.getElementById('po_number'+i).value;
            const  mill=document.getElementById('mill'+i).value;
            const  pu_code=document.getElementById('pu_code'+i).value;
            const article=document.getElementById('article'+i).value;
            const  shade=document.getElementById('shade'+i).value;
            const rec_plant=document.getElementById('rec_plant'+i).value;
            const fullqty=document.getElementById('ord_qty'+i).value;
            const pending_qty=document.getElementById('pending_qty'+i).value;
            console.log("fullqty",fullqty)
            // const maximum_carton=document.getElementById('max_carton'+i).value;
            const total_box= await fetchDataAndCalculate(article);
            var label_count= document.getElementById('label_count'+i).value;
        
            const quantity=document.getElementById('ord_qty'+i).value;
            console.log("qty",quantity)
            const percentage=(Number(quantity)*130)/100;
            console.log(percentage)
            console.log("box+ordeder",Number(box_qty)+Number(label_count))
            const numericInput = /^[0-9]*$/;
      
            // Display alerts and return as needed
            if (!numericInput.test(String(box_qty))) {
              window.alert("Please enter number in Quantity");
              setButtonDisabled(false);
              setIsLoading(false);
              return;
            }
    
            if (Number(box_qty)>0 && (Number(box_qty) + Number(label_count)) > percentage) {
              console.log("box+ordeder", Number(box_qty) + Number(label_count));
              console.log(percentage);
              window.alert("Wrong Box Quantity for "+po_number);
              setButtonDisabled(false);
              setIsLoading(false);
              return;
            }
        if(total_box == null)
        {
          window.alert("Null in at_acc")
          setButtonDisabled(false);
          setIsLoading(false);
         return
        }
        if(Number(box_qty)>0)
         ccount=ccount+1
        if(activeUser=='SIRIUS' && ccount>6)
        {
          window.alert("Maximum of 6 shades is allowed")
          setButtonDisabled(false);
          setIsLoading(false);
          return
        }
          if(activeUser=='SIRIUS')
        {
          console.log("total_count_sirius",total_count_sirius)
          console.log("box_qty",box_qty)
         total_count_sirius=total_count_sirius+Number(box_qty)
         if(total_count_sirius>Number(total_box))
         {
          window.alert("Wrong Box Quantity")
          setButtonDisabled(false);
          setIsLoading(false);
          return
         }
          if(Number(box_qty)>Number(pending_qty))
          {
          window.alert("Wrong Box Quantity")
          setButtonDisabled(false);
          setIsLoading(false);
          return
          }
        }
        if(Number(box_qty) > Number(total_box))
        {
         window.alert("Wrong Box quantity")
         setButtonDisabled(false);
         setIsLoading(false);
        return;
        }
      
  //   if ( !box_qty) {
  //    window.alert("Please fill Box Quantity");
  //    setButtonDisabled(false);
  //    setIsLoading(false);
  //    return;
  //  }

 
   else{
    if(box_qty!=0 && box_qty!=undefined && box_qty!=null){
console.log("order id",sc_order_no)
if(fullqty!==pending_qty && label_count==0)
label_count=Number(fullqty)-Number(pending_qty)+Number(box_qty)
else
label_count=Number(label_count)+Number(box_qty)
    var cones=pending_qty
  var pendingqty=pending_qty-(box_qty)
  // if( pendingqty <0)
  // pendingqty=0
    // dataObject[i] = {carton_no,packer_id, sc_order_no,mill,pu_code,article,shade,rec_plant,fullqty,currentDateTime,pendingqty,label_type,cones,res_data,box_qty,po_number};
    dataObject[i] = {packer_id, sc_order_no,mill,pu_code,article,shade,rec_plant,fullqty,currentDateTime,pendingqty,cones,res_data,box_qty,po_number,label_count};
    
}
}
          }
    
       
          const jsonData = JSON.stringify(dataObject);
          Axios.post(`${process.env.REACT_APP_API_URL}/label/glabel_loose`, { body: jsonData,mill:mill }, {
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            console.log(response.data);
            window.alert("Label Generated " + JSON.stringify(response.data));
            window.location.reload(true);
          }).catch((err) => {
            console.log(err);
            window.alert(err);
          });
    
        } catch (error) {
          console.error(error);
          window.alert("Error checking packer ID");
          setButtonDisabled(false);
          setIsLoading(false);
          return;
        }
      }
    };
    
//   const generatelabel = async(e) => {
//      e.preventDefault();
//      setButtonDisabled(true);
//      setIsLoading(true);
//      if(details.length===0)
//      {
//       window.alert("Select Labels")
//       setButtonDisabled(false);
//       return;
//      }
//      const packer_id = document.getElementById('packer_id').value;
//      if(packer_id)
//      {
//        Axios.post(`${process.env.REACT_APP_API_URL}/label/packer_id_check`, {
//          mill:mill,
//         },{
//           headers: {
//             'x-access-token': token,
//             'Content-Type': 'application/json'}
//           }).then((response) => {
//            console.log("mill",mill)
//            console.log("packer id",packer_id)
//            console.log("packer id res",response.data)
//            if(mill=="COATS"){
//            if(packer_id!=="madura_admin" && packer_id!=="admin_healer")
//            {
//             window.alert("Wrong Packer ID");
//             packer_count=1
//             setButtonDisabled(false);
//             setIsLoading(false);
//             return;
//            }
//            }else{
//            if(packer_id!=response.data[0].uname)
//            {
//            window.alert("Wrong Packer ID");
//            packer_count=1
//            setButtonDisabled(false);
//            setIsLoading(false);
//            return;
//            }}
//           });
//      }
//     for(let i = 1;i <= details.length;i++){
  
//       // const carton_no=document.getElementById('carton_no'+i).value;
      
//       const box_qty = document.getElementById('box_qty'+i).value;
//       const  sc_order_no=document.getElementById('sc_order_no'+i).value;
//       const po_number=document.getElementById('po_number'+i).value;
//       const  mill=document.getElementById('mill'+i).value;
//       const  pu_code=document.getElementById('pu_code'+i).value;
//       const article=document.getElementById('article'+i).value;
//       const  shade=document.getElementById('shade'+i).value;
//       const rec_plant=document.getElementById('rec_plant'+i).value;
//       const fullqty=document.getElementById('ord_qty'+i).value;
//       const pending_qty=document.getElementById('pending_qty'+i).value;
//       console.log("fullqty",fullqty)
//       // const maximum_carton=document.getElementById('max_carton'+i).value;
//       const total_box= await fetchDataAndCalculate(article);
//       var label_count= document.getElementById('label_count'+i).value;
//       const quantity=document.getElementById('ord_qty'+i).value;
//       console.log("qty",quantity)
//       const percentage=(Number(quantity)*130)/100;
//       console.log(percentage)
//       console.log("box+ordeder",Number(box_qty)+Number(label_count))
//       const numericInput = /^[0-9]*$/;

//       if (!numericInput.test(String(box_qty))) {
//         window.alert("Please enter number in Quantity");
//         setButtonDisabled(false);
//         setIsLoading(false);
//         return;
//       }
//    if((Number(box_qty)+Number(label_count))>percentage)
//    {
//     console.log("box+ordeder",Number(box_qty)+Number(label_count))
//     console.log(percentage)
//     window.alert("Wrong Box quantity")
//     setButtonDisabled(false);
//     setIsLoading(false);
//    return;
//    }
//       // console.log("console func",fetchDataAndCalculate(article,fullqty));
//       console.log("tot box",total_box)
//         if(total_box == null)
//         {
//           window.alert("Null in at_acc")
//           setButtonDisabled(false);
//           setIsLoading(false);
//          return
//         }
//         if(box_qty > total_box)
//         {
//          window.alert("Wrong Box quantity")
//          setButtonDisabled(false);
//          setIsLoading(false);
//         return;
//         }
//     if ( !box_qty) {
//      window.alert("Please fill Box Quantity");
//      setButtonDisabled(false);
//      setIsLoading(false);
//      return;
//    }
//    if ( !packer_id) {
//     window.alert("Please fill Packer ID");
//     setButtonDisabled(false);
//     setIsLoading(false);
//     return;
//   }
 
//    else{
//     if(box_qty!=0){
// console.log("order id",sc_order_no)
// label_count=Number(label_count)+Number(box_qty)
//     var cones=pending_qty
//   var pendingqty=pending_qty-(box_qty)
//   // if( pendingqty <0)
//   // pendingqty=0
//     // dataObject[i] = {carton_no,packer_id, sc_order_no,mill,pu_code,article,shade,rec_plant,fullqty,currentDateTime,pendingqty,label_type,cones,res_data,box_qty,po_number};
//     dataObject[i] = {packer_id, sc_order_no,mill,pu_code,article,shade,rec_plant,fullqty,currentDateTime,pendingqty,cones,res_data,box_qty,po_number,label_count};
    
// }
// }
//   } 
//   const jsonData = JSON.stringify(dataObject);
//   Axios.post(`${process.env.REACT_APP_API_URL}/label/glabel_loose`, {
//     body: jsonData,
    
//   },{
//     headers: {
//       'x-access-token': token,
//       'Content-Type': 'application/json'}
//     }).then((response) => {
//      console.log(response.data)
//     window.alert("Label Generated "+ JSON.stringify(response.data));
//      window.location.reload(true);
    

// }).catch((err)=>{
//   console.log(err)
//   window.alert(err)
// });      
//   };

  
   // Define options for the Select component
   const options = order_id.map((item) => ({
    label: item.sc_order_no,
    value: item.sc_order_no,
}));
const options1_article = article.map((item) => ({
  label: item.material_code,
  value: item.material_code,
}));
const options1 = options1_article.filter((options1_article, index, self) =>
  index === self.findIndex((t) => (
    t.value === options1_article.value && t.label === options1_article.label
  ))
);
// const options2 = shade.map((item) => ({
//   label: item.material_code,
//   value: item.material_code,
// }));
const options_shade = order_id.map((item) => ({
  label: item.shade,
  value: item.shade,
}));
const options2 = options_shade.filter((options_shade, index, self) =>
  index === self.findIndex((t) => (
    t.value === options_shade.value && t.label === options_shade.label
  ))
);
const options3 = depo.map((item) => ({
  label: item.depo_name,
  value: item.depo_name,
}));

const clear_values =() => {
  setArticle('');
  setShade('');
  setDepo('');
  setSelectedShades([]);
}
const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '150px', 
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '150px',
  }),
};
 
  return(
    <div>
       {isLoggedIn ? ( <>
      <h3 style={{marginLeft:"100px"}} data-cy="order_label">Generate Loose Label</h3>
     
        <div style={{ display:"flex", marginLeft:"100px", marginTop: "20px"}}>
   
     <div style={{ display:"flex", marginLeft:"50px"}}>
      <Select
        options={options1}
        placeholder="Select Article"
        value={selectedArticle}
        onChange={setArticle}
        isSearchable={true}
        styles={customStyles}
      />
    </div>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <>
      {activeUser === "SIRIUS" ? (
        <Select
        options={options2}
        value={selectedShades.map(option => ({ label: option, value: option }))}
        onChange={handleShadeChange}
        isSearchable={true}
        placeholder={"Select Shade"}
        isMulti={true}
        styles={customStyles}
      />
       
      ) : (
        <Select
        options={options2}
        placeholder="Select Shade"
        value={selectedShade}
        onChange={setShade}
        isSearchable={true}
        styles={customStyles}
      />
      )}
    </>
      </div>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Select
        options={options3}
        placeholder="Select Depo"
        value={selectedDepo}
        onChange={setDepo}
        isSearchable={true}
        styles={customStyles}
      />
       
      </div>
      <div style={{ display:"flex", marginRight:"50px",justifyContent:"flex-end"}}>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Button variant="primary" onClick={getdetails}>Show Details</Button>
      </div>
      <div style={{ display:"flex", marginLeft:"50px"}}>
      <Button variant="primary" onClick={clear_values}>
        Clear
      </Button>
      </div>
      
      </div>
      </div>
      <br/>
     
     <br/>
      {details.length > 0 ? (
   <table className="table container" id="tb1">
   <thead>
   <tr><td><p>Max. Carton Qty</p></td><td>
  <input type="text" value={maxqty} ></input></td>
    <td><p>Packer ID <span style={{ color: 'red' }}>*</span></p></td><td>
  <input type="text" id={'packer_id'} ></input></td></tr>
     <tr>
        {/* <th scope="col">carton_no</th> */}
        <th scope="col">PO Number</th>
       {/* <th scope="col">mill</th>
       <th scope="col">Production Unit</th> */}
       <th scope="col">Article</th>
       <th scope="col">Shade</th>
       <th scope="col">rec_plant</th>
       {/* <th scope="col">Order quantity</th> */}
       <th scope="col">OrderQuantity</th> 
       <th scope="col">Pending Quantity</th>
       <th scope="col">Maximum Quantity</th>
       <th scope="col">Quantity</th>
       
       {/* <th scope="col">packer id</th> */}
       {/* <th scope="col">Maximum Cartons </th> */}
        
     </tr>
   </thead>
   <tbody>
            {rows1}
            <tr><td colSpan="8" align="right"> <div>
            <Button variant="primary" onClick={generatelabel} disabled={isButtonDisabled}>Generate Label</Button>
         </div></td></tr>
          
              </tbody>
              
      </table> 
 ) : (
  <p></p>
)}
      <table><tr>

      {labelid_details.map((item,i)=>(
    <td>Label id Generated are :{item.carton_no} || </td>
      ))}

      </tr>
      </table>

      <br /><br />

     
      </>) : (window.location.href = "#/login")}  
      {isLoading && (
      <div className="loading-overlay">
        <div className="loading-icon"></div>
      </div>
    )}
   </div>
   
  );
};





