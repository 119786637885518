import React, { useState,useEffect } from 'react';
import Axios from "axios";
import * as XLSX from "xlsx";
import './excel.css'
import  secureLocalStorage  from  "react-secure-storage";
import Excel_component from '../components/excel/excel_component';
import Excel_download from '../components/excel/excel_download';
// drag drop file component
export default function Orders() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const [items, setItems] = useState([]);
  const [companies,setCompanies]=useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(1);
  const [lastOrderId, setLastOrderId] = useState(0);
  const token = secureLocalStorage.getItem("token");
  const username=secureLocalStorage.getItem("activeUser");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const validated_mcodes = new Set();

  const dataObject = {};
  var count=0;
  var error_count=0;
 var error_text="";
  var str_count=0;
  var fname="";
  var ddate;
  var mill_map = new Map();
  var pu_map = new Map();
  var article_map = new Map();
  var rec_map = new Map();
  const columns= [
    ['PoNumber','Vendor_Code','Plant','Material','Quantity','RecPlant','SAP_Order_Date'],
    
  ];
  const file_name="order.xlsx";
  const handleUpload = (details) => {
    fname=details.name;
    readExcel(details);
  };


 
  const order = async (e) => {
    setIsLoading(true);
    const executor= secureLocalStorage.getItem("mill");
    e.preventDefault();
    setButtonDisabled(true);
    const today = new Date();
    for (let i = 0; i < items.length; i++) {
        const po_num = document.getElementById('po'+i).value;
        // const oid = document.getElementById('oid'+i).value;
      const material = document.getElementById('material'+i).value;
      const mill = document.getElementById('mill'+i).value
      const pucode=document.getElementById("pucode"+i).value;
      const orddate = document.getElementById('orddate'+i).value
      const recplant=document.getElementById("recplant"+i).value;
      const quantity = document.getElementById('quantity'+i).value;
      const sapdate=document.getElementById('sapdate'+i).value;
      const uname= username;
      const [day, month, year] = sapdate.split('.');
       const outputDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      var material_article=material.substring(0, material.indexOf("-"));
      var material_shade=material.substring(material.indexOf('-') + 1);
      if(material_article=='')
      {
        material_article=material_shade
        material_shade=''
      }
      dataObject[i] = { po_num,material_article,mill,pucode,orddate,recplant,material_shade,quantity,uname,material,outputDate};
    }
    try{
      const jsonData = JSON.stringify(dataObject);
      const order_result=await Axios.post(`${process.env.REACT_APP_API_URL}/order/check_id_mill`, {
        body: jsonData,
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         });
       console.log("order_result.data.length",order_result.data.length)
       if(order_result.data.length!==0)
       {
        window.alert("Follwing are the Duplicate POs "+JSON.stringify(order_result.data))
        setIsLoading(false);
        setButtonDisabled(false);
       }
      else{
        try{
          const jsonData = JSON.stringify(dataObject);
        const order_result=await Axios.post(`${process.env.REACT_APP_API_URL}/order/order_place`, {
          body: jsonData,
          cname:executor,
         },{
           headers: {
             'x-access-token': token,
             'Content-Type': 'application/json'}
           });
           if(order_result){
            window.alert("Your orders are placed");
             window.location.href = `#/filter_component`;
             window.location.reload(true);
            }
       } catch (error) {
         console.log(error);
       }
      }
    }catch (error) {
     console.log(error);
   }
  
   
  };

 
  const readExcel = async (file) => {
    setIsLoading(true);
  
    try {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
  
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
  
          const wb = XLSX.read(bufferArray, { type: "buffer" });
  
          const wsname = wb.SheetNames[0];
  
          const ws = wb.Sheets[wsname];
  
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        };
        
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      function containsOnlyNumbers(str) {
                return /^[0-9]+$/.test(str);
              }

    
      const data = await promise;
      if (data.length == 0) {
        window.alert('The uploaded Excel file is empty');
        window.location.reload(true);
        }
      for(let k=0;k<data.length;k++) {
        let line_index=k+2;
        var response1="";
        var response2="";
        var response4="";
        // var mcode=data[k].Article+"-"+data[k].Shade;
      var mcode1=data[k].Material
      var mcode1_as_string=mcode1.toString();
      var mcode = mcode1_as_string.substring(0, mcode1_as_string.indexOf("-"));
      if(mcode=='')
      mcode=mcode1
      console.log("mcode",mcode);
        if(data[k].Vendor_Code == 0)
        {
          error_count += 1;
          error_text += "Line = " + line_index + ", Column = Vendor_Code: Vendor_Code is Invalid\n";
          console.log(error_text)
        }
        if(line_index==2)
        {
          response1 = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {
              mill: data[k].Vendor_Code,
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              });
              mill_map.set(data[k].Vendor_Code, response1.data.length);
              if(response1.data.length==0){
              error_count += 1;
              error_text += "Line = " + line_index + ", Column = Vendor_Code: Vendor_Code is Invalid\n";}
        }
        else{
          if( mill_map.get(data[k].Vendor_Code)!= null)
          {
            if( mill_map.get(data[k].Vendor_Code)==0)
            {
              error_count += 1;
              error_text += "Line = " + line_index + ", Column = Vendor_Code: Vendor_Code is Invalid\n";}
            }
            else{
              response1 = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {
                mill: data[k].Vendor_Code,
              },{
                headers: {
                  'x-access-token': token,
                  'Content-Type': 'application/json'}
                });
                mill_map.set(data[k].Vendor_Code, response1.data.length);
                if(response1.data.length==0){
                error_count += 1;
                error_text += "Line = " + line_index + ", Column = Vendor_Code: Vendor_Code is Invalid\n";}
            }
          } 
     
        if(data[k].Plant==0){
          error_count += 1;
          error_text += "Line = " + line_index + ", Column = Plant : No such Production Unit for the Vendor_Code\n";
          console.log(error_text)
        }
        if(line_index==2)
        {
          response2 = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {

              mill:data[k].Vendor_Code,
              pu_code:data[k].Plant,
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              });
              pu_map.set(data[k].Plant, response2.data.length);
              console.log(data[k].Plant)
              console.log(response2.data.length)
              if(response2.data.length==0){
              error_count += 1;
              error_text += "Line = " + line_index + ", Column = Plant : No such Production Unit for the Vendor_Code\n";
            }
        }
        else{
          if( pu_map.get(data[k].Plant)!= null)
          {
            if(data[k-1].Vendor_Code){
            if( pu_map.get(data[k].Plant)==0 && data[k].Vendor_Code==data[k-1].Vendor_Code)
            {
             
              error_count += 1;
              error_text += "Line = " + line_index + ", Column = Plant : No such Production Unit for the Vendor_Code\n";
           }
           else if( pu_map.get(data[k].Plant)==0 && data[k].Vendor_Code!=data[k-1].Vendor_Code){
           
            response2 = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {

              mill:data[k].Vendor_Code,
              pu_code:data[k].Plant,
            },{
              headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'}
              });
              console.log(data[k].Plant)
              console.log(response2.data.length)
              pu_map.set(data[k].Plant, response2.data.length);
              if(response2.data.length==0){
              error_count += 1;
              error_text += "Line = " + line_index + ", Column = Plant : No such Production Unit for the Vendor_Code\n";
            }
          }
          }
          }
            else{
             
              response2 = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {

                mill:data[k].Vendor_Code,
                pu_code:data[k].Plant,
              },{
                headers: {
                  'x-access-token': token,
                  'Content-Type': 'application/json'}
                });
              
                pu_map.set(data[k].Plant, response2.data.length);
                if(response2.data.length==0){
                error_count += 1;
                error_text += "Line = " + line_index + ", Column = Plant : No such Production Unit for the Vendor_Code\n";
              }
            }
          }
      if (!validated_mcodes.has(mcode)) {
        try {
            console.log(`Validating mcode: ${mcode} at line ${line_index}`);
            const response = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {
                mcode: mcode,
            }, {
                headers: {
                    'x-access-token': token,
                    'Content-Type': 'application/json'
                }
            });

            article_map.set(mcode, response.data);
            validated_mcodes.add(mcode);

            if (response.data.length == 0 || mcode.length < 7) {
                error_count += 1;
                error_text += `Line = ${line_index}, Column = Material : Material is Invalid\n`;
            } else if (response.data[0].status === "Inactive") {
                error_count += 1;
                error_text += `Line = ${line_index}, Column = Material : Material Code is currently Inactive\n`;
            }
        } catch (error) {
            error_count += 1;
            error_text += `Line = ${line_index}, Column = Material : Error during validation\n`;
            console.error(`Error validating mcode: ${mcode}`, error);
        }
    } else {
        const cachedResponse = article_map.get(mcode);
        if (cachedResponse.length == 0 || mcode.length < 7) {
            error_count += 1;
            error_text += `Line = ${line_index}, Column = Material : Material is Invalid\n`;
        } else if (cachedResponse[0].status === "Inactive") {
            error_count += 1;
            error_text += `Line = ${line_index}, Column = Material : Material Code is currently Inactive\n`;
        }
    }



      if(containsOnlyNumbers(data[k].Quantity)== false)
      {
        error_count += 1;
        error_text += "Line = " + line_index + ", Column = Quantity : Quantity should contain only Numbers\n";
        console.log(error_text)
      }
          //rec plant validation
          if(data[k].RecPlant==0){
            error_count += 1;
            error_text += "Line = " + line_index + ", Column = RecPlant : RecPlant is Invalid\n";
            console.log(error_text)
          }
          if(line_index==2)
          {
            response4 = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {
                 recplant:data[k].RecPlant,
               },{
                 headers: {
                   'x-access-token': token,
                   'Content-Type': 'application/json'}
                 });
                 rec_map.set(data[k].RecPlant, response4.data.length);
                 if(response4.data.length==0){
                 error_count += 1;
                 error_text += "Line = " + line_index + ", Column = RecPlant : RecPlant is Invalid\n";
          }}
          else{
            if( rec_map.get(data[k].RecPlant)!= null)
            {
              if( rec_map.get(data[k].RecPlant)==0)
              {
                error_count += 1;
                error_text += "Line = " + line_index + ", Column = RecPlant : RecPlant is Invalid\n";
              }
              }
              else{
                response4 = await Axios.post(`${process.env.REACT_APP_API_URL}/order/validate_order`, {
                  recplant:data[k].RecPlant,
                },{
                  headers: {
                    'x-access-token': token,
                    'Content-Type': 'application/json'}
                  });
                  rec_map.set(data[k].RecPlant, response4.data.length);
                  if(response4.data.length==0){
                  error_count += 1;
                  error_text += "Line = " + line_index + ", Column = RecPlant : RecPlant is Invalid\n";
              }
            } }
    }
      if(error_count > 0) {
        const sts="Failed"
        
                   Axios.post(`${process.env.REACT_APP_API_URL}/upload/ordstatus_insert`, {

            cname:data[0].Vendor_Code,
            view:error_text,
            no_of_errors:error_count,
            uploaded_date:currentDateTime,
            status:sts,
            file_name:fname,
          },{
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'}})
        document.getElementById('tb1').style.visibility = "hidden";
        window.alert("Invalid contents in the file.. Please upload the correct file \n" + error_text);
        window.location.reload(true);
      }
      else {
        const sts="Success"
       
                 Axios.post(`${process.env.REACT_APP_API_URL}/upload/ordstatus_insert`, {

            cname:data[0].Vendor_Code,
            view:error_text,
            no_of_errors:error_count,
            uploaded_date:currentDateTime,
            status:sts,
            file_name:fname,
          },{
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'}})
        document.getElementById("tb1").removeAttribute("hidden");
        document.getElementById('tb1').style.visibility = "visible";
      }
  
      setItems(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  // date 


  const [date, setDate] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  useEffect(() => {
    if (isLoggedIn){
    const today = new Date();
    const currentTime=today.toLocaleTimeString();

    const formattedDate = today.toISOString().substr(0, 10);
    setDate(formattedDate);
    const year = today.getFullYear();
const month = (today.getMonth() + 1).toString().padStart(2, '0');
const day = today.getDate().toString().padStart(2, '0');
const hours = today.getHours().toString().padStart(2, '0');
const minutes = today.getMinutes().toString().padStart(2, '0');
const seconds = today.getSeconds().toString().padStart(2, '0');

const formattedDateTime = `${year}-${month}-${day} `;
setCurrentDateTime(formattedDateTime);
  }
}, []);
  // useEffect(() => {
  //   const today = new Date();
  //   const formattedDate = today.toISOString().substr(0, 10);
  //   setDate(formattedDate);
  // }, []);

 // Map over the orders array and generate a row for each order
 const rows = items.map((d, index) => {
  
  // Render the row with the order details and the generated order ID
  return (
      <tr> 
        <td><input type="text" id={"po"+count} value={d.PoNumber} readOnly disabled></input></td>
      {/*  <td><input type="text" data-testid="oid" data-cy="oid" id={"oid"+count}  value={orderId1} readOnly disabled ></input></td>*/}
        <td><input type="text" data-cy="mill" id={"mill"+count} value={d.Vendor_Code}  readOnly disabled></input></td>  
        <td><input type="text" data-cy="pucode" id={"pucode"+count} value={d.Plant}  readOnly disabled></input></td>  
       <td><input type="text" data-cy="material" id={"material"+count} value={d.Material}  readOnly disabled></input></td>
       {/* <td><input type="text" data-cy="shade" id={"shade"+count} value={d.Shade} readOnly disabled></input></td> */}
      <td><input type="text" data-cy="orddate" id={"orddate"+count} value={currentDateTime}  readOnly disabled></input></td>
       <td><input type="number" data-cy="quantity" id={"quantity"+count} value={d.Quantity}  disabled></input></td>
       <td><input type="text" data-cy="recplant" id={"recplant"+count} value={d.RecPlant} disabled></input></td>  
       <td><input type="text" id={"sapdate"+count} value={d.SAP_Order_Date} readOnly onLoad={count=count+1} disabled></input></td>
  </tr>
  )
});

  return (

    <div>
 {isLoggedIn ? ( <>        
      <br/>
      <h3 style={{textAlign: 'center'}} data-cy="order_label">Place Order</h3>
      <Excel_component onUpload={handleUpload}/>
      <Excel_download data={columns} file_name={file_name}></Excel_download>
    <br/>
    <div>

    
    {isLoading && (
      <div className="loading-overlay">
        <div className="loading-icon"></div>
      </div>
    )}
  </div>
  
    <div>
    <table data-cy='order-table' className="table container" id="tb1" hidden="hidden">
        <thead>
          <tr>
          <th scope="col">PO Number</th>
           {/*  <th scope="col">Order Id</th> */} 
            <th scope="col">Vendor_Code</th>
            <th scope="col">PU Code</th>
            <th scope="col">Material</th>
            {/* <th scope="col">Shade</th> */}
            <th scope="col">Order Date</th>
            <th scope="col">Order Quantity</th>
            <th scope="col">Rec Plant</th>
            <th scope="col">SAP Order Date</th>
          </tr>
        </thead>
        
          {/* {items.length >0 ? */}
          <tbody>
           
             {rows }
             <tr><td colSpan="9" align="right"> <div><button type="submit" data-cy="order_button" data-testid="placeorder" className="btn btn-primary" disabled={isButtonDisabled} onClick={order}>
            Place Order
          </button></div></td></tr>
           </tbody>
       {/* :  <tbody> {options} </tbody> } */}

      </table> </div>
      </>) : (window.location.href = "#/login")}
    </div>
  );
};


