import React, { useState,useEffect } from 'react';
import './master_data.css'
import { Link ,useParams} from 'react-router-dom';
import Axios from "axios";
import Select from "react-select";
import { useLocation } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody
} from 'mdb-react-ui-kit';
export default function Production_data() {
  const token = secureLocalStorage.getItem("token");
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const location=useLocation();
  const [details,setDetails]=useState([]);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const id = new URLSearchParams(location.search).get('id');
  const[sc_vendor,setscvendor]=useState([]);  
  const[sc_vendor1,setscvendor1]=useState([]);  
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");
  const [input8, setInput8] = useState("");
  const [input9, setInput9] = useState("");
  const [input10, setInput10] = useState("");
  const [input11, setInput11] = useState("");
  const [input12, setInput12] = useState("");
  const [input13, setInput13] = useState("");
  const [input14, setInput14] = useState("");
  const[selectedStatus,setSelectedStatus]=useState("");
  const [scVendor, setScVendor] = useState("");
  function containsOnlychar(str) {
    return /^[a-zA-Z]{1,4}$/
    .test(str);
  }

 
  

 const handleSelectChange = (selectedOption) => {

    setScVendor(selectedOption.value);
  };



  useEffect(()=>{
    if (isLoggedIn){
    Axios.post(`${process.env.REACT_APP_API_URL}/vendor/scvendor_disp`,
    {
     
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response1) => {
      setscvendor(response1.data)
    })
    const today = new Date();
    const currentTime=today.toLocaleTimeString();

   
    const year = today.getFullYear();
const month = (today.getMonth() + 1).toString().padStart(2, '0');
const day = today.getDate().toString().padStart(2, '0');
const hours = today.getHours().toString().padStart(2, '0');
const minutes = today.getMinutes().toString().padStart(2, '0');
const seconds = today.getSeconds().toString().padStart(2, '0');

const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
setCurrentDateTime(formattedDateTime);
    
     Axios.post(`${process.env.REACT_APP_API_URL}/prod-unit/edit_proddata`,
     {
      id:id,
     },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      
      if (response !== undefined) {
        setDetails(response.data)
        setSelectedStatus(response.data[0].sc_vendor)
      
        setScVendor(response.data[0].sc_vendor)
    } else {
        console.log('Response is undefined');
    }
     })
     .catch((error) => {
      console.error(error);
    });
  }
 },[]);

 const Prod_data_update = (e) => {
  e.preventDefault();
  
  const pu_code = document.getElementById("pu_code").value;
  const pu_name = document.getElementById("pu_name").value;
  const pu_doccode = document.getElementById("pu_doccode").value;
  const pu_address = document.getElementById("pu_address").value;
  const pu_contact_person = document.getElementById("pu_contact_person").value;
  const pu_updated_by = document.getElementById("pu_updated_by").value;
  const pu_updated_on = currentDateTime;
  const pu_updated_ip = document.getElementById("pu_updated_ip").value;
  const phone_factory = document.getElementById("phone_factory").value;
  const phone_office = document.getElementById("phone_office").value;
  const localsalestaxno = document.getElementById("localsalestaxno").value;
  const centralsalestaxno = document.getElementById("centralsalestaxno").value;
  const tax_id_num = document.getElementById("tax_id_num").value;
  const sc_vendor = scVendor;
  

  
  if (!pu_code || !pu_name || !pu_doccode || !pu_address || !pu_contact_person || !pu_updated_by|| !pu_updated_on|| !pu_updated_ip|| !phone_factory|| !phone_office|| !localsalestaxno|| !centralsalestaxno|| !tax_id_num|| !sc_vendor) {
    window.alert("Please fill in all fields.");
    return;
  }
  let ans=containsOnlychar(pu_code)
  
  if(ans==false)
  {
    window.alert("Invalid PU Code")
  }
  else{

  
  Axios.post(`${process.env.REACT_APP_API_URL}/prod-unit/Update_Prod`, {
    id:id,
    pu_code:pu_code,
    pu_name:pu_name,
    pu_doccode:pu_doccode,
    pu_address:pu_address,
    pu_contact_person:pu_contact_person,
    pu_updated_by:pu_updated_by,
    pu_updated_on:pu_updated_on,
    pu_updated_ip:pu_updated_ip,
    phone_factory:phone_factory,
    phone_office:phone_office,
    localsalestaxno:localsalestaxno,
    centralsalestaxno:centralsalestaxno,
    tax_id_num:tax_id_num,
    sc_vendor:sc_vendor,
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
    if(response.data =="-10")
    {
      window.alert("PU Code Alreay Exists");
    
    }
    else{ 
      window.alert("Values updated");
    window.location.href = '#/Production';}
    window.location.reload(true);
  });
}
 }

const Prod_data_insert = (e) => {
  e.preventDefault();
  const pu_code = input1;
  const pu_name = input2;
  const pu_doccode = input3;
  const pu_address = input4;
  const pu_contact_person = input5;
  const pu_updated_by = input6;
  const pu_updated_on = currentDateTime;
  const pu_updated_ip = input8;
  const phone_factory= input9;
  const phone_office = input10;
  const localsalestaxno = input11;
  const centralsalestaxno = input12;
  const tax_id_num = input13;
  const sc_vendor =  scVendor;

 
  
console.log(sc_vendor);
let ans=containsOnlychar(pu_code)
  
  if (!pu_code || !pu_name || !pu_doccode || !pu_address || !pu_updated_by || !pu_updated_ip || !phone_factory|| !phone_office|| !localsalestaxno|| !centralsalestaxno|| !tax_id_num|| !sc_vendor|| !pu_contact_person|| !pu_updated_on) {
    window.alert("Please fill in all fields.");
    return;
  }
  else if(ans==false)
  {
    window.alert("Invalid PU Code")
  }
  else{
  Axios.post(`${process.env.REACT_APP_API_URL}/prod-unit/Insert_prod_unit`, {
    pu_code:pu_code,
    pu_name:pu_name,
    pu_doccode:pu_doccode,
    pu_address:pu_address,
    pu_contact_person:pu_contact_person,
    pu_updated_by:pu_updated_by,
    pu_updated_on:pu_updated_on,
    pu_updated_ip:pu_updated_ip,
    phone_factory:phone_factory,
    phone_office:phone_office,
    localsalestaxno:localsalestaxno,
    centralsalestaxno:centralsalestaxno,
    tax_id_num:tax_id_num,
    sc_vendor:sc_vendor,
    
  },{
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'}
    }).then((response) => {
    if(response.data == "50")
    {
      window.alert("PU Code Already Exists");
    }
    else
    {
      window.alert("New Production Unit Inserted");
      window.location.href = '#/Production';
      window.location.reload(true);
    }
  });
};}
const options = sc_vendor.map((item) => ({
    label: item.sc_vendor,
    value: item.sc_vendor,
}));

  const inputStyle = { margin: '0 5px' };
console.log(details)


  return (

    
    <div>
      {isLoggedIn ? ( <>
     {details.length >0 &&
      details.map((d) => ( 
            <div key={d.id}>

            <MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
            <MDBCardBody>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="pu_code">PU Code</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
            <input type="text" id="pu_code" style={{inputStyle}} defaultValue={d.pu_code} placeholder={'PU Code'} required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="pu_name">PU Name</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="pu_name" style={{inputStyle}} defaultValue={d.pu_name} placeholder={'PU Name'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="pu_doccode">PU Doccode</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="pu_doccode" style={{inputStyle}} defaultValue={d.pu_doccode} placeholder={'PU Doccode'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="pu_address">PU Address</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="pu_address" style={{inputStyle}} defaultValue={d.pu_address} placeholder={'PU Address'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>



            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="pu_updated_by">Updated By</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="pu_updated_by" style={{inputStyle}} defaultValue={d.pu_updated_by} placeholder={'Updated By'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="pu_updated_ip">Updated IP</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="pu_updated_ip" style={{inputStyle}} defaultValue={d.pu_updated_ip} placeholder={'Updated IP'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
           
            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="localsalsetaxno">Locat Sales Taxno</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="localsalestaxno" style={{inputStyle}} defaultValue={d.localsalestaxno} placeholder={'Locat Sales Taxno'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="centralsalestaxno">Central Sales Taxno</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="centralsalestaxno" style={{inputStyle}} defaultValue={d.centralsalestaxno} placeholder={'Central Sales Taxno'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="tax_id_num">Tax ID Number</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="tax_id_num" style={{inputStyle}} defaultValue={d.tax_id_num} placeholder={'Tax ID Number'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="pu_contact_person">Contact Person Name</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="pu_contact_person" style={{inputStyle}} defaultValue={d.pu_contact_person} placeholder={'Contact Person Name'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="phone_factory">Phone Factory Number</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="phone_factory" style={{inputStyle}} defaultValue={d.phone_factory} placeholder={'Phone Factory Number'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="phone_office">Phone Office Number</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <input type="text" id="phone_office" style={{inputStyle}} defaultValue={d.phone_office} placeholder={'Phone Office Number'}  required />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
            

            
          
            <button type="submit" className="btn btn-primary" onClick={Prod_data_update} >
            Save
            </button>
            <Link to="/Production">
            <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
            </button>
            </Link>
            
            </MDBCardBody>
            </MDBCard> 
            </MDBCol>

            </div>
     ))}
     {details.length === 0 && 
     <div>

                <MDBCol lg="7" className="mx-auto" >
            <MDBCard className="mb-4">
              <MDBCardBody>
              <MDBRow>
              <MDBCol sm="3">
                <label htmlFor="sc_vendor">SC Vendor</label>
              
              </MDBCol>
              <MDBCol sm="9">
                
              <MDBCardText className="text-muted">
              <Select options={options}  onChange={handleSelectChange} id="sc_vendor" placeholder="Search SC Vendor" />
            
              </MDBCardText>
              </MDBCol>
            </MDBRow>
                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="pu_code">PU Code</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" inputmode="string" style={{inputStyle}}  defaultValue={input1} placeholder={'PU Code'} onChange={(e) => setInput1(e.target.value)}  />
                
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="pu_name">PU Name</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input2} placeholder={'PU Name'} onChange={(e) => setInput2(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="pu_doccode">PU Doccode</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input3} placeholder={'PU Doccode'} onChange={(e) => setInput3(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="pu_address">PU Address</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input4} placeholder={'PU Address'} onChange={(e) => setInput4(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>



                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="pu_updated_by">Updated By</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input6} placeholder={'Updated By'} onChange={(e) => setInput6(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="pu_updated_ip">Updated IP</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input8} placeholder={'Updated IP'} onChange={(e) => setInput8(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="localsalestaxno">Local Salse Taxno</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input11} placeholder={'Local Salse Taxno'} onChange={(e) => setInput11(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="centralsalestaxno"> Central Salse Taxno</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input12} placeholder={'Central Salse Taxno'} onChange={(e) => setInput12(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="tax_id_num">Tax Id  Number </label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input13} placeholder={'Tax Id  Number '} onChange={(e) => setInput13(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="pu_contact_person">Contact Person Name</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input5} placeholder={'Contact Person Name'} onChange={(e) => setInput5(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow> 
                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="phone_factory">Phone Factory Number</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input9} placeholder={'Phone Factory Number'} onChange={(e) => setInput9(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="3">
                    <label htmlFor="phone_office">Phone Office Number</label>
                   
                  </MDBCol>
                  <MDBCol sm="9">
                    
                  <MDBCardText className="text-muted">
                  <input type="text" style={{inputStyle}} value={input10} placeholder={'Phone Office Number'} onChange={(e) => setInput10(e.target.value)} required />
                 
                  </MDBCardText>
                  </MDBCol>
                </MDBRow>

    
          
          <button type="submit" className="btn btn-primary" onClick={Prod_data_insert} >
            Save
          </button>
          <Link to="/Production">
          <button style={{marginLeft :'20px'}} type="submit" className="btn btn-primary">
            Back
          </button>
          </Link>
                 
              </MDBCardBody>
            </MDBCard> 
          </MDBCol>
      
      </div>}
      </>) : (window.location.href = "#/login")}
    </div>
  );
}
