import React, { useState,useEffect } from 'react'
import '../../routes/status_change.css';
import  secureLocalStorage  from  "react-secure-storage";
export default function Excel_component(props) {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState('');
    var count=1;
    const [error, setError] = useState('');
     // drag state
     const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);
   
     // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
          setDragActive(true);
        } else if (e.type === "dragleave") {
          setDragActive(false);
        }
      };
      // triggers when file is dropped
    const handleDrop = function(e) {
        // fname=e.dataTransfer.files[0].name;
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
      // readExcel(e.dataTransfer.files[0])
      props.onUpload(e.dataTransfer.files[0]);
      };
       // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        const file = e.target.files[0];
        const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;
        if (!allowedExtensions.test(file.name)) {
          window.alert('Only .xls, .xlsx, and .csv files are allowed');
          window.location.reload(true);
        } 
        // fname=e.target.files[0].name;
        if (e.target.files && e.target.files[0]) {
          // readExcel(e.target.files[0])
          props.onUpload(e.target.files[0])
      }};
      
  return (
    <div >
    <div data-cy="file-component" id="file-upload">
    <form id="form-file-upload" data-testid="upform" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} data-cy="upload-input" data-testid="up_file" type="file" id="input-file-upload" multiple={true} accept=".xlsx, .xls"
       
        onChange={
          handleChange}
        />
      <label id="label-file-upload"  data-testid="drop_file" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div>
          <p data-testid="drag_label">Drag and drop your file here or Click to upload files</p>
          {/* <button className="upload-button" onClick={onButtonClick}>Upload a file</button> */}
        </div>
      </label>
      { dragActive &&<div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      <br/>Allowed file formats: .xls, .xlsx, .csv<br/>
       </form></div>
      <br/><br/>
      
    </div>
  )
}
