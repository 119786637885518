import React, { useState,useEffect } from 'react';
import Axios from "axios";
import moment from 'moment-timezone';
import Select from "react-select";
import  secureLocalStorage  from  "react-secure-storage";
export default function Log_report(){
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
    const [details,setDetails]=useState([]);
    const [order_id,setorderids]=useState([]);
    const [oid, setOid] = useState(null);
    const token = secureLocalStorage.getItem("token");
    const mill = secureLocalStorage.getItem("mill");
    useEffect(() => {
      if (isLoggedIn){
      if (mill === "COATS") {
        Axios.post(`${process.env.REACT_APP_API_URL}/order/id_disp`, {},{
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'}
          }).then((response) => {
         
          setorderids(response.data);
        }).catch((err) => {
          console.log(err);
        });
      } else {
  
  
        Axios.post(`${process.env.REACT_APP_API_URL}/order/id_disp_vendor`, {
          mill: mill,
        },{
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'}
          }).then((response) => {
          
          setorderids(response.data);
        }).catch((err) => {
          console.log(err);
        });
      }
    }
    }, []);
    const calc=(d1,d2)=>{
        const date1 = new Date(d1);
        const date2 = new Date(d2);
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        const hours = timeDiff / (1000 * 60 * 60);
        const rounded = hours.toFixed(2);
        return rounded;
    }
    
   const format_date=(utcDate)=>{
    const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
    return istDate;
  }
  const getdetails = (selectedOption) => {
    setOid(selectedOption.value);
    Axios.post(`${process.env.REACT_APP_API_URL}/order-log/log_report_disp`,{
        id: selectedOption.value,
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
       
        setDetails(response.data)
    });
}
  



  
    
     // Define options for the Select component
     const options = order_id.map((item) => ({
      label: item.sc_order_no,
      value: item.sc_order_no,
  }));
  
  return (
    <div>
       {isLoggedIn ? ( <>
 
    <div style={{ display:"flex", marginLeft:"100px", marginTop: "20px"}}>
    <Select options={options} onChange={getdetails} placeholder="Search Order ID" />

</div><br/>
       <div>
      <table className="table container" >
          <thead>
            <tr>
              <th scope="col">Order ID</th>
              <th scope="col">Order Stage</th>
              <th scope="col">Execution Date/Time</th>
              <th scope="col">Cycle Time(in Hours)</th>
              <th scope="col">Executers</th>
            </tr>
          </thead>
           
               {details.map((d) => (
                 <tbody>
                  <tr>
                   <td>{d.sc_order_no}</td>
                   <td>{d.statuscode}</td>
                   <td>{format_date(d.execution_time)}</td>
                   {d.statuscode === 'Order Created' &&
                   <td>0.00</td> }
                   {d.statuscode === 'Order Released' &&
                   <td>{calc(details[0].execution_time,details[1].execution_time)}</td> }
                   {d.statuscode === 'Dyeing' &&
                   <td>{calc(details[1].execution_time,details[2].execution_time)}</td> }
                   {d.statuscode === 'Winding' &&
                   <td>{calc(details[2].execution_time,details[3].execution_time)}</td> }
                   {d.statuscode === 'Packing' &&
                   <td>{calc(details[3].execution_time,details[4].execution_time)}</td> }
                    {d.statuscode === 'Close' &&
                   <td>{calc(details[4].execution_time,details[5].execution_time)}</td> }
                   
                   <td>{d.executer}</td>
                 </tr>
                 </tbody>
               ))}
             
        </table> </div>
        </>) : (window.location.href = "#/login")}  
    </div>
  )
}
