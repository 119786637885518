import { useState,useEffect } from "react"
import React from 'react'
import  secureLocalStorage  from  "react-secure-storage";
import Axios from "axios";
import MUIDataTable from 'mui-datatables'
const Depo= () => {
    const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
    const token = secureLocalStorage.getItem("token");
    const [status, setStatus] = useState([]);
    useEffect(() => {
      if (isLoggedIn){
        Axios.post(`${process.env.REACT_APP_API_URL}/depo/depo_details`,{
        
        },{
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'}}).then((response) => {
         setStatus(response.data)
        });
      }}, []);
      const columns=[
       
           {name:"id", label: "ID"},
           {name:"depo_name", label: "Depo Name"},
           {name:"desc", label: "Desc"}
        
      ]
      return (
        <div>
           {isLoggedIn ? ( <>
          <h3 style={{marginLeft:"100px"}} data-cy="order_label">Depo</h3>
          <br/><br/>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <MUIDataTable 
    data={status}
    columns={columns}
    options={{ filterType: 'checkbox',selectableRows: 'none',sort: false,filter: false  }}
    className="custom-table1" 
    />
    </div>
    
          </>) : (window.location.href = "#/login")}
        </div>
      )
}
export default Depo