import React, { useState } from 'react'
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
} from '@mui/material'
import MUIDataTable from 'mui-datatables'
import Axios from 'axios'
import FileSaver from 'file-saver'
import secureLocalStorage from 'react-secure-storage'
import moment from 'moment-timezone'
function Carton_reprint() {
  const isLoggedIn = secureLocalStorage.getItem('isLoggedIn')
  const token = secureLocalStorage.getItem('token')
  const uname= secureLocalStorage.getItem("activeUser");
  const [selectedOption, setSelectedOption] = React.useState('')
  const [textBox1Value, setTextBox1Value] = React.useState('')
  const [textBox2Value, setTextBox2Value] = React.useState('')
  const [textBox3Value, setTextBox3Value] = React.useState('')
  const [textBox4Value, setTextBox4Value] = React.useState('')
  const [details, setDetails] = useState([])
  const mill = secureLocalStorage.getItem('mill')
  const format_date = (utcDate) => {
    const istDate = moment.utc(utcDate).tz('Asia/Kolkata').format('DD/MM/YYYY')
    return istDate
  }
  const columns = [
      {name:"carton_no",label:"Carton No"},
      {name:"sc_order_no",label:"SC Order No"},
      {name:"mill",label:"Mill"},
      {name:"article",label:"Article"},
      {name:"shade",label:"Shade"},
      {name:"depot",label:"Depot"},
      {name:"fullqty",label:"Quantity"},
      {name:"packerid",label:"Packer ID"},
      {name:"grosswt",label:"Gross Weight"},
      // {name:"greykg",label:"Net Weight"},
      {name:"cartontype",label:"Carton Type"},
      {name:"statuscode",label:"Status"},
      {name:"carton_date", label: "Carton Date",
  options: {
    customBodyRender: (value) => format_date(value)
  }
  },
  {name:"count_label",label:"Count Label"}]
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value)
    setDetails([])
  }

  const handleTextBox1Change = (event) => {
    setTextBox1Value(event.target.value)
  }

  const handleTextBox2Change = (event) => {
    setTextBox2Value(event.target.value)
  }

  const handleTextBox3Change = (event) => {
    setTextBox3Value(event.target.value)
  }
  const handleTextBox4Change = (event) => {
    setTextBox4Value(event.target.value)
  }
  const handleSubmit = () => {
    // Handle form submission here
    // const regexPattern = /^CL\d{6}$/
    const regexPattern = /^[0-9a-zA-Z]{8}$/;
    if (selectedOption == '') {
      window.alert('Choose label type')
      return
    }
    if (selectedOption == 'Solid' && textBox1Value && textBox2Value) {
      if (!regexPattern.test(String(textBox1Value))) {
        window.alert('Invalid carton number (From) ')
        return
      }

      if (!regexPattern.test(String(textBox2Value))) {
        window.alert('Invalid carton number (To)')
        return
      }
      Axios.post(
        `${process.env.REACT_APP_API_URL}/label/solid_label_reprint`,
        {
          mill: mill,
          from: textBox1Value,
          to: textBox2Value,
        },
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => {
        var len;
        if (response.data.length > 0) 
        {
        console.log("length",response.data.length)
        for(len=0;len<response.data.length;len++)
       {
        console.log("response.data[len].fullqty",response.data[len].fullqty)
        response.data[len].fullqty=response.data[len].at_cc;
        console.log("response.data[len].fullqty",response.data[len].fullqty)
        }
        setDetails(response.data)
        }
        else {
          window.alert('No record found')
          return
        }
      })
    } else if (selectedOption == 'Loose' && textBox3Value && textBox4Value) {
      if (!regexPattern.test(String(textBox3Value))) {
        window.alert('Wrong carton number for From')
        return
      }
      if (!regexPattern.test(String(textBox4Value))) {
        window.alert('Wrong carton number for To')
        return
      }
      Axios.post(
        `${process.env.REACT_APP_API_URL}/label/loose_label_reprint`,
        {
          mill: mill,
          carton_no1: textBox3Value,
          carton_no2: textBox4Value,
        },
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => {
        var len;
        if (response.data.length > 0) 
        {
          for(len=0;len<response.data.length;len++)
          {
            const grossWeight =  ((response.data[len].grosswt/response.data[len].at_cc) * response.data[len].fullqty).toFixed(1)
            response.data[len].grosswt=grossWeight
           }
        setDetails(response.data)
        }
        else {
          window.alert('No record found')
          return
        }
      })
    } else {
      window.alert('Please fill Carton number')
      return
    }
  }

  const getBrowserName = () => {
    let browserInfo = navigator.userAgent
    let browser
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      browser = 'Opera'
    } else if (browserInfo.includes('Edg')) {
      browser = 'Edge'
    } else if (browserInfo.includes('Chrome')) {
      browser = 'Chrome'
    } else if (browserInfo.includes('Safari')) {
      browser = 'Safari'
    } else if (browserInfo.includes('Firefox')) {
      browser = 'Firefox'
    } else {
      browser = 'unknown'
    }
    return browser
  }

  const downloadData = async () => {
    const browser = getBrowserName()
    if (textBox1Value && textBox2Value) {
      Axios.post(
        `${process.env.REACT_APP_API_URL}/label/label_update_solid`,
        {
          carton_no1: textBox1Value,
          carton_no2: textBox2Value,
        },
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      ).then((_response) => {})
    }
    if (textBox3Value && textBox4Value) {
      Axios.post(
        `${process.env.REACT_APP_API_URL}/label/label_update`,
        {
          carton_no1: textBox3Value,
          carton_no2:textBox4Value,
        },
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      ).then((_response) => {})
    }
    let data = ''
    let label_type
    var doc = document.getElementById('printf').contentWindow.document;
    doc.open();
    let ctype=''
    // Loop through details array and add each row to the text file
    // details.forEach((item) => {
    //   data += `${item.carton_no},${item.sc_order_no},${item.mill},${item.pu_code},${item.article},${item.shade},${item.depot},${item.fullqty},${item.packerid},${item.grosswt},${item.greykg},${item.cartontype},${item.cardtype},${item.sc_carton},${format_date(item.carton_date)}\n`;
    // });
    if (selectedOption === 'Loose') {
     if(uname=='SIRIUS')
     {
      label_type =
      browser === 'Safari' || browser === 'Firefox' ? 'Loose' : 'Loose.dat'
   
  const uniqueCartonNos = Array.from(new Set(details.map(detail => detail.carton_no)));
      console.log("uniqueCartonNos",uniqueCartonNos)
      var grossweight=0
      uniqueCartonNos.forEach(cartonNo => {
        // Get unique items for the carton
        const cartonItems = details.filter(detail => detail.carton_no === cartonNo);
        const depot = Array.from(new Set(cartonItems.map(detail => detail.depot))).join(', ');
        const fullqty = Array.from(new Set(cartonItems.map(detail => detail.fullqty))).join(', ');
        const greykg = Array.from(new Set(cartonItems.map(detail => detail.greykg))).join(', ');
        const article = Array.from(new Set(cartonItems.map(detail => detail.article))).join(', ');
        const carton_date = Array.from(new Set(cartonItems.map(detail => detail.carton_date))).join(', ');
        const packerid = Array.from(new Set(cartonItems.map(detail => detail.packerid))).join(', ');
        // Print the unique carton number with unique items
        console.log(`Carton Number: ${cartonNo} (depot: ${depot})`);
        data += `${cartonNo},${depot},${article},`
      
        cartonItems.forEach(detail => {
          console.log(`  shade: ${detail.shade}, Quantity: ${detail.fullqty} ${detail.at_asu}`);
          data += ` ${detail.shade}    ${detail.fullqty} ${detail.at_asu}   ,`
          grossweight=Number(grossweight)+Number(detail.grosswt)
          console.log("cartonItems.length",cartonItems.length)
          
        });
        console.log(cartonItems)
        console.log("fullqty",fullqty)
        console.log("greykg",greykg)
        const netValue = (Number(fullqty) * Number(greykg)).toFixed(1)
        if(cartonItems.length==1)
        data+=`,,,,, ${grossweight},Weight,${format_date(carton_date)},${packerid}\r\n`
      else if(cartonItems.length==2)
       data+=`,,,, ${grossweight},Weight,${format_date(carton_date)},${packerid}\r\n`
      else if(cartonItems.length==3)
       data+=`,,, ${grossweight},Weight,${format_date(carton_date)},${packerid}\r\n`
      else if(cartonItems.length==4)
       data+=`,, ${grossweight},Weight,${format_date(carton_date)},${packerid}\r\n`
      else if(cartonItems.length==5)
       data+=`, ${grossweight},Weight,${format_date(carton_date)},${packerid}\r\n`
       else if(cartonItems.length==6)
       data+=` ${grossweight},Weight,${format_date(carton_date)},${packerid}\r\n`
      
      });
     
     }
     else{
      details.forEach((item) => {
        label_type =
          browser === 'Safari' || browser === 'Firefox' ? 'Loose' : 'Loose.dat'
        const netValue = (item.fullqty * item.greykg).toFixed(1)
        // const crossWeight =  ((item.grosswt/item.at_cc) * item.fullqty).toFixed(1)
        const article = `${item.article.substring(0,4)}/${item.article.substring(4,7)}`
        if(uname=='madura_admin')
        {
          doc.write(" <B><p style='line-height: 0.9;'>Carton No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.carton_no);
      doc.write(" <B><p style='line-height: 0.9;'>Depot&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.depot);
      doc.write(" <B><p style='line-height: 0.9;'>Article&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", article);
      // doc.write(" <B><p>Ticket No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.article.substring(4,7));
      doc.write(" <B><p style='line-height: 0.9;'>Shade&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.shade);
      doc.write(" <B><p style='line-height: 0.9;'>Full Quantity&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",item.fullqty);
      doc.write(" <B><p style='line-height: 0.9;'>AT_ASU&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.at_asu);
      doc.write(" <B><p style='line-height: 0.9;'>Netvalue &nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",netValue );
      doc.write(" <B><p style='line-height: 0.9;'>Gross wt&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.grosswt);
      doc.write(" <B><p style='line-height: 0.9;'>Carton  Date&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",  format_date(item.carton_date));
      doc.write(" <B><p style='line-height: 0.9;'>Packer ID&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.packerid);
         }
         else{
        data += `${item.carton_no},${item.depot},${article}, ${item.shade}    ${item.fullqty} ${item.at_asu}   ,,,,,,  ${netValue},${item.grosswt},${format_date(item.carton_date)},${item.packerid}\r\n`
         }
      })
    }
      
    } else {
      details.forEach((item) => {
        label_type =
          browser === 'Safari' || browser === 'Firefox' ? 'Solid' : 'Solid.dat'
          const netValue = (item.greykg * item.at_cc).toFixed(1)
          if(uname=='madura_admin')
          {
            doc.write(" <B><p style='line-height: 0.6;'>Carton No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.carton_no);
        doc.write(" <B><p style='line-height: 0.6;'>Depot&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.depot);
        doc.write(" <B><p style='line-height: 0.6;'>Article&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.article.substring(0,4));
        doc.write(" <B><p style='line-height: 0.6;'>Ticket No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.article.substring(4,7));
        doc.write(" <B><p style='line-height: 0.6;'>Shade&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.shade);
        doc.write(" <B><p style='line-height: 0.6;'>AT_CC&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.at_cc);
        doc.write(" <B><p style='line-height: 0.6;'>AT_ASU&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.at_asu);
        doc.write(" <B><p style='line-height: 0.6;'>Carton No&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.carton_no);
        doc.write(" <B><p style='line-height: 0.6;'>Netvalue &nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",netValue );
        doc.write(" <B><p style='line-height: 0.6;'>Gross wt&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.grosswt);
        doc.write(" <B><p style='line-height: 0.6;'>Carton  Date&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ",  format_date(item.carton_date));
        doc.write(" <B><p style='line-height: 0.6;'>Packer ID&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; ", item.packerid);
           }
           else{
        data += `${item.carton_no},${item.depot} ,${item.article.substring(0,4)},${item.article.substring(4,7)},${item.shade}, ${item.at_cc} ${item.at_asu},${item.carton_no},${netValue},${item.grosswt},${format_date(item.carton_date)},${item.packerid}\r\n`
           }
      })
    }
    if(uname=='madura_admin')
    {
      doc.close()
      window.frames["printf"].focus()
       window.frames["printf"].print()
    
    }
     else{
    try {
      if (browser === 'Safari' || browser === 'Firefox') {
        var blob = new Blob([data], { type: 'application/octet-stream' })
        FileSaver.saveAs(blob, `${label_type}.dat`)
      } else {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: label_type,
          types: [
            {
              description: 'Dat Files',
              accept: {
                'application/octet-stream': ['.dat'],
              },
            },
          ],
        })

        const writable = await fileHandle.createWritable()
        await writable.write(data)
        await writable.close()
      }
    } catch (error) {
      console.error('Error saving file:', error)
    }
  }
  }
  return (
    <div>
      {isLoggedIn ? (
        <>
          <br />{' '}
          <div >
<iframe id="printf" name="printf"  style={{ display: 'none' }} ></iframe>
</div>
          <h3 style={{ textAlign: 'center' }} data-cy="order_label">
            Carton Reprint
          </h3>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="500px"
              border="2px solid #000"
              padding="40px"
            >
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="radio-group"
                  name="radio-group"
                  value={selectedOption}
                  onChange={handleRadioChange}
                >
                  <Box display="flex" alignItems="center">
                    <FormLabel
                      style={{ marginRight: '16px', marginLeft: '-120px' }}
                    >
                      Type
                    </FormLabel>
                    <FormControlLabel
                      value="Solid"
                      control={<Radio />}
                      label="Solid"
                    />
                    <FormControlLabel
                      value="Loose"
                      control={<Radio />}
                      label="Loose"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
              {selectedOption === 'Solid' && (
                <Box display="flex" alignItems="center" marginTop="16px">
                  <FormLabel style={{ marginRight: '16px' }}>
                    Carton Numbers
                  </FormLabel>
                  <Box display="flex" alignItems="center">
                    <TextField
                      label="From"
                      value={textBox1Value}
                      onChange={handleTextBox1Change}
                      style={{ width: '180px', marginRight: '8px' }}
                    />
                    <TextField
                      label="To"
                      value={textBox2Value}
                      onChange={handleTextBox2Change}
                      style={{ width: '180px' }}
                    />
                  </Box>
                </Box>
              )}

              {selectedOption === 'Loose' && (
                <Box display="flex" alignItems="center" marginTop="16px">
                  <FormLabel style={{ marginRight: '16px' }}>
                    Carton Numbers
                  </FormLabel>
                  <Box
                    display="flex"
                    alignItems="center"
                    marginTop="16px"
                    style={{ marginLeft: '16px' }}
                  >
                    <TextField
                      label="From"
                      value={textBox3Value}
                      onChange={handleTextBox3Change}
                      style={{ width: '180px', marginRight: '8px' }}
                    />
                      <TextField
                      label="To"
                      value={textBox4Value}
                      onChange={handleTextBox4Change}
                      style={{ width: '180px', marginRight: '8px' }}
                    />
                  </Box>
                </Box>
              )}

              <Box marginTop="16px">
                <Button variant="contained" onClick={handleSubmit}>
                  Generate
                </Button>
              </Box>
            </Box>
          </div>
          {details.length > 0 && (
            <>
            {console.log(details)}
              <MUIDataTable
                title={'DOWNLOAD LABEL'}
                data={details}
                columns={columns}
                options={{
                  filterType: 'checkbox',
                  selectableRows: 'none',
                  sort: false,
                  downloadOptions : {
                    filename: 'CartonStatus.csv',
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  }
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadData}
                >
                  Download Label
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        window.location.href = '#/login'
      )}
    </div>
  )
}

export default Carton_reprint
