
import React, { useState,useEffect } from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import Axios from "axios";
import * as XLSX from "xlsx";
import './status_change.css';
import Excel_component from '../components/excel/excel_component';
import Excel_download from '../components/excel/excel_download';
export default function Multiple_status_up(){
  const columns = [
    ['Order_ID','Status'],
    
  ];
  const file_name="status.xlsx";
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
    var count=1;
    var error_count=0;
    var error_text="";
    const dataObject = {};
    const dataObject1 = {};
    const handleUpload = (details) => {
      readExcel(details);
    };
    const mill= secureLocalStorage.getItem("mill");
    const token = secureLocalStorage.getItem("token");
  useEffect(()=>{
    if (isLoggedIn){
    const today = new Date();
    const currentTime = today.toLocaleTimeString();
    console.log(currentTime);
    console.log(today);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const hours = today.getHours().toString().padStart(2, '0');
    const minutes = today.getMinutes().toString().padStart(2, '0');
    const seconds = today.getSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    setCurrentDateTime(formattedDateTime);
  }
  },[]);
    
    const update_status = async (e) => {
        e.preventDefault();
        for (let i = 1; i <= items.length; i++) {
        const oid = document.getElementById('oid'+i).value;
        const sts = document.getElementById('sts'+i).value;
        dataObject[i] = {oid,sts};
        dataObject1[i] = {oid,sts,currentDateTime,mill};
     
      }
      try{
        const jsonData = JSON.stringify(dataObject1);
      await  Axios.post(`${process.env.REACT_APP_API_URL}/order-log/order_log_insert`, {
        body: jsonData,
     
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         });
        //  if(order_result){
        //   window.alert("Status Updated");
        //    window.location.reload(true);
        //   }
     } catch (error) {
       console.log(error);
     }
      try{
        const jsonData = JSON.stringify(dataObject);
      const order_result=await  Axios.post(`${process.env.REACT_APP_API_URL}/order/multiple_order_status_update`, {
        body: jsonData,
     
       },{
         headers: {
           'x-access-token': token,
           'Content-Type': 'application/json'}
         });
         if(order_result){
          window.alert("Status Updated");
           window.location.reload(true);
          }
     } catch (error) {
       console.log(error);
     }

      // Axios.post(`${process.env.REACT_APP_API_URL}/order-log/order_log_insert`, {
      //   sts: sts,
      //   id: oid,
      //   last_edited: currentDateTime,
      //   mill:mill,
      // },{
      //   headers: {
      //     'x-access-token': token,
      //     'Content-Type': 'application/json'}
      //   }).then((response) => {
       
      // });
 
      };
     
   
    const readExcel = async (file) => {
      setIsLoading(true);
    
      try {
        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
    
          fileReader.onload = (e) => {
            const bufferArray = e.target.result;
    
            const wb = XLSX.read(bufferArray, { type: "buffer" });
    
            const wsname = wb.SheetNames[0];
    
            const ws = wb.Sheets[wsname];
    
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          };
          
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    
        const data = await promise;
        for(let k=0;k<data.length;k++) {
          let line=k+2;
          // const response = await Axios.post(`${process.env.REACT_APP_API_URL}/order/check_id_mill`, {
          //   id: data[k].Order_ID,
          // },{
          //   headers: {
          //     'x-access-token': token,
          //     'Content-Type': 'application/json'}
          //   });
          // if(response.data.length == 0) {
          //   error_count += 1;
          //   error_text += "Line " + line + " No such Order ID\n";
          // }
          console.log(data[k].Status)
          if(data[k].Status !== "Order Released" && data[k].Status !== "Dyeing" && data[k].Status !== "Winding" && data[k].Status !=="Packing" && data[k].Status !=="Shortclosed")
          {
            error_count += 1;
            error_text += "Line " + line + " No such Status\n";
          }
        }
    
        if(error_count > 0) {
          document.getElementById('tb1').style.visibility = "hidden";
          window.alert("Invalid contents in the file.. Please upload the correct file \n" + error_text);
          window.location.reload(true);
        }
        else {
          document.getElementById("tb1").removeAttribute("hidden");
          document.getElementById('tb1').style.visibility = "visible";
        }
    
        setItems(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    
    return (
      <div>
         {isLoggedIn ? ( <>
        <br/>
       
        <h3 style={{textAlign: 'center'}}>Multiple Status Update</h3><br/>
        <Excel_component onUpload={handleUpload}/>
        <Excel_download data={columns} file_name={file_name}></Excel_download>
        <div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-icon"></div>
        </div>
      )}
    </div>
    <br/>
    <br/>
     
      <div>
      <table className="table container" id="tb1" hidden="hidden">
          <thead>
            <tr>
              <th scope="col">Order ID</th>
              <th scope="col">Status</th>
             
              
            </tr>
          </thead>
            <tbody>
               {items.map((d) => (
                  <tr>
                   <td><input type="text" id={"oid"+count} defaultValue={d.Order_ID} readOnly></input></td>
                   <td><input type="text" id={"sts"+count} defaultValue={d.Status} onLoad={count=count+1} readOnly></input></td>
                 </tr>
               ))}
               <tr><td colspan="5" align="right"> <div><button type="submit" className="btn btn-primary"  onClick={update_status}>
              Update Status
            </button></div></td></tr>
             </tbody>
        </table> </div>
        </>) : (window.location.href = "#/login")}
      </div>
    );
};
