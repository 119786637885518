import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import './master.css'
import  secureLocalStorage  from  "react-secure-storage";

export default function Master() {
  const isLoggedIn = secureLocalStorage.getItem("isLoggedIn");
  const token = secureLocalStorage.getItem("token");
  const [details, setDetails] = useState([])
  const columns = [
 
    {
      name: "edit",
      options: {
        filter: false,
        label:"Edit",
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = details[tableMeta.rowIndex].id;
          return (
            <Link to={`/Master_data?id=${id}`}>
              <img src="https://cdn3.iconfinder.com/data/icons/streamline-icon-set-free-pack/48/Streamline-22-512.png" width="15" height="15"/ >
            </Link>
          );
        }
      }
    },
    {name:"material_group", label: "Material Group"},
    {name:"material_code",label: "Material Code"},
    {name:"material_desc",label: "Material Desc"},
   {name: "material_uom",label: "Material Uom"},
   {name: "material_sc",label: "Material SC"},
   {name: "material_wbc",label: "Material WBC"},
   {name: "status",label: "Material Status"}
  ];
  useEffect(() => {
       if (isLoggedIn){
    const getMasterDetails=()=>{
    axios.post(`${process.env.REACT_APP_API_URL}/material/show_masterdata`,{
    },{
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'}
      }).then((response) => {
      setDetails(response.data)

    })
  }
  getMasterDetails()
}
  }, [])
   
 
  return(
    <div>
      
      {isLoggedIn ? ( <>          
        <div className='movv'>
    <div style={{display: 'flex'}}>

        <div style={{display: 'flex',marginLeft: '50px' }}>
        <Link to="/Master_data_upload">
            <button type="submit" className="btn btn-primary">
            Import
          </button>
        </Link>
        <div style={{marginLeft: '10px' }}>
        <Link to="/Master_data">
            <button type="submit" className="btn btn-primary">
            New
          </button>
        </Link>
        </div>
        </div>
        </div>
        </div>
       <br/>
        <div className='container'>
      <MUIDataTable title={"MASTER DATA"}
        data={details}
        columns={columns}
        options={{ filterType: 'checkbox',selectableRows: 'none',sort: false,filter: false  }}
      />
    </div>
    
    </>) : (window.location.href = "#/login")}
   </div>

   
  );
};
